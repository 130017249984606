import React, { useEffect, useState } from 'react';
import './ProductHeader.css';
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
import electronics from '../../assets/electronics.jpg'
import fashion from '../../assets/fashion.jpg'
import food from '../../assets/food.jpg'
import beauty from '../../assets/beauty.jpg'
import { Header } from '../utilityComponents'



function ProductHeader(props) {
    return (
        <div className="bg-white" style={{ width: '100%', }}>
            <div className="nw1-product-bigger-header ">
                <div style={{
                    backgroundImage: `url(${props.backgroundPictureURL})`,
                    width: '100%',
                    height: 400,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }} className=''>
                </div>
            </div> 
            {Header(WakandaLogoWhite)}
        </div>
    );
}

export default ProductHeader;
