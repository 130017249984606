import React from 'react'
import { uniformImageHolder } from './utilityComponents'
import Skeleton from '@yisheng90/react-loading';
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Product from './product1'
import './styles/provider.css'
let OLDbg = 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680'
let NEWbg = 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2Fwkmarket-bground4.jpg?alt=media&token=644f492d-403f-43ae-b7d1-64ac77546fe2'

const ProviderUnit = (props) => {
    return (
        <div class='text-left' style={{ borderRadius: 35, overflow: "hidden", width: 282, }}>
            <Link to={{
                pathname: props.xID ? `/shop/${props.xID}` : '',
                xID: { item: props.xID }
            }}
                target="_blank" style={{ textDecoration: 'none' }}>
                <div class='shadow-lg' style={{ paddingBottom: 20, backgroundColor: "white", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, top: 0 }}>
                    {(props.bgCover || props.providerPhoto || props.providerName) ? <div >{uniformImageHolder((props.bgCover == OLDbg) || (!props.bgCover) ? NEWbg : props.bgCover, "100%", 100)}</div>
                        :
                        <Skeleton width={"100%"} height={100} />}
                    <div style={{ display: 'flex', padding: 20, paddingLeft: 30, paddingBottom: 0, paddingRight: 10, }}>
                        {props.providerPhoto ? <div style={{ float: 'left', marginTop: -50, }} className='zoomin'> {uniformImageHolder(props.providerPhoto, 80, 80, '2px solid white')}</div>
                            : <div style={{ float: 'left', marginTop: -50, borderRadius: 20, overflow: 'hidden', border: '2px solid white', zIndex: 999 }}>
                                <Skeleton width={80} height={80} />
                            </div>
                        }
                        <div style={{ float: 'right', display: 'flex', flexDirection: "column", marginLeft: 10, justifyContent: 'flex-start' }}>
                            {props.providerName ? <div style={{ overflowX: 'hidden', marginTop: -10, textOverflow: 'ellipsis', maxWidth: 150, color: 'grey' }}>
                                <small class='text-truncate' style={{ textAlign: 'left', color: 'black' }}><b>{props.providerName}</b></small>
                            </div> : <div class='col-md-12' style={{ marginTop: -10, paddingTop: 5, width: 150, paddingLeft: 0, marginBottom: 0 }}>
                                    <Skeleton width={"100%"} height={10} />
                                </div>
                            }

                            <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis', maxWidth: 150, color: 'grey' }}>
                                {props.description ? <small className='text-truncate' style={{ color: 'grey' }}>{props.description}</small>
                                    : <Spinner animation="grow" variant="success" role="status" size="sm">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Link>


            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: 10, paddingLeft: 10, paddingRight: 10 }} >
                <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: 10, }}>
                    <small><b>Offers</b></small>
                    <Link to={{
                            pathname: `/shop/${props.xID}`,
                            xID: { item: props.xID }
                        }}
                        target="_blank" style={{ textDecoration: 'none' }}
                    ><small style={{ color: "#00A85A", paddingRight: 20 }}>See All</small></Link>
                </div>
                <div class='' style={{ display: "flex", justifyContent: "space-between", paddingTop: 10 }}>
                    {props.products && props.products.map((item, id) => {
                        if (props.products.indexOf(item) > 2) { return }
                        return (<div class='zoomease'>
                            <Link to={{
                                pathname: props.xID ? `/shop/${props.xID}` : '',
                                xID: { item: props.xID }
                            }}target="_blank" style={{ textDecoration: 'none', color:'#000000' }}>
                                <div style={{ textDecoration: 'none' }}>
                                    <Product productImage={item.pictureURL} key={id} price={item.price} product={item} /></div></Link>
                        </div>)
                    }
                    )}
                </div>

            </div>
        </div>
    )
}

export default ProviderUnit