import React, { Component } from 'react'; 
import ReactDOM from 'react-dom';  
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AllComponents from './components/allComponents'
import CategoryPage from './components/categoryPage'
import Providers from './components/Provider/providers'
import ProfessionalsPage from './components/ProfessionalsPage'
import JobsPage from './components/JobsPage'
import JobsDetails from './components/JobsDetails'
import Footer from './components/Footer/footer'
import Header from './components/Header/header'
import ProductPage from './components/productPage'
import AppDownload from './components/AppDownload'
import CommunityPage from './components/CommunityPage'
import InvitePage from './components/InvitePage'
import Shops from './components/Shops/shops'
import ShopOwner from './components/shopOwner'
import Slider from './components/slider'
import Chadwick from './components/chadwick'
import BlackFriday from './components/blackFriday'
import BlackFriday2 from './components/blackFriday2'
import WakandaJobs from './components/WakandaJobs/wakandajobs'
import WakandaWallets from './components/WakandaWallets/wakandawallets'
import WakandaEvents from './components/WakandaEvents/wakandaevents'
import WakandaAcademy from './components/WakandaAcademy/wakandaacademy'
import HelmetMetaData from './components/HelmetMetaData' 
import FourZeroFour from './components/FourZeroFour'
import BlogHome from './components/Blog/BlogHome'
import BlogPost from './components/Blog/BlogPost'
import BlogHome2 from './components/Blog/BlogHome2'
import CreatePost from './components/Blog/CreatePost'
import Login from './components/Blog/Login'
import Verification from './components/Blog/Verification'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom"
import HeaderComponent from './HeaderComponent';
import faq from './components/FAQ/faq'


const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">
      Go Home
    </Link>
  </div>
);


const routing = (

  <Router>
   {/*  <HelmetMetaData></HelmetMetaData> */}
    <Switch>
      <Route exact path="/all" component={AllComponents} />
      <Route exact path="/" component={App} />
      <Route exact path="/gh" component={App} />
      <Route exact path="/app" component={AppDownload} />
      <Route exact path="/chadwick-boseman" component={Chadwick} />
      <Route exact path="/r.i.p.chadwick.boseman" component={Chadwick} />
      <Route exact path="/welcome-to-blackfriday" component={BlackFriday} />
      <Route exact path="/blackfriday" component={BlackFriday2} />
      <Route exact path="/holidaysale" component={BlackFriday2} />
      <Route exact path="/holidaysalegh" component={BlackFriday2} />
      <Route exact path="/blackfridaygh" component={BlackFriday2} />
      <Route exact path="/jobs" component={JobsPage} />
      <Route exact path="/wakandawallets" component={WakandaWallets} />
      <Route exact path="/wakandaevents" component={WakandaEvents} />
      <Route exact path="/wakandaacademy" component={WakandaAcademy} /> 
      {/* <Route exact path="/blog/home" component={BlogHome} />  */}
      <Route exact path="/community/:communityid" component={CommunityPage} />
      <Route exact path="/invite/:inviteid" component={InvitePage} />
      <Route exact path="/blog/allBlogs" component={BlogHome2} />
      <Route exact path="/blog/login" component={Login} /> 
      <Route exact path="/blog/verifyOTP" component={Verification} /> 
      <Route exact path="/blog/verifyOTP/:phone" component={Verification} />
      <Route exact path="/blog/create-new-post" component={CreatePost} />
      <Route exact path="/blog/post/:blogid/:postid" component={BlogPost} /> 
      <Route exact path="/blog/:blogid" component={BlogHome} />


      <Route exact path="/shop/:id" component={ShopOwner} />
      <Route exact path="/category/:id" component={CategoryPage} />
      <Route exact path="/professionals/:id" component={ProfessionalsPage} />
      <Route exact path="/jobs/:id" component={JobsPage} />
      <Route exact path="/jobs/:providerid/:jobid" component={JobsDetails} />
      <Route exact path="/:shopid/:productid" component={ProductPage} />
      <Route exact path="/providers" component={Providers} />
      <Route exact path="/category" component={CategoryPage} />
      <Route exact path="/shops" component={Shops} />
      <Route exact path="/shop-owner" component={ShopOwner} />
      <Route exact Path="/slider" component={Slider} />
      <Route exact path="/tunde" component={HeaderComponent} />
      <Route exact path="/header" component={Header} />
      <Route exact path="/footer" component={Footer} />
      <Route exact path="/faq" component={faq} />
      {/* <Redirect path="*" to="/" /> */}
      <Route component={FourZeroFour} />
    </Switch>
   
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
