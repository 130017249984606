import React, { useRef, useState } from "react";
import Slider from "react-slick";
import SliderArrow from "./sliderArrow";

const ElementSlider = (props) => {
    const sliderRef = useRef(null);
    const sliderWrapperRef = useRef(null);

    const [showRightArrow, setShowRightArrow] = useState(true);
    const [showLeftArrow, setShowLeftArrow] = useState(true);

    const settings = {
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: true,
        infinite: true,
        arrows: false,
    };

    return (
        <div style={{ position: 'relative', }} ref={sliderWrapperRef}>
            {showLeftArrow && (
                <SliderArrow
                    top={props.top}
                    type="prev"
                    onClick={() => sliderRef.current.slickPrev()}
                    className='d-none d-sm-inline text-center'
                />
            )}
            <div className='text-center'>
            <Slider {...settings} ref={sliderRef} style={{ width: '100%'}} className='ml-sm-4'>
                {props.children}
            </Slider>
            </div>
            {showRightArrow && (
                <SliderArrow
                    top={props.top}
                    type="next"
                    onClick={() => sliderRef.current.slickNext()}
                    className='d-none d-sm-inline text-center'
                />
            )}
        </div>
    );
};

export default ElementSlider;