const prefix = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2F"

const data = {
    "images" : {

        "Phones": prefix + "Electronics%2FPhones.jpg?alt=media&token=a4b78d9b-458c-4e92-b766-f34294b275e4",
        "Tablets": prefix + "Electronics%2FTablets.jpg?alt=media&token=c529f86a-92a0-4987-8338-be8b0f543a20",
        "Computers": prefix + "Electronics%2FComputers.jpg?alt=media&token=0740a76d-443c-4da7-85f4-00141290977e",
        "TV": prefix + "Electronics%2Ftv.jpg?alt=media&token=ae262503-0de7-4141-add5-17f175d72b24",
        "Cameras": prefix + "Electronics%2Fcameras.jpg?alt=media&token=d378d2b3-89bc-402c-acf6-e17a5276b2da",
        "Sound": prefix + "Electronics%2FSound.jpg?alt=media&token=5923f39d-a6b3-4ab2-8d2e-cc5e5335b09b",
        "Software": prefix + "Electronics%2FSoftware.jpg?alt=media&token=4771fe01-1329-461a-87a8-022870149400",
        "Gaming": prefix + "Electronics%2FGaming.jpg?alt=media&token=b605be38-6ecb-4bbc-95df-62a046ea6003",
        "ACs": prefix + "Electronics%2Fair-conditioner2.jpg?alt=media&token=47e75524-a548-4662-9a09-ad229aed82cb",
        "Fans": prefix + "Electronics%2FAppliances.jpg?alt=media&token=90a35894-df94-45fa-b41d-4886661561df",
        "Accessories": prefix + "Electronics%2FAccessories.jpg?alt=media&token=d459c442-8fec-467b-8dc2-57c914359c48",
        "Appliances": prefix + "Electronics%2FAppliances.jpg?alt=media&token=90a35894-df94-45fa-b41d-4886661561df",
    
        "Men´s Clothes": prefix + "Fashion%2Fmens-clothes.jpg?alt=media&token=b2e86dc1-8887-4e77-b112-2fe5aa3980ad",
        "Women´s Clothes": prefix + "Fashion%2Fwomens-clothes.jpg?alt=media&token=1d52dfd7-37d7-462e-bfa4-40e54b09a875",
        "Children's Clothes": prefix + "Fashion%2Fchildrens-clothes2.jpg?alt=media&token=d92eb081-f55d-4ab3-9705-4b689704df01",
        "Bags": prefix + "Fashion%2Fbags.jpg?alt=media&token=22084fc0-3477-45db-8f0b-8f323795c0f7",
        "Shoes": prefix + "Fashion%2Fshoes.jpg?alt=media&token=296c1c90-abb0-40c4-8662-9c5433b5ad45",
        "Watches": prefix + "Fashion%2Fwatches2.jpg?alt=media&token=5aa12cbd-9117-4345-b860-25b82507d128",
        "Jewlery": prefix + "Fashion%2Ffabric.jpg?alt=media&token=3ee0f450-d829-4993-b0a6-9a40c2513909",
        "Sports": prefix + "Fashion%2Faccessories.jpg?alt=media&token=f5f29806-8f2c-46ac-8002-377822e0b82f",
        "Luggage": prefix + "Fashion%2Ftravel-bag-4326728_1920.jpg?alt=media&token=7435aa80-f52a-45cd-868f-6793a3c257d7",
        "Clothing Accessories": prefix + "Fashion%2Ftravel-bag-4326728_1920.jpg?alt=media&token=7435aa80-f52a-45cd-868f-6793a3c257d7",
        "Wedding Wear": prefix + "Fashion%2Fwedding.jpg?alt=media&token=df686943-1ecf-4d54-bc95-bfdd4fed2edb",
        "Taylors & Fashion Design": prefix + "Fashion%2Ftailor.jpg?alt=media&token=c9d51d9b-32d5-432f-bb42-c8de7c76e826",
        "Fabrics": prefix + "Fashion%2Ffabric.jpg?alt=media&token=3ee0f450-d829-4993-b0a6-9a40c2513909",
        "Laundry": prefix + "Fashion%2Flaundry.jpg?alt=media&token=f413954a-bb58-4514-bb63-d37f19206dcd",
    
        "Restaurants / Cafes": prefix + "Food%2FRestaurants%20%2Frestaurant-449952_1920.jpg?alt=media&token=b7f93500-61f9-47a6-b48b-6195ff8fa416",
        "Fresh Food": prefix + "Food%2FFreshFood%2Fcarrots-crate-food-1508666.jpg?alt=media&token=ef7ad2a8-a72a-4d34-a41f-5d1ea6786622",
        "Groceries": prefix + "Food%2FFreshFood%2Fcarrots-crate-food-1508666.jpg?alt=media&token=ef7ad2a8-a72a-4d34-a41f-5d1ea6786622",
        "Drinks": prefix + "Food%2FDrinks%2Falcohol-alcoholic-beverage-alcoholic-beverages-1498923.jpg?alt=media&token=36f14817-aa05-46b6-9b5c-75cfb62e4724",
        "Cakes & Bakery": prefix + "Food%2FCakesandBakery%2Fbakery-baking-blueberries-291528.jpg?alt=media&token=92162525-57c2-4589-bfd4-fd5531c8d8ba",
        "Lifestock": prefix + "Food%2FLivestock%2Fanimals-chickens-close-up-1459451.jpg?alt=media&token=8ae63b69-408b-472c-9337-efe6c48282de",
        "Wholesale": prefix + "Food%2FWholesale%2Fabundance-assorted-business-2387343.jpg?alt=media&token=356d75a8-193b-4a48-887c-ce26d4d2f5e9",
        "Agricultural Products": prefix + "Food%2FAgricProducts%2Fbean-black-rice-cereal-1537169.jpg?alt=media&token=504b2436-2259-49b3-a0a6-1e06b7257ccf",
        "Catering": prefix + "Food%2FCatering%2Fbanquet-catering-chairs-1395967.jpg?alt=media&token=b36f753c-28cc-4cca-a4d6-f0651deeb26a",
    
        "Furniture": prefix + "Home%2FFurniture.jpg?alt=media&token=a760eeeb-51a9-4625-8bc1-d5fc2d1907c9",
        "Cleaning": prefix + "Home%2FCleaning.jpg?alt=media&token=12dc4d9e-f1e2-4ab8-9cca-8d3bfa21f03e",
        "Drycleaning": prefix + "Fashion%2Flaundry.jpg?alt=media&token=f413954a-bb58-4514-bb63-d37f19206dcd",
        "Curtains & Blinds": prefix + "Home%2FCurtains_and_Blinds.jpg?alt=media&token=1e18e5e9-1ff4-490e-9291-23edc892ff4d",
        "Carpets": prefix + "Home%2FCarpets.jpg?alt=media&token=d6c9ada1-114f-4488-ad3e-449006095721",
        "Lights": prefix + "Home%2FLights.jpg?alt=media&token=4fe91ac1-9c59-4738-9af1-381165747938",
        "Garden & Flowers": prefix + "Home%2FGardens_and_Flowers.jpg?alt=media&token=c1d1bfbe-3928-410d-af4e-2398799e953a",
        "Kitchen & Dining": prefix + "Home%2FKitchen_and_Dining.jpg?alt=media&token=7041d0e6-3c4f-4fcc-9b33-4ccbfc25e033",
        "Interior, Decoration, Doors, Floors": prefix + "Home%2Finterior_decoration.jpg?alt=media&token=ffb2bfe9-c1ce-4a38-b758-416e7d6d1b89",
        "Exterior, Windows, Tools": prefix + "Home%2Fexterior_window.jpg?alt=media&token=b392c715-e412-4e91-bdc8-50114858c351",
        "Security & Home Technology": prefix + "Home%2Fsecurity_home_tech.jpg?alt=media&token=2a072d95-ac3e-4da2-bc2b-d08874432405",
        "Baby Products": prefix + "Home%2Fbaby_products.jpg?alt=media&token=dcce82b6-ef94-49d7-9223-07f0caa8ba98",
        "Sports": prefix + "Home%2Fsports.jpg?alt=media&token=eefa9dc0-3826-4dcd-b30c-39329c556a36",
        "Pets": prefix + "Home%2Fpets.jpg?alt=media&token=ecf75833-2524-49b3-b59f-ddb2f46c115a",
        "Home / Office For Sale": prefix + "Home%2FHome-Office-For-Sale.jpg?alt=media&token=a74aa436-c879-4835-8426-aae45c004715",
        "Home / Office For Rent": prefix + "Home%2FHome-Office-For-Sale.jpg?alt=media&token=a74aa436-c879-4835-8426-aae45c004715",
        "Short-term Rent": prefix + "Home%2Fshort_term_rent.jpg?alt=media&token=92c23688-3a95-45cf-b11c-7369f916e2c2",
        "Land & Plots": prefix + "Home%2FLand_Plots.jpg?alt=media&token=2401a248-245f-4720-a1bf-b8cf7488c8df",
        "Event Locations": prefix + "Home%2FEvent_Location.jpg?alt=media&token=53121fc4-f797-41a5-b1be-02b438d9b645",
        "Shared Workspaces": prefix + "Home%2Fshared_workspaces.jpg?alt=media&token=3335e639-4de4-40f6-a9f4-6877e6091363",
    
        "Accessories": prefix + "Beauty%2FAccessories%2Faccessories-accessory-hat-1460838.jpg?alt=media&token=df7bff06-400d-47e6-9d01-dff638da1d38",
        "Hair": prefix + "Beauty%2FHair%2Fadult-beautiful-curly.jpg?alt=media&token=c21bcbd2-726a-4200-8c25-263e32e9a10a",
        "Makeup": prefix + "Beauty%2FMakeup%2Fassorted-blur-close-up-1115128.jpg?alt=media&token=43abcfff-b9a9-4769-abd4-2719c5ae956b",
        "Fragrances": prefix + "Beauty%2FFragrances%2Fbottles-close-up-collection-1432926.jpg?alt=media&token=e1b79e55-84e7-47c8-a71b-4185c80d47bc",
        "Vitamins": prefix + "Beauty%2FVitamins%2Fcapsules-gel-golden-33355.jpg?alt=media&token=06cf989a-5cff-4ee2-82c3-d58b7be4e48a",
        "Body & Bath": prefix + "Beauty%2FBodyandBath%2Faromatherapy-aromatic-bath-towels-206299.jpg?alt=media&token=ae2a49df-654c-4def-b4aa-f81a3d7e4234",
        "Skin Care": prefix + "Beauty%2FSkinCare%2Fbottle-brand-container-2442898.jpg?alt=media&token=ff593554-6c51-4673-8238-66ed774086c1",
        "Massages": prefix + "Beauty%2FMassages%2Faccommodation-beach-bed-1531672.jpg?alt=media&token=258721c5-cef6-4bf7-845a-07c7a477604b",
        "Salons & Barbers": prefix + "Beauty%2FSalonsandBarbers%2Fbald-barber-barbershop-2014808.jpg?alt=media&token=afc78ec2-b3c5-4dbf-81ca-29a0615de8e9",
    
        "New Cars":  "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fnew-cars.jpg?alt=media&token=0d2bceee-823e-4402-88ad-b693776f77f0",
        "Imported Cars": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fimported-cars2.jpg?alt=media&token=a36e040e-d78e-4f90-9306-9fae6bb03ef2",
        "Locally Used Cars": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Flocally-used-cars.jpg?alt=media&token=43a9999f-45b0-4f5f-b4d3-6d2458faa7ae",
        "Used Cars with Warranty": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2FUsed%20Cars%20with%20Warranty.jpg?alt=media&token=5838a366-3e03-43bc-b6d4-8cd93c6f1aec",
        "Trucks": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Ftruck-driver.jpg?alt=media&token=f4b99a1b-5c22-4a3a-bc94-0d89e9b210a0",
        "Motorbikes": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fbike.jpg?alt=media&token=daf13bf6-0436-436f-9cc8-c79be5faec58",
        "Spare Parts": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fspareparts.jpg?alt=media&token=cbc66994-2093-4c65-b04c-da4161c82a65",
        "Car Insurance": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fnew-cars.jpg?alt=media&token=0d2bceee-823e-4402-88ad-b693776f77f0",
        "Car Loans": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fimported-cars2.jpg?alt=media&token=a36e040e-d78e-4f90-9306-9fae6bb03ef2",
        "Mechanics": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fmechanic.jpg?alt=media&token=8b1167c5-cb3f-430f-9fb7-bd5ec290112b",
        "Accessories": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Faccessories.jpg?alt=media&token=dcdf2de5-047e-4a65-a4c1-ee9a8174a048",
        "Car Wash": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fcar-wash.jpg?alt=media&token=edd651b1-031e-401b-aab9-761b72eab37b",
        "Drivers": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fdrivers.jpg?alt=media&token=13af67de-e165-40dc-b12a-8508d9f45051",
        "Drivers with Cars": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fdrivers.jpg?alt=media&token=13af67de-e165-40dc-b12a-8508d9f45051",
        "Drivers with Motorbike": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fdriver%20on%20bike.jpg?alt=media&token=858212de-86ef-42fa-ab27-17be9fcf99bd",
        "Drivers with Truck": "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Ftruck-driver.jpg?alt=media&token=f4b99a1b-5c22-4a3a-bc94-0d89e9b210a0",
    
        "Airtime": prefix + "Repair_Refill%2FAirtime.jpg?alt=media&token=6641238c-60f7-4492-8ce9-c172e3cdd505",
        "Fuel Supply": prefix + "Repair_Refill%2FGas%20Supply.jpg?alt=media&token=8797a949-9a59-40e1-9073-fc97b1f073ab",
        "Gas Supply": prefix + "Repair_Refill%2FGas%20Supply.jpg?alt=media&token=8797a949-9a59-40e1-9073-fc97b1f073ab",
        "Trade-Ins": prefix + "Repair_Refill%2FTrade-Ins.jpg?alt=media&token=b92872b9-a9bc-4d27-b33e-169f44b7007b",
        "Phone Repair": prefix + "Repair_Refill%2FPhone%20Repair.jpg?alt=media&token=adff395a-9d54-408b-b099-7aa07519c4d3",
        "Computer Repair": prefix + "Repair_Refill%2FComputer%20Repair.jpg?alt=media&token=facee9d3-4289-4c63-baf2-77bf1cea33dd",
        "TV Repair / Installation": prefix + "Repair_Refill%2FTV%20Repair%3AInstallation.jpg?alt=media&token=2959131c-756a-44b0-999c-617039da1a12",
        "Electronics Repair": prefix + "Repair_Refill%2FElectronics%20Repair.jpg?alt=media&token=d4d2d5c2-067e-449a-b4b2-48b3c3a58921",
        "Appliance Repair": prefix + "Repair_Refill%2FOther%20Repair.jpg?alt=media&token=4f38fefa-143d-4704-8196-5ed86bb8bda1",
        "Home Repair": prefix + "Repair_Refill%2FHome%20Repair.jpg?alt=media&token=4963c0c4-e236-4110-89cb-2e704f4c12f8",
        "Other Repair": prefix + "Repair_Refill%2FOther%20Repair.jpg?alt=media&token=4f38fefa-143d-4704-8196-5ed86bb8bda1",
    
        "Hotels": prefix + "Travel%2FHotels.jpg?alt=media&token=52798a38-818a-4012-8794-16dc3132814e",
        "Accomodation & Hostels": prefix + "Travel%2FAccomodation-%26-Hostels.jpg?alt=media&token=3005a52c-3519-4fcc-b132-8291d986ac4a",
        "Tours": prefix + "Travel%2FTours.jpg?alt=media&token=8e5cec46-bc39-4c57-ae30-7b83907f9a42",
        "Sights": prefix + "Travel%2FSights.jpg?alt=media&token=cbeefa02-e944-480e-b0ca-ef1c3f7a55bb",
        "Flights": prefix + "Travel%2FFlights.jpg?alt=media&token=b84d615a-8fc6-4154-85c9-d20db88780df",
        "Bus & Train Rides": prefix + "Travel%2FBus-%26-Train-Rides.jpg?alt=media&token=2bd0dbd1-1272-4eaf-ac51-a0bcd9833748",
    
        "Pharmacies": prefix + "Health%2FPharmacies%2FPharmacy.jpg?alt=media&token=db11dfbf-2d57-4e71-8ed8-be3d1dcc0161",
        "Labs": prefix + "Health%2FLabs%2FLaboratory%203.jpg?alt=media&token=087c8dfa-c2c3-4bac-bc22-393ff4c1ad64",
        "General Doctors / Primary Clinics": prefix + "Health%2FGeneralDoctorsPrimaryClinics%2FDoctor.jpg?alt=media&token=1161aa3b-5e84-48ac-a55a-4a6c6567d83c",
        "Eye Doctors / Clinics": prefix + "Health%2FEyeDoctorsClinics%2Feye%20doctor%202.jpg?alt=media&token=8e8908e2-3470-4291-aaf1-c63fcedc8591",
        "Ear, Nose, Throat": prefix + "Health%2FEarNoseThroat%2FEar.jpg?alt=media&token=a1a361c4-926d-44a1-8969-da5971d5119e",
        "Dentists / Dental Clinics": prefix + "Health%2FDentistsDentalClinics%2FDentist%202.jpg?alt=media&token=782bee7a-287b-4e81-bf19-9a28bc95c925",
        "Nutritionsts": prefix + "Health%2FNutritionsts%2FNutritionsts.jpg?alt=media&token=e65cb460-483f-4ef7-ab97-2e19fc479dd7",
        "Physiotherapists": prefix + "Health%2FPhysiotherapists%2FPhysiotherapy.jpg?alt=media&token=dca07cb8-520b-474b-8a0a-d5b3d1b4e60e",
        "Clinical Psychologists": prefix + "Health%2FClinicalPsychologists%2FClinical%20Psychologist%20copy.jpg?alt=media&token=ba159670-4cf8-42fa-93c5-bea60174e1e5",
        "Psychiatrists": prefix + "Health%2FPsychiatrists%2FTherapist%20%26%20Physciatrist%203.jpg?alt=media&token=daefc068-43ca-4816-bac4-39dbe4aaa87a",
        "Specialty Nurses": prefix + "Health%2FSpecialtyNurses%2FNurse%202.jpg?alt=media&token=7eb178ca-f5b5-4c3c-b088-e6cc3bbf117e",
        "Sexual Health Specialist / Clinic": prefix + "Health%2FSexualHealthSpecialistClinic%2Fsexual%20Health%20SpecialistClinic.jpg?alt=media&token=1ae9b4b6-68ce-4cba-90d6-e8dea9ca7612",
        "Occupational Therapists": prefix + "Health%2FOccupationalTherapists%2FTherapist%20%26%20Physciatrist%202.jpg?alt=media&token=96374782-1904-4619-90a7-2fc5aee9dffe",
        "Other Specialists": prefix + "Health%2FOccupationalTherapists%2FTherapist%20%26%20Physciatrist%202.jpg?alt=media&token=96374782-1904-4619-90a7-2fc5aee9dffe",
        "Health Shops / Products": prefix + "Health%2FHealthShopsProducts%2FHealth%20Shops%20Products.jpg?alt=media&token=45771bc5-508e-42fa-9fec-6de5985e34e1",
        "Blood Banks": prefix + "Health%2FBloodBanks%2FBlood%20Bank%202.jpg?alt=media&token=c5077a7d-b71d-4e07-8877-970b4bc42d94",
        "Medical Instruments": prefix + "Health%2FMedicalInstruments%2FMedical%20Instruments.jpg?alt=media&token=20252f29-964d-4273-a75e-2d7cf2b942c8",
        "Gyms & Fitness Centers": prefix + "Health%2FGymsAndFitnessCenters%2FGym%202.jpg?alt=media&token=1c971bd3-404a-4e8d-ab06-cee347e35884",
    
    
        "Machinery": prefix + "Commercial%2Fmachinery.jpg?alt=media&token=b82de776-6ca4-4627-a634-0e25cc99de0a",
        "Construction": prefix + "Commercial%2Fconstruction.jpg?alt=media&token=89c156f8-380b-40d9-8b7e-732d87dbbe1b",
        "Tools & Parts": prefix + "Commercial%2FTools-and-parts.jpg?alt=media&token=71e2d6d2-4c9e-470c-abc7-33684384ba0b",
        "Repair & Maintenance": prefix + "Commercial%2FRepair-and-maintenance.jpg?alt=media&token=289e77d1-b996-45a8-bece-5c5724590871",
        "Waste Recycling": prefix + "Commercial%2FWaste-Recycling.jpg?alt=media&token=d584a7d4-10d2-4b52-86de-418969d7358b",
        "Cement, Wood, Materials": prefix + "Commercial%2FCement-and-Wood.jpg?alt=media&token=2a0c2b78-ee65-4491-abf3-3089e2199071",
        "Commercial Accessories": prefix + "Commercial%2FCommercial-Accessories.jpg?alt=media&token=f68481f2-4c1a-46f0-b645-9eef244a1e70",
        "Office Supply": prefix + "Commercial%2FOffice-Supplies.jpg?alt=media&token=bf964785-627e-4564-8b15-701148b8c060",
        "Business Centers": prefix + "Commercial%2FBusiness-Centre.jpg?alt=media&token=7d8383e0-c1ff-44c8-9761-b9682bdf6514",
        "Crowdfunding, Investments": prefix + "Commercial%2FCrowdfunding-Investments.jpg?alt=media&token=b9318ae0-4f81-478e-bfba-ef9c752adea2",
        "Donations, Initiatives, Sponsorship": prefix + "Commercial%2FCrowdfunding-Investments.jpg?alt=media&token=b9318ae0-4f81-478e-bfba-ef9c752adea2",
    
        "Books": prefix + "Art%2Fbooks.jpg?alt=media&token=b7240976-6d39-4aa0-bd9a-7d2b80dfdc17",
        "Crafts & Paintings": prefix + "Art%2Fcrafts-and-paintings.jpg?alt=media&token=0c1cc911-d3e5-4c55-a2be-26ecb23bcbf1",
        "Collectibles": prefix + "Art%2Fcollectibles.jpg?alt=media&token=92473650-b9ea-4d7f-943d-476cbaeccf02",
        "Movies": prefix + "Art%2Fmovies.jpg?alt=media&token=f8bb9773-5067-4337-8cc8-88a960b8d80d",
        "Music": prefix + "Art%2Fmusic.jpg?alt=media&token=01b2e681-557a-4594-b0fd-1710fdadf634",
        "Musical Instruments": prefix + "Art%2Fmusical-instruments.jpg?alt=media&token=cb4d170b-b882-4c9f-ae68-9e4a88aa6bd6",
        "Concerts, Performances, Events": prefix + "Art%2FConcerts-Performances-Events.jpg?alt=media&token=e108f8c7-46f0-4bee-b84b-756a6173e530",
        "Exhibitions, Galleries": prefix + "Art%2Fexhibitions-galleries.jpg?alt=media&token=61589d5a-bc72-42c4-94b9-a8ad6a52d3ab",
        "Theater, Comedy, Shows": prefix + "Art%2FTheater-Comedy-Shows.jpg?alt=media&token=38f5ec88-d9b7-4b64-971f-a2ab604e420f",
        "General Art": prefix + "Art%2Fgeneral-art.jpg?alt=media&token=81045dbe-85ac-4be4-814d-f4d3eaffca5c",
    
        "Sales and Marketing": prefix + "Quick%20Jobs%2Fsales-marketing.jpg?alt=media&token=2e4d024e-af63-47ba-b117-f33d061d4451",
        "Physical Workmanship": prefix + "Quick%20Jobs%2Fphysical-workmanship.jpg?alt=media&token=876dc123-9787-470f-817e-8616140319e6",
        "Technical, IT": prefix + "Quick%20Jobs%2Ftechnical-IT.jpg?alt=media&token=c5506621-3d60-4958-b51e-ebf51b5d3beb",
        "Creatives, Design, DJ, Entertainer": prefix + "Quick%20Jobs%2Fcreatives.jpg?alt=media&token=74f2b989-9a86-4c93-b87e-731f9e893d9a",
        "Waiters, Hostesses, Representatives": prefix + "Quick%20Jobs%2Fwaitress.jpg?alt=media&token=869149a5-9cd4-4634-91e4-b9aad10fa520",
        "Teaching, Nanny, Nursing": prefix + "Quick%20Jobs%2Fteaching.jpg?alt=media&token=56b04ad6-1e92-4347-bbaa-88faf519962f",
        "Household, Cooking, Errands": prefix + "Quick%20Jobs%2Fhousehold-cooking.jpg?alt=media&token=7d85e93f-ade8-458c-a355-0faff1428bd4",
        "Office and Admin": prefix + "Quick%20Jobs%2Foffice-admin.jpg?alt=media&token=c0caac04-3bbf-42d7-a238-2b9e7ff2a24b",
        "Extra Cash": prefix + "Quick%20Jobs%2Fextra-cash.jpg?alt=media&token=161fb594-dcc2-407f-8ad1-8a73570151a5",
        "Other": prefix + "Quick%20Jobs%2Fother.jpg?alt=media&token=4ddf5ff9-a98a-4d1a-a415-c3b7c87bfc49",
    
    
        "Legal": prefix + "Professional%2Flegal.jpg?alt=media&token=3f25b084-2a8b-44d2-83fe-329b492353d3",
        "Marketing": prefix + "Professional%2Fmarketing.jpg?alt=media&token=2d516ee9-0e52-4a0b-8602-71c5b9f7fb66",
        "Digital Marketing": prefix + "Professional%2Fdigital-marketing.jpg?alt=media&token=a79741c0-6582-40fb-9507-56c7eece97e3",
        "PR": prefix + "Professional%2FPR.jpg?alt=media&token=786941be-68ad-4826-b396-42064ad717c8",
        "Graphics Design": prefix + "Professional%2Fgraphic-design.jpg?alt=media&token=d05e5c7c-c707-452c-95d8-b1f1d5edb731",
        "Writing / Content": prefix + "Professional%2Fwriting-content.jpg?alt=media&token=cecc92d7-be63-403d-9dfa-6936599869d9",
        "Translation": prefix + "Professional%2Ftranslation.jpg?alt=media&token=5e80febf-9a9b-438f-9006-5ab64030435e",
        "Video": prefix + "Professional%2Fvideo.jpg?alt=media&token=a4f67f9a-e2d7-442b-b53f-aa26a3ebe733",
        "Animation": prefix + "Professional%2Fanimation.jpg?alt=media&token=3293e850-e11c-4c3f-9ade-e7908f7f8948",
        "Photography": prefix + "Professional%2Fphotography.jpg?alt=media&token=eda7ee51-aa9b-4e0b-af53-23235f50d99f",
        "Music": prefix + "Professional%2Fmusic.jpg?alt=media&token=c91d9a31-2e92-4051-9042-01a299bd2243",
        "Marketing Materials, Printing, Branding": prefix + "Professional%2Fbranding-printing.jpg?alt=media&token=2d8d2a52-a3e8-4c75-9b86-2fe77347af77",
        "Software Development": prefix + "Electronics%2FSoftware.jpg?alt=media&token=4771fe01-1329-461a-87a8-022870149400",
        "Website Development": prefix + "Professional%2Fwebsite.jpg?alt=media&token=bc61f8dc-eea4-45d1-aec6-466d8510ee50",
        "App Development": prefix + "Professional%2Fbranding-printing.jpg?alt=media&token=2d8d2a52-a3e8-4c75-9b86-2fe77347af77",
        "Computer & IT Services": prefix + "Professional%2Fcomputer-IT.jpg?alt=media&token=3c0fe653-70c3-46e2-94e8-958802a77c3a",
        "General Business": prefix + "Professional%2Fgeneral-business.jpg?alt=media&token=834f248b-3a1b-4ee3-83db-f5da7991c393",
        "Facility Management": prefix + "Professional%2Ffacility-management.jpg?alt=media&token=052058ff-38a3-4db4-8fb9-dc345deddeff",
        "Training & Education": prefix + "Professional%2Fteaching.jpg?alt=media&token=099a48bb-25e6-479d-8af5-9233e0e0852e",
        "Training - Soft Skills": prefix + "Professional%2Fteaching.jpg?alt=media&token=099a48bb-25e6-479d-8af5-9233e0e0852e",
        "Coaching": prefix + "Professional%2Fcoaching.jpg?alt=media&token=4be562e8-5eae-49f7-8d93-96378d280307",
        "Visa & Concierge": prefix + "Professional%2Fvisa-concierge.jpg?alt=media&token=a4122617-4721-4cf3-8456-dd06986a3a42",
        "Events & Catering": prefix + "Food%2FCatering%2Fbanquet-catering-chairs-1395967.jpg?alt=media&token=b36f753c-28cc-4cca-a4d6-f0651deeb26a",
        "Wedding Planning": prefix + "Professional%2Fwedding-planning.jpg?alt=media&token=3b568186-3983-4427-980b-63d9dba99bd3",
        "Trade Services": prefix + "Professional%2Ftrade-services.jpg?alt=media&token=865711ba-0bbd-420e-b645-27b03e6371c1",
        "Financial & Investment": prefix + "Professional%2Ffinancial-investment.jpg?alt=media&token=96f076dd-5d3b-41b1-b193-23c04178aef5",
        "Insurances": prefix + "Professional%2Finsurance.jpg?alt=media&token=ca754b7f-c519-427d-85d6-0af3a93379e0",
        "Loans": prefix + "Professional%2Floans.jpg?alt=media&token=a8350eb9-b473-4ba2-b39b-ceb58aca4dbb",
        "Business Consulting": prefix + "Professional%2Fbusiness-consulting.jpg?alt=media&token=0104fe0b-1677-48cd-be61-0260698fee4e",
        "IT Consulting": prefix + "Electronics%2FSoftware.jpg?alt=media&token=4771fe01-1329-461a-87a8-022870149400",
        "Other Services": prefix + "Professional%2Fother-services.jpg?alt=media&token=50a584e2-6ef2-4159-af00-e5ebe045f45d",
        "Fun / Entertainment": prefix + "Professional%2Ffun-entertainment.jpg?alt=media&token=2ec41489-abfd-48bb-a2dd-f46b318edc3c",
    
        "Plumbing": prefix + "Handyman%2FPlumbing.jpg?alt=media&token=9f763caf-1a91-439b-b48f-716c37372a5f",
        "Electrician": prefix + "Handyman%2FElectrician.jpg?alt=media&token=f68488c6-10b9-462c-b320-6bb71ebda179",
        "AC": prefix + "Handyman%2Fair-conditioner2.jpg?alt=media&token=a9597139-bdfb-40d2-9ed3-70ee68fa768b",
        "Generator": prefix + "Handyman%2Fgenerator.jpg?alt=media&token=76b19859-7239-4f22-bbb5-bade68563fd7",
        "Appliances": prefix + "Handyman%2Fappliances.jpg?alt=media&token=f3217f00-5b6c-4019-bcf1-b7bc274ef864",
        "Painting": prefix + "Handyman%2Fpainting.jpg?alt=media&token=6447ac18-4e07-451d-8e33-c5c8f306df8f",
        "Fumigation": prefix + "Handyman%2Ffumigation.jpg?alt=media&token=9526c631-33d9-476f-a9a0-acae84b4f5d7",
        "Doors, Knobs, Locks": prefix + "Handyman%2FDoors_Knobs.jpg?alt=media&token=5d666dec-1eb9-4a51-bc66-fe4b6eafcf0b",
        "Curtains, Blinds": prefix + "Handyman%2FCurtains_and_Blinds.jpg?alt=media&token=ccfe4ccb-dd2a-4563-b892-998480a85ec2",
        "Flooring": prefix + "Handyman%2Fflooring.jpg?alt=media&token=229bef72-5710-4bcf-a235-9b323697c190",
        "Walls & Ceiling": prefix + "Handyman%2Fwalls_ceiling.jpg?alt=media&token=14d6d60b-aa73-44ac-aee9-2aca8f6c806d",
        "Gardening": prefix + "Handyman%2FGardens_and_Flowers.jpg?alt=media&token=3f56b5eb-9d34-4e2f-87a1-8d4737a6b061",
        "Furniture Assembly": prefix + "Handyman%2Ffurniture-assembly.jpg?alt=media&token=b6810cd6-6a90-4a8f-a12f-edbdf6c80a84",
        "General Work": prefix + "Handyman%2Fgeneral_work.jpg?alt=media&token=fc21a4f4-33cc-49cb-b5a5-ee2b389ccd79",
    }

}


export default data