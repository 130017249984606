import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { uniformImageHolder, displayprice } from './utilityComponents'
import Skeleton from '@yisheng90/react-loading';
import Product from './product1'
import ProductHeader from './Header/ProductHeader'
import Footer from './Footer/footer'
import call from '../assets/call-icon.png'
//import Product from  './product1'
import heart from '../assets/heart4.svg'
import './styles/provider.css'
import ElementSlider from './slider'
const borderStyle = {

}

const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"



const centerContent2 = {
    width: '100%',
    margin: 'auto',
    minHeight: '100vh',
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
}



const Shops = (props) => {
    const [shopNamefromURL, setShopNameFromURL] = useState("")
    const [initialURL, setInitialURL] = useState("")
    const [defaultURLandValidShopnamePresent, setURLandShopNameValidity] = useState("")
    const [shopData, setShopData] = useState({})
    const [loading, setLoading] = useState(true)
    const [theme, setTheme] = useState("")
    const [shopCategory, setShopCategory] = useState()
    const [redirect, setRedirect] = useState(false)
    const [shopProducts, setShopProducts] = useState(productData)
    const [providerID, setProviderID] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [description, setDescription] = useState("")
    const [pictureURL, setPictureURL] = useState("")
    const [backgroundPictureURL, setBackgroundPictureURL] = useState("")
    const [phone, setPhone] = useState(false)
    const [phoneDisplay, setPhoneDisplay] = useState('Show Contact')
    const [phoneNumber, setPhoneNumber] = useState("")

    const parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            //await this.setState({ initialURL: , shopNamefromURL: })
            await setInitialURL(fullURLsubStrings[2])
            await setShopNameFromURL(fullURLsubStrings[4])
            await setProviderID(fullURLsubStrings[4])
            console.log('the providerID from url is: ', providerID);
            //await this.setState({ url: fullURLsubStrings[2] })*/
            // return fullURLsubStrings[2]; 
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }


    const fetchDataWithShopName = async () => {
        console.log('starting fetchShopOwnerDetailsbyxID...')
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    shopName: `${shopNamefromURL}`
                })
            });
            const r2 = await r.json();

            if (r2.msg == 'SUCCESS') {
                // await this.setState({ hasCustomDomain: true }) 
                console.log("Server Data is: ", r2.data);
                console.log("Profile ", r2.data.profile);


                let shopData = r2.data
                let theme = r2.data.shop.shopType
                let shopCategory = r2.data.shop.shopCategory
                console.log("shopCategory is:  ", shopCategory);
                let shopPost = r2.data.posts
                /* console.log('shopPost', shopPost)
               
                let shop = r2.data.shop
                console.log('shops', shop) */
                //await this.setState({ defaultURLandValidShopnamePresent: true, shopData, loading: false, theme, shopCategory })
                await setURLandShopNameValidity(true)
                await setShopData(shopData)
                await setLoading(false)
                await setTheme(theme)
                await setShopCategory(shopCategory)
                await setShopProducts(shopPost)
                //await this.setState({ defaultURLandValidShopnamePresent: true, shopData, loading: false, shopCategory })



            } else {
                console.log('no valid shopName detected from URL... ', r2.msg)
                //fetchShopOwnerDetailsbyxID()
                setLoading(false)
                //setRedirect(true)
                //this.setState({ loading: false, redirect: true })
            }

            return

        } catch (err) {
            console.log("Error from firebase using fetchdatawithshopname is: ", err);
            setLoading(false)
            setRedirect(true)
            //this.setState({ loading: false, redirect: true })

        }

    }


    const fetchShopOwnerDetailsbyxID = async () => {
        //console.log('attempting to fetch data with URL', this.state.initialURL)
        if (providerID.length == '') { return }
        console.log('the providerID received is fetchShopOwnerDetailsbyxID in: ', providerID);
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'provider',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    providerID: providerID,
                    location: 'lagos'
                })
            });
            const r2 = await r.json();
            console.log('r2 provider fetched in fetchShopOwnerDetailsbyxID is ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                let data = r2.result
                //setShopData(r2.results)
                await setShopProducts(data.posts)
                await setBusinessName(data.businessName)
                await setDescription(data.description)
                await setPictureURL(data.pictureURL)
                await setBackgroundPictureURL(data.backgroundPictureURL)
                await setPhoneNumber(data.phoneNumber)
            } else {
                console.log('no custom URL detected... ', r2.msg)

                //this.fetchDataWithShopName()
                //this.setState({ hasCustomDomain: false, loading: false })

            }

        } catch (err) {
            console.log("Error from firebase using fetchdatawithurl is: ", err);
            fetchShopOwnerDetailsbyxID()

        }

    }
    useEffect(() => {
        fetchShopOwnerDetailsbyxID()
    }, [providerID])

    useEffect(() => {
        parseURLandReturnRelevantURL()
    }, [])
    let fone = JSON.stringify({ phoneNumber })
    let newfone = fone.substring(13)
    let numm = `tel:${newfone}`

    return (
        <div style={{ paddingBottom: 0 }}>
            <ProductHeader backgroundPictureURL={backgroundPictureURL} />
            <div class='shadow-md conatiner-fluid' style={{ paddingBottom: 20, backgroundColor: '#F5F5F5', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, width: '100%', }}>
                {/* {backgroundPictureURL &&<div style={{ paddingBottom: 20, backgroundColor: '#F5F5F5', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, width: '100%', height:100}}>

                </div>} */}
                <div className='container' style={{ display: 'flex', padding: 20, paddingLeft: 30, paddingBottom: 0, }}>
                    {/* <div style={{ float: 'left', marginTop: -50, }}> {uniformImageHolder(pictureURL || providerPhoto, 100, 100, '4px solid #F5F5F5', 15)}</div> */}
                    {pictureURL ? <div style={{ float: 'left', marginTop: -50, }}>
                        {uniformImageHolder(pictureURL || url, 100, 100, '4px solid #F5F5F5', 15)}
                    </div>
                        :
                        <div style={{ float: 'left', marginTop: -40, borderRadius: 15, overflow: 'hidden' }}>
                            <Skeleton width={100} height={100} />
                        </div>
                    }
                    <div style={{ float: 'right', display: 'flex', flexDirection: "column", marginLeft: 10, textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'wrap' }}>
                        <small class='text-truncate'><b>{businessName || ""}</b></small>
                        <small class='text-truncate text-wrap' style={{ marginTop: 5, fontSize: 11, color: '#666' }}>{description || ""}</small>
                        {(businessName || description || pictureURL) && <div style={{ transition: 'all 1s ease', marginTop: 10, marginLeft: 0 }}>
                            <button type="button" style={{ textDecoration: 'none' }} class="btn btn-sm px-4 py-1 py-sm-2 btn-success m-1" onClick={() => { setPhone(true); setPhoneDisplay(phoneNumber) }}>
                                {phone == true ? <a href={numm} style={{ fontWeight: 'bold', paddingLeft: 3 }} style={{ textDecoration: 'none' }}>
                                    <small style={{ margin: 10, fontWeight: 'bold', color: '#ffffff', textDecoration: 'none', }}>&nbsp;<img src={call} alt="" />{phoneDisplay}</small>
                                </a> : <small style={{ margin: 10, fontWeight: 'bold', fontSize: 14 }}>{phoneDisplay}</small>}
                            </button>
                        </div>}
                    </div>
                </div>
            </div>

            <section style={{ marginTop: 10, minHeight: '30vh' }}>
                <div class='d-flex justify-content-center  mt-4 mb-4 p-0'>
                    <div class='container mt-4 row p-0 m-0'>
                        {shopProducts.map((item, id) => {
                            return (
                                <div class='col-md-3 zoomin  py-2 p-1 p-sm-2 ' style={{ width: '50%' }}>
                                    <div class='shadow-sm p-0 pt-0' style={{ border: '1px solid rgba(100,100,100,0.1)', borderRadius: 10 }}>
                                        {item.pictureURL ? <Link to={`/${providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <div class=''
                                                style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', position: 'relative' }}>
                                                {uniformImageHolder(item.pictureURL || url, '100%', '12rem', '', 10)}
                                            </div>
                                        </Link>
                                            :
                                            <Skeleton width={"100%"} height={"12rem"} />}
                                        {/* <div class='col-md-12 p-0 pt-0' style={{ marginTop: -10 }}>{uniformImageHolder(item.pictureURL || url, '100%', '12rem', '', 10)}</div> */}
                                        <div class='col-md-12 ' style={{ paddingBottom: 20, paddingTop: 0, width: "100%", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            <div style={{ width: "100%", marginTop: 10, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                <Link to={`/${providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                    <small class='text-truncate' style={{ fontSize: 14, textOverflow: 'ellipsis', color: 'black' }} >{item.title || item.text || "..."}</small>
                                                </Link>
                                            </div>
                                            <div class='container row d-flex align-items-center ' style={{ width: "100%", textOverflow: 'ellipsis' }}>
                                                {((item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount <= 0)) || (!item.hasOwnProperty("postPriceDiscount"))) && <small class='text-truncate py-2' style={{ fontSize: 13, }} ><b>
                                                    {displayprice(item.price, item.priceType, item.priceCurrency) || '...'}
                                                </b></small>}
                                                {(item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount > 0)) && <small style={{ color: 'black', fontWeight: 'bold' }} className="py-2 text-truncate">
                                                    <strike>{displayprice(item.price, item.priceType, item.priceCurrency) || '...'}</strike>
                                                </small>}
                                                {(item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount > 0)) && <small class='text-truncate ml-2' style={{ fontSize: 13, color: '#FF1E78' }} ><b>
                                                    {item.priceCurrency === 'NGN' ? '₦' : '₵'}{(item.price - item.postPriceDiscount) || '...'}
                                                </b></small>}
                                            </div> 
                                            <hr />
                                            <div style={{ width: "100%", marginTop: -5, overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                <small class='text-truncate col-md-12  pl-0' style={{ fontSize: 11 }} >sold by <span class='text-success text-truncate'>{businessName || "..."}</span></small>
                                                <Link to={`/${providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <button type="button" class="btn btn-sm btn-inline btn-success px-2 py-1 py-sm-2 mt-2 d-flex justify-content-center align-items-center" style={{ width: '100%', }} >
                                                            <span className='mx-2' ><small style={{ fontSize: 14 }} class='text-truncate'>View Product<span></span></small></span>
                                                        </button>
                                                    </div>
                                                </Link>

                                            </div>
                                        </div>
                                        {(item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount > 0)) && <div style={{ position: 'absolute', top: "10.5rem", left:20, backgroundColor: '#FF1E78', padding: 5, paddingBottom: 0, paddingTop: 0, borderRadius: 10 }} >
                                            <small style={{ color: 'white', fontWeight: 'bold', fontSize: 10, marginTop: -10 }} >
                                                {Math.floor(item.postPriceDiscount / item.price * 100)}<i>{"% off"}</i>
                                            </small>
                                        </div>}
                                    </div>

                                </div>)
                        })}
                    </div>
                </div>
            </section>
            <div style={{ marginBottom: -40 }}>
                <Footer />
            </div>



        </div>
    )
}

export default Shops