import React, { useEffect, useState, useLayoutEffect } from 'react';
import LazyLoad from 'react-lazy-load';
import Providers from './Provider/providers';
import { Link, Redirect } from 'react-router-dom';
import Professionals from './Professionals/professionals';
import Jobs from './Jobs/jobs';
import Shops from './Shops/shops';
import ProductShowcase from './ProductShowcase';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import banner1image from './images/Group-674.png';
import banner2image from './images/Mask-Group-6.png';
import Skeleton from '@yisheng90/react-loading';
import { uniformImageHolder, displayprice } from './utilityComponents';
import { Banner1, Banner2, Banner6 } from './utilityComponents';
import { Spinner, Modal, Form } from 'react-bootstrap';
import {
	BrowserView,
	MobileView,
	isBrowser,
	isAndroid,
	isIOS,
	isMobile,
} from 'react-device-detect';
import * as rdd from 'react-device-detect';
import '../styles/utility.css';

import searchIcon from '../assets/searchIcon.svg';
import DD from '../assets/ddial.jpeg';
import Header from '../components/Header/header';
import Newsletter from './Newsletter/newsletter';
import Footer from './Footer/footer';
import FAQ from './FAQ/faq';
import data from './imageData';

import WakandaLogo from '../assets/Wakanda-Logo.png';
import google from '../assets/google.png';
import apple from '../assets/Apple.png';
//import banner1image2 from './images/BFtext.png'
import banner1image2 from '../assets/new-year-discounts.png';
let url =
	'https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500';
let Chadwick =
	'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/chadwick.jpeg?alt=media&token=0b01b488-39b5-4c26-a42a-d18691f80b5f';
let OLDbg =
	'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680';
let NEWbg =
	'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2Fwkmarket-bground4.jpg?alt=media&token=644f492d-403f-43ae-b7d1-64ac77546fe2';
let SEARCH = '';

let LOCATIONS = [
	{ name: 'Lagos', value: 'lagos' },
	{ name: 'Ibadan', value: 'ibadan' },
	{ name: 'Port Harcourt', value: 'portHarcourt' },
	{ name: 'Kaduna', value: 'kaduna' },
	{ name: 'Abeokuta', value: 'abeokuta' },
	{ name: 'Ghana', value: 'ghana' },
	{ name: 'Abuja', value: 'abuja' },
];

const jobs = [
	{ name: 'Sales and Marketing', color: '#4160E0' },
	{ name: 'Graphic Design', color: '#F46E56' },
	{ name: 'Office & Admin', color: '#13C170' },
	{ name: 'Teaching, Nanny, Nursing', color: '#C43699' },
	{ name: 'IT, Technicals', color: '#4DC5E2' },
];

let categories = [
	'Electronics',
	'Fashion',
	'Food',
	'Beauty',
	'Cars',
	'Health',
	'Handyman',
	'Appliances',
];

const marketing = [
	'- Sell your products and services',
	'- Use the Wakanda Wallet and Escrow Service',
	'- Get free business/e-commerce Website and a lot more…',
];

function getFaviconEl() {
	return document.getElementById('favicon');
}

function useMediaQuery() {
	const [screenSize, setScreenSize] = useState([0, 0]);

	useLayoutEffect(() => {
		function updateScreenSize() {
			setScreenSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateScreenSize);
		updateScreenSize();
		return () => window.removeEventListener('resize', updateScreenSize);
	}, []);

	return screenSize;
}

const AllComponents = () => {
	const [SCREEN_WIDTH] = useMediaQuery();
	const [location, setLocation] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [providerSearchResults, setProviderSearchResults] = useState([]);
	const [searchMsg, setSearchMsg] = useState('...');
	const [selected, setSelected] = useState('offers');
	const [show, setShow] = useState(true);
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [modalState, setModalState] = useState(true);
	const [area, setArea] = useState();
	const [redirect, setRedirect] = useState(false);
	const [key, setKey] = useState('offers');

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const searchStuff = async (search_term) => {
		if (searchTerm.length < 3) return;
		try {
			const r = await fetch(
				'https://us-central1-afbase-7ee60.cloudfunctions.net/worldFind',
				{
					method: 'POST',
					//mode: "no-cors",
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						searchTerm: search_term || '',
						authPhrase: 'A¶§Ô˝D3ßºƒn',
						amount: 300,
						location: location || 'lagos',
						//searchProviders: true
					}),
				}
			);
			const r2 = await r.json();
			if (r2.msg == 'SUCCESS') {
				if (r2.results.length == 0 && providerSearchResults.length == 0) {
					setSearchMsg(
						`No results found for ${SEARCH} in ${properLocationName(
							location
						)}...`
					);
				} else {
					setSearchMsg(
						`found ${
							r2.results.length + (providerSearchResults.length || 0)
						} results for ${SEARCH} in ${properLocationName(location)}...`
					);
				}
				await setSearchResults(r2.results);
			} else {
			}
			await searchProviders(search_term);
		} catch (err) {}
	};
	const searchProviders = async (search_term) => {
		try {
			const r = await fetch(
				'https://us-central1-afbase-7ee60.cloudfunctions.net/worldFind',
				{
					method: 'POST',
					//mode: "no-cors",
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						searchTerm: search_term || '',
						authPhrase: 'A¶§Ô˝D3ßºƒn',
						amount: 300,
						location: location || 'lagos',
						searchProviders: true,
					}),
				}
			);
			const r2 = await r.json();
			if (r2.msg == 'SUCCESS' && r2?.contacts) {
				if (r2.contacts.length > 0) {
					setSearchMsg(
						`found ${
							(searchResults.length || 0) + (r2.contacts.length || 0)
						} results for ${SEARCH} in ${properLocationName(location)}...`
					);
				}
				await setProviderSearchResults(r2.contacts);
			} else {
			}
		} catch (err) {}
	};

	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	const properLocationName = (location_value) => {
		if (!location_value) return 'your location';
		let result = '';
		result = LOCATIONS.filter((item) => {
			if (item.value == location_value) return true;
		});
		return result[0].name;
		//return 'your location'
	};

	const handleSearch = async (term) => {
		term = term.trim();
		if (term === '' || term.length < 3 || SEARCH.trim().length < 3) {
			setSearchResults([]);
			/* setSearchMsg('')
            setSearchTerm('') */
			return;
		} else {
			await setSearchMsg(`searching for ${term}...`);
			await setSearchTerm(term);
			await searchStuff(term);
		}
	};

	const cancelSearch = () => {
		setSearchTerm('');
		setSearchResults([]);
	};

	const parseURLandReturnRelevantURL = async () => {
		try {
			const fullURL = window.location.href.split('#')[0];
			const fullURLsubStrings = fullURL.split('/');
			if (fullURLsubStrings[fullURLsubStrings.length - 1] == 'gh') {
				setLocation('ghana');
			} else {
				setLocation('lagos');
			}

			//await this.setState({ url: fullURLsubStrings[2] })*/
			// return fullURLsubStrings[2];
		} catch (err) {}
	};

	useEffect(() => {
		parseURLandReturnRelevantURL();
	}, []);

	function topFunction() {
		window.scrollTo(0, 0);
		return;
	}

	const handleIcon = () => {
		const favicon = getFaviconEl(); // Accessing favicon element
		favicon.href = Chadwick;
	};

	const getLocationByIP = async () => {
		/* navigator.geolocation.getCurrentPosition(position => {
           
        }) */

		fetch('https://ipapi.co/json/')
			.then((response) => response.json())
			.then((data) => {
				if (data.city === 'Lagos') {
					setLocation('lagos');
				} else if (data.city === 'Oyo') {
					setLocation('ibadan');
				} else if (data.city === 'Ibadan') {
					setLocation('ibadan');
				} else if (data.city === 'Port Harcourt') {
					setLocation('portHarcourt');
				} else if (data.city === 'Kaduna') {
					setLocation('kaduna');
				} else if (data.city === 'Abeokuta') {
					setLocation('abeokuta');
				} else if (data.country_name === 'Ghana') {
					setLocation('ghana');
					setRedirect(true);
				} else if (data.city === 'Abuja') {
					setLocation('abuja');
				} else if (data.city === 'Abuja') {
					setLocation('abuja');
				} else {
					setLocation('lagos');
				}
			});
	};

	useEffect(() => {
		topFunction();
	}, []);

	return (
		<div
			style={{ overflow: 'hidden', margin: '0 auto', backgroundColor: 'white' }}
		>
			{redirect && <Redirect to="/gh" />}
			<Header
				changelocation={(e) => setLocation(e)}
				searchforstuff={async (val) => {
					setSearchTerm(val);
					SEARCH = val;
					await handleSearch(val);
				}}
				cancelSearch={searchTerm}
				location={location}
				showSearchModal={() => {
					if (SCREEN_WIDTH <= 600) {
						setShowSearchModal(!showSearchModal);
					}
				}}
			/>
			{/* {(searchTerm.length >= 3) && (searchResults.length <= 1 || providerSearchResults.length <= 1) && <div class='container p-4 mb-0 pt-0  animated fadeIn'>
                <div class=' p-4 m-4 text-center animated fadeIn'
                    style={{}}>
                    {searchMsg.startsWith('No results found') || searchMsg.startsWith('searching for') ? <h5 style={{ textAlign: 'center', color: '#00A86B' }}>{searchMsg}</h5> : <h5 style={{ textAlign: 'center', color: '#4F7645' }}>{searchMsg}</h5>}
                </div>
            </div>} */}

			{searchTerm.length >= 3 && (
				<div class="container mb-0 pt-0 text-center animated fadeIn d-flex justify-content-center align-items-center">
					<div class="mt-4 mb-0  animated fadeIn ">
						{searchMsg.startsWith('No results found') ||
						searchMsg.startsWith('searching for') ? (
							<h5 style={{ textAlign: 'center', color: '#00A86B' }}>
								{searchMsg}
							</h5>
						) : (
							<h5 style={{ color: '#4F7645' }}>
								{searchResults.length + providerSearchResults.length == 1
									? '1 result'
									: `${
											searchResults.length + providerSearchResults.length
									  } results`}{' '}
								found for <i>'{SEARCH}'</i> in {properLocationName(location)}...
							</h5>
						)}
					</div>
					<div
						onClick={() => {
							setSearchResults([]);
							setProviderSearchResults([]);
							setSearchTerm('');
						}}
						style={{
							borderRadius: 12,
							width: 24,
							height: 24,
							color: 'white',
							backgroundColor: '#D0D0D0',
							position: 'relative',
							marginTop: 14,
							marginLeft: 10,
						}}
					>
						<small style={{ position: 'absolute', top: 2, right: 8 }}>x</small>
					</div>
				</div>
			)}

			{searchTerm.length < 3 ? (
				<div>
					<div
						class="container mt-4"
						style={{ minHeight: 60, position: 'relative', marginTop: -60 }}
					>
						{/*  {Banner6(banner1image2, 'It’s time to celebrate the biggest sales and shopping event of the year!', ' ', 'SHOP NOW', '#000')} */}
						<div
							class="rainbow"
							style={{
								backgroundColor: '#000',
								alignItems: 'center',
								padding: 20,
								paddingTop: 50,
								borderRadius: 20,
								//borderWidth:5, border:'1px dashed #66ff99'
							}}
						>
							<div class="container d-md-flex flex-md-equal w-100  align-items-center pl-md-3">
								<div class="px-3  px-md-5  text-white overflow-hidden">
									<div class="pt-md-4 pt-4 animate__animated animate__backInLeft animate__slower ">
										<h2 class="display-5 text-light">
											{'2021 is over, 2022 is here! Welcome to a new season!'}
										</h2>

										<Link to={'/holidaysale'}>
											{' '}
											<div className="mt-4">
												<button type="button" class="btn btn-outline-light ">
													{' '}
													{'SHOP NOW'}
												</button>
											</div>
										</Link>
									</div>
								</div>
								<div
									class="  px-md-5 text-center overflow-hidden mt-4  align-items-center  zoomin"
									style={{
										backgroundImage: `url(${banner1image2})` || '',
										backgroundSize: 'contain',
										backgroundPosition: 'center center',
										backgroundRepeat: 'no-repeat',
										height: 200,
										marginBottom: 60,
										//textAlign: 'center',
									}}
								>
									<div
										class=" box-shadow mx-auto"
										style={{ width: '80vw', borderRadius: '21px 21px 0 0' }}
									></div>
								</div>
							</div>
						</div>
					</div>

					<div class="container-fluid p-4">
						<div className="p-4">
							<h6 style={{ color: '#707070', fontWeight: 'bold' }}>
								Top Picks from Shops
							</h6>
						</div>
						<Shops data={jobs} location={location || ''} />
					</div>
					<div class="container-fluid p-4">
						<div className="p-4">
							<h6 style={{ color: '#707070', fontWeight: 'bold' }}>
								Hire a Professional
							</h6>
						</div>
						<Professionals />
					</div>
					<div class="container-fluid  p-4">
						<div className="p-4">
							<h6 style={{ color: '#707070', fontWeight: 'bold' }}>
								Get a Job
							</h6>
						</div>
						<Jobs />
					</div>

					<div
						class="container mb-0 pt-0 text-center animated fadeIn"
						style={{ marginTop: 70 }}
					>
						{location !== '' &&
							categories.map((item, id) => {
								if (item == 'Appliances') {
									return;
								}
								if (item == 'Electronics' && location == 'ghana') {
									return;
								}
								if (item == 'Food' && location == 'ghana') {
									return;
								}
								return (
									<div key={id}>
										<div
											class="container font-weight-bold p-2 pt-3 row animated fadeIn px-4 ml-1"
											style={{
												color: '#00A85A',
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												backgroundColor: '#F5F5F5',
											}}
										>
											<h6 style={{ textAlign: 'center' }}>
												{`${item} Offers` || ''}
											</h6>
											<Link
												to={`/category/${item}`}
												target="_blank"
												style={{ textDecoration: 'none' }}
											>
												<div style={{ paddingBottom: 10 }}>
													{' '}
													<small
														style={{
															textDecoration: 'none',
															fontWeight: 'bold',
															color: 'black',
														}}
													>
														See More
													</small>
												</div>
											</Link>
										</div>
										<ProductShowcase shopCategory={item} location={location} />
									</div>
								);
							})}
					</div>

					<div
						class="container  p-4"
						style={{ minHeight: 60, position: 'relative', marginTop: 40 }}
					>
						{Banner1(
							banner1image,
							'Build Your Own E-Commerce Website!',
							'No Coding | No Setup Fees | Launch in a Day',
							'GET STARTED',
							'#00A85A'
						)}
					</div>
					<div
						class="container mt-n4 p-4"
						style={{ minHeight: 60, position: 'relative', marginTop: 0 }}
					>
						{Banner2(
							banner2image,
							'Enjoy More Benefits from the Wakanda Market App',
							marketing,
							'GET STARTED',
							'#C1DE76'
						)}
					</div>
					<FAQ />
					<Newsletter />
				</div>
			) : (
				<section style={{ minHeight: '100vh', paddingBottom: 50 }}>
					<div
						classname="container d-flex justify-content-between mb-4 align-items-center flex-row text-center"
						style={{ textAlign: 'center', alignSelf: 'center' }}
					>
						<div
							type="button"
							class="btn btn-light col-md-5"
							style={{
								alignSelf: 'center',
								borderWidth: selected === 'offers' ? 1 : 0,
								borderColor: '#14c170',
								width: '96%',
							}}
							onClick={() => {
								setSelected('offers');
							}}
						>
							<small
								style={{ color: selected === 'offers' ? '#14c170' : 'black' }}
							>
								<b>Offers({searchResults.length || 0})</b>
							</small>
						</div>
						<div
							type="button"
							class="btn btn-light col-md-5"
							style={{
								alignSelf: 'center',
								borderWidth: selected === 'providers' ? 1 : 0,
								borderColor: '#14c170',
								width: '96%',
							}}
							onClick={() => {
								setSelected('providers');
							}}
						>
							<small
								style={{
									color: selected === 'providers' ? '#14c170' : 'black',
								}}
							>
								<b>Providers({providerSearchResults.length || 0})</b>
							</small>
						</div>
					</div>

					<div class="d-flex justify-content-center mb-4">
						<div class="container mt-4 row p-0 m-0 d-flex flex-wrap">
							<div class="d-flex justify-content-around flex-wrap col-md-12">
								{providerSearchResults.length > 0 &&
									selected === 'providers' &&
									providerSearchResults.map((item, id) => {
										return (
											<div
												className="col-md-3"
												style={{
													marginTop: -15,
													marginBottom: 20,
													borderBottomLeftRadius: 20,
													borderBottomRightRadius: 20,
												}}
												key={id}
											>
												<Link
													to={{
														pathname: item.xID ? `/shop/${item.xID}` : '',
														xID: { item: item.xID },
													}}
													target="_blank"
													style={{ textDecoration: 'none' }}
												>
													<div
														class="shadow-lg"
														style={{
															paddingBottom: 20,
															borderBottomLeftRadius: 20,
															borderBottomRightRadius: 20,
															top: 0,
															overflow: 'hidden',
															borderRadius: 20,
														}}
													>
														{item.backgroundPictureURL ||
														item.pictureURL ||
														item.businessName ? (
															<div style={{ marginTop: -20 }}>
																{uniformImageHolder(
																	item.backgroundPictureURL == OLDbg
																		? NEWbg
																		: item.backgroundPictureURL,
																	'100%',
																	100
																)}
															</div>
														) : (
															<Skeleton width={'100%'} height={100} />
														)}
														<div
															style={{
																display: 'flex',
																padding: 20,
																paddingLeft: 30,
																paddingBottom: 0,
																paddingRight: 10,
																borderBottomLeftRadius: 20,
																borderBottomRightRadius: 20,
															}}
														>
															{item.pictureURL ? (
																<div
																	style={{ float: 'left', marginTop: -50 }}
																	className="zoomin"
																>
																	{' '}
																	{uniformImageHolder(
																		item.pictureURL,
																		80,
																		80,
																		'2px solid white'
																	)}
																</div>
															) : (
																<div
																	style={{
																		float: 'left',
																		marginTop: -50,
																		borderRadius: 20,
																		overflow: 'hidden',
																		border: '2px solid white',
																		zIndex: 999,
																	}}
																>
																	<Skeleton width={80} height={80} />
																</div>
															)}
															<div
																style={{
																	float: 'right',
																	display: 'flex',
																	flexDirection: 'column',
																	marginLeft: 10,
																	justifyContent: 'flex-start',
																	textOverflow: 'ellipsis',
																	overflow: 'hidden',
																}}
															>
																{item.businessName ? (
																	<div
																		style={{
																			overflowX: 'hidden',
																			marginTop: -10,
																			textOverflow: 'ellipsis',
																			maxWidth: 150,
																			color: 'grey',
																			paddingTop: 5,
																		}}
																	>
																		<small
																			class="text-truncate"
																			style={{
																				textAlign: 'left',
																				color: 'black',
																			}}
																		>
																			<b>{item.businessName}</b>
																		</small>
																	</div>
																) : (
																	<div
																		class="col-md-12"
																		style={{
																			marginTop: -10,
																			paddingTop: 5,
																			width: 150,
																			paddingLeft: 0,
																			marginBottom: 0,
																		}}
																	>
																		<Skeleton width={'100%'} height={10} />
																	</div>
																)}

																<div
																	style={{
																		overflowX: 'hidden',
																		textOverflow: 'ellipsis',
																		maxWidth: 150,
																		color: 'grey',
																	}}
																>
																	{item.description ? (
																		<small
																			className="text-truncate"
																			style={{ color: 'grey' }}
																		>
																			{item.description}
																		</small>
																	) : (
																		<Spinner
																			animation="grow"
																			variant="success"
																			role="status"
																			size="sm"
																		>
																			<span className="sr-only">
																				Loading...
																			</span>
																		</Spinner>
																	)}
																</div>
															</div>
														</div>
													</div>
												</Link>
											</div>
										);
									})}
							</div>

							{selected === 'offers' &&
								searchResults.map((item, id) => {
									return (
										<div
											class="col-md-3 col-sm-6   py-2 animated fadeIn"
											style={{ width: '50%' }}
											key={id}
										>
											<div
												class="shadow-sm p-0 pt-0 zoomin"
												style={{
													border: '1px solid rgba(100,100,100,0.1)',
													borderRadius: 10,
												}}
											>
												{item.url ? (
													<div
														class="col-md-12 p-0 pt-0"
														style={{
															marginTop: -10,
															overflowX: 'hidden',
															textOverflow: 'ellipsis',
														}}
													>
														{uniformImageHolder(
															item.url,
															'100%',
															'12rem',
															'',
															10
														)}
													</div>
												) : (
													<Skeleton width={'100%'} height={'12rem'} />
												)}

												<div
													class="col-md-12 "
													style={{
														padding: 20,
														paddingBottom: 20,
														paddingTop: 0,
														width: '100%',
													}}
												>
													<div
														style={{
															height: 50,
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
													>
														<div
															style={{
																width: '100%',
																marginTop: 10,
																overflowX: 'hidden',
																textOverflow: 'ellipsis',
															}}
														>
															<small
																class="text-truncate"
																style={{ fontSize: 14 }}
															>
																{item.t || '...'}
															</small>
														</div>
														<div
															style={{
																width: '100%',
																marginTop: -5,
																overflowX: 'hidden',
																textOverflow: 'ellipsis',
															}}
														>
															<small
																class="text-truncate"
																style={{ fontSize: 13 }}
															>
																<b>
																	{displayprice(item.p, item.pt, item.pc) ||
																		'...'}
																</b>
															</small>
														</div>
													</div>

													<hr />
													<div
														style={{
															width: '100%',
															marginTop: -5,
															overflow: 'hidden',
														}}
													>
														<Link
															to={`/${item.author}/${item.xID}`}
															target="_blank"
															style={{ textDecoration: 'none' }}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'row',
																	width: '100%',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<button
																	type="button"
																	class="btn btn-lg btn-inline btn-success px-2 row mt-2 d-flex justify-content-center align-items-center"
																	style={{ width: '100%' }}
																>
																	<span className="mx-2">
																		<small
																			style={{ fontSize: 13 }}
																			class="text-truncate"
																		>
																			View Product<span></span>
																		</small>
																	</span>
																</button>
															</div>
														</Link>
													</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</section>
			)}
			<Footer />

			<Modal show={show} onHide={handleClose}>
				{/* <Modal.Header closeButton> 
                </Modal.Header> */}
				<Modal.Body>
					<div class="mx-auto my-auto col-md-12">
						<div>
							<div
								className="col-md-12 d-flex justify-content-center align-items"
								style={{ flexDirection: 'column' }}
							>
								<div
									className="col-md-12 d-flex justify-content-center p-4"
									style={{ width: '100%', flexDirection: 'column' }}
								>
									<h4
										style={{
											textAlign: 'center',
											marginTop: -5,
											color: 'green',
											fontWeight: 'bold',
										}}
									>
										Get free health consultation for 3 months when you download
										Wakanda Market App
									</h4>
								</div>
								<img src={DD} width="100%" alt="wakanda-logo" />

								<div style={{ width: '100%' }} class="col-md-12 my-3">
									<div>
										<BrowserView>
											<div
												className="col-md-12 "
												style={{
													flexDirection: 'row',
													display: 'flex',
													justifyContent: 'space-evenly',
												}}
											>
												<a
													href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda"
													target="_blank"
													className="col-md-6 col-sm-12 col-xs-12"
													style={{ textDecoration: 'none', width: '100%' }}
												>
													<div
														className="col-md-12"
														style={{
															backgroundImage: `url(${google})`,
															backgroundPosition: 'center center',
															backgroundRepeat: 'no-repeat',
															//backgroundAttachment: 'fixed',
															backgroundSize: '100%',
															width: '100%',
															height: 50,
															borderRadius: 5,
														}}
													></div>
												</a>
												<a
													href="https://apps.apple.com/ng/app/wakanda-market/id1451309384"
													target="_blank"
													className="col-md-6 col-sm-12 col-xs-12"
													style={{ textDecoration: 'none', width: '100%' }}
												>
													<div
														className="col-md-12"
														style={{
															backgroundImage: `url(${apple})`,
															backgroundPosition: 'center center',
															backgroundRepeat: 'no-repeat',
															//backgroundAttachment: 'fixed',
															backgroundSize: '100%',
															width: '100%',
															height: 50,
															borderRadius: 5,
														}}
													></div>
												</a>
											</div>
										</BrowserView>

										<MobileView>
											<div
												className="col-md-12"
												style={{
													flexDirection: 'row',
													display: 'flex',
													justifyContent: 'space-evenly',
													width: '100%',
												}}
											>
												{isAndroid && (
													<div
														className="col-md-6"
														style={{
															backgroundImage: `url(${google})`,
															backgroundPosition: 'center center',
															backgroundRepeat: 'no-repeat',
															//backgroundAttachment: 'fixed',
															backgroundSize: '84%',
															width: '50%',
															height: 50,
															borderRadius: 5,
														}}
													></div>
												)}
												{isIOS && (
													<div
														className="col-md-6"
														style={{
															backgroundImage: `url(${apple})`,
															backgroundPosition: 'center center',
															backgroundRepeat: 'no-repeat',
															//backgroundAttachment: 'fixed',
															backgroundSize: '84%',
															width: '50%',
															height: 50,
															borderRadius: 5,
														}}
													></div>
												)}
											</div>
										</MobileView>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<div
					onClick={handleClose}
					style={{
						zIndex: 20,
						padding: 10,
						backgroundColor: 'red',
						top: -10,
						right: -10,
						width: 50,
						height: 50,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: 25,
						position: 'absolute',
					}}
				>
					<small style={{ color: 'white', fontWeight: 'bold' }}>X</small>
				</div>
			</Modal>

			<Modal
				show={showSearchModal}
				onHide={() => {
					setShowSearchModal(false);
				}}
			>
				<Modal.Body classname="d-flex justify-content-center align-items-center">
					<div class=" mx-auto my-auto col-md-12">
						<div className="gradient-buttons p-1">
							<div
								onClick={() => {
									setShowSearchModal(false);
								}}
								type="button"
								className="btn btn-light btn-block my-2"
								style={{ alignSelf: 'center' }}
							>
								<small style={{ color: 'black', fontWeight: 'bold' }}>
									{' '}
									close{' '}
								</small>
							</div>
							<div
								className="col-md-12 d-flex"
								style={{
									backgroundColor: '#32cd32',
									width: '100%',
									borderRadius: 3,
									height: 50,
								}}
							>
								<img src={searchIcon} alt="search" width="18" />
								&nbsp;&nbsp;
								<form class="form-inline block">
									<div class="form-group mb-2 d-block">
										<input
											type="text"
											class="form-control-plaintext pb-0"
											id="search"
											value={searchTerm}
											placeholder="search"
											onChange={async (e) => {
												SEARCH = e.target.value;
												setSearchTerm(e.target.value);
												await handleSearch(e.target.value);
											}}
										/>
									</div>
								</form>
							</div>

							{searchTerm.length >= 3 && (
								<div class="container mb-0 pt-0 text-center animated fadeIn d-flex justify-content-center align-items-center">
									<div class="mt-4 mb-0  animated fadeIn ">
										{searchMsg.startsWith('No results found') ||
										searchMsg.startsWith('searching for') ? (
											<h5 style={{ textAlign: 'center', color: '#00A86B' }}>
												{searchMsg}
											</h5>
										) : (
											<h5 style={{ color: '#4F7645' }}>
												{searchResults.length + providerSearchResults.length ==
												1
													? '1 result'
													: `${
															searchResults.length +
															providerSearchResults.length
													  } results`}{' '}
												found for <i>'{SEARCH}'</i> in{' '}
												{properLocationName(location)}...
											</h5>
										)}
									</div>
									<div
										onClick={() => {
											setSearchResults([]);
											setProviderSearchResults([]);
											setSearchTerm('');
										}}
										style={{
											borderRadius: 12,
											width: 24,
											height: 24,
											color: 'white',
											backgroundColor: '#D0D0D0',
											position: 'relative',
											marginTop: 14,
											marginLeft: 10,
										}}
									>
										<small style={{ position: 'absolute', top: 2, right: 8 }}>
											x
										</small>
									</div>
								</div>
							)}
						</div>
						{searchTerm.length >= 3 && (
							<div
								classname="container d-flex justify-content-between mb-4 align-items-center flex-row text-center"
								style={{ textAlign: 'center', alignSelf: 'center' }}
							>
								<div
									type="button"
									class="btn btn-light col-md-5"
									style={{
										alignSelf: 'center',
										borderWidth: selected === 'offers' ? 1 : 0,
										borderColor: '#14c170',
										width: '96%',
									}}
									onClick={() => {
										setSelected('offers');
									}}
								>
									<small
										style={{
											color: selected === 'offers' ? '#14c170' : 'black',
										}}
									>
										<b>Offers({searchResults.length || 0})</b>
									</small>
								</div>
								<div
									type="button"
									class="btn btn-light col-md-5"
									style={{
										alignSelf: 'center',
										borderWidth: selected === 'providers' ? 1 : 0,
										borderColor: '#14c170',
										width: '96%',
									}}
									onClick={() => {
										setSelected('providers');
									}}
								>
									<small
										style={{
											color: selected === 'providers' ? '#14c170' : 'black',
										}}
									>
										<b>Providers({providerSearchResults.length || 0})</b>
									</small>
								</div>
							</div>
						)}
						<div class="d-flex justify-content-center mb-4">
							<div class="container mt-4 row p-0 m-0 d-flex flex-wrap">
								<div class="d-flex justify-content-around flex-wrap col-md-12">
									{providerSearchResults.length > 0 &&
										selected === 'providers' &&
										providerSearchResults.map((item, id) => {
											return (
												<div
													className="col-md-6"
													style={{
														marginTop: -15,
														marginBottom: 20,
														borderBottomLeftRadius: 20,
														borderBottomRightRadius: 20,
													}}
													key={id}
												>
													<Link
														to={{
															pathname: item.xID ? `/shop/${item.xID}` : '',
															xID: { item: item.xID },
														}}
														target="_blank"
														style={{ textDecoration: 'none' }}
													>
														<div
															class="shadow-lg"
															style={{
																paddingBottom: 20,
																borderBottomLeftRadius: 20,
																borderBottomRightRadius: 20,
																top: 0,
																overflow: 'hidden',
																borderRadius: 20,
															}}
														>
															{item.backgroundPictureURL ||
															item.pictureURL ||
															item.businessName ? (
																<div style={{ marginTop: -20 }}>
																	{uniformImageHolder(
																		item.backgroundPictureURL == OLDbg
																			? NEWbg
																			: item.backgroundPictureURL,
																		'100%',
																		100
																	)}
																</div>
															) : (
																<Skeleton width={'100%'} height={100} />
															)}
															<div
																style={{
																	display: 'flex',
																	padding: 20,
																	paddingLeft: 30,
																	paddingBottom: 0,
																	paddingRight: 10,
																	borderBottomLeftRadius: 20,
																	borderBottomRightRadius: 20,
																}}
															>
																{item.pictureURL ? (
																	<div
																		style={{ float: 'left', marginTop: -50 }}
																		className="zoomin"
																	>
																		{' '}
																		{uniformImageHolder(
																			item.pictureURL,
																			80,
																			80,
																			'2px solid white'
																		)}
																	</div>
																) : (
																	<div
																		style={{
																			float: 'left',
																			marginTop: -50,
																			borderRadius: 20,
																			overflow: 'hidden',
																			border: '2px solid white',
																			zIndex: 999,
																		}}
																	>
																		<Skeleton width={80} height={80} />
																	</div>
																)}
																<div
																	style={{
																		float: 'right',
																		display: 'flex',
																		flexDirection: 'column',
																		marginLeft: 10,
																		justifyContent: 'flex-start',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																	}}
																>
																	{item.businessName ? (
																		<div
																			style={{
																				overflowX: 'hidden',
																				marginTop: -10,
																				textOverflow: 'ellipsis',
																				maxWidth: 150,
																				color: 'grey',
																				paddingTop: 5,
																			}}
																		>
																			<small
																				class="text-truncate"
																				style={{
																					textAlign: 'left',
																					color: 'black',
																				}}
																			>
																				<b>{item.businessName}</b>
																			</small>
																		</div>
																	) : (
																		<div
																			class="col-md-12"
																			style={{
																				marginTop: -10,
																				paddingTop: 5,
																				width: 150,
																				paddingLeft: 0,
																				marginBottom: 0,
																			}}
																		>
																			<Skeleton width={'100%'} height={10} />
																		</div>
																	)}

																	<div
																		style={{
																			overflowX: 'hidden',
																			textOverflow: 'ellipsis',
																			maxWidth: 150,
																			color: 'grey',
																		}}
																	>
																		{item.description ? (
																			<small
																				className="text-truncate"
																				style={{ color: 'grey' }}
																			>
																				{item.description}
																			</small>
																		) : (
																			<Spinner
																				animation="grow"
																				variant="success"
																				role="status"
																				size="sm"
																			>
																				<span className="sr-only">
																					Loading...
																				</span>
																			</Spinner>
																		)}
																	</div>
																</div>
															</div>
														</div>
													</Link>
												</div>
											);
										})}
								</div>

								{selected === 'offers' &&
									searchResults.map((item, id) => {
										return (
											<div
												class="col-md-6 col-sm-6   py-2 animated fadeIn"
												style={{ width: '50%' }}
												key={id}
											>
												<div
													class="shadow-sm p-0 pt-0 zoomin"
													style={{
														border: '1px solid rgba(100,100,100,0.1)',
														borderRadius: 10,
													}}
												>
													{item.url ? (
														<div
															class="col-md-12 p-0 pt-0"
															style={{
																marginTop: -10,
																overflowX: 'hidden',
																textOverflow: 'ellipsis',
															}}
														>
															{uniformImageHolder(
																item.url,
																'100%',
																'12rem',
																'',
																10
															)}
														</div>
													) : (
														<Skeleton width={'100%'} height={'12rem'} />
													)}

													<div
														class="col-md-12 "
														style={{
															padding: 10,
															paddingBottom: 20,
															paddingTop: 0,
															width: '100%',
														}}
													>
														<div
															style={{
																height: 50,
																overflow: 'hidden',
																textOverflow: 'ellipsis',
															}}
														>
															<div
																style={{
																	width: '100%',
																	marginTop: 10,
																	overflowX: 'hidden',
																	textOverflow: 'ellipsis',
																}}
															>
																<small
																	class="text-truncate"
																	style={{ fontSize: 14 }}
																>
																	{item.t || '...'}
																</small>
															</div>
															<div
																style={{
																	width: '100%',
																	marginTop: -5,
																	overflowX: 'hidden',
																	textOverflow: 'ellipsis',
																}}
															>
																<small
																	class="text-truncate"
																	style={{ fontSize: 13 }}
																>
																	<b>
																		{displayprice(item.p, item.pt, item.pc) ||
																			'...'}
																	</b>
																</small>
															</div>
														</div>

														<hr />
														<div
															style={{
																width: '100%',
																marginTop: -5,
																overflow: 'hidden',
															}}
														>
															<Link
																to={`/${item.author}/${item.xID}`}
																target="_blank"
																style={{ textDecoration: 'none' }}
															>
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																		width: '100%',
																		alignItems: 'center',
																		justifyContent: 'center',
																	}}
																>
																	<button
																		type="button"
																		class="btn btn-lg btn-inline btn-success px-2 row mt-2 d-flex justify-content-center align-items-center"
																		style={{ width: '100%' }}
																	>
																		<span className="mx-2">
																			<small
																				style={{ fontSize: 13 }}
																				class="text-truncate"
																			>
																				View Product<span></span>
																			</small>
																		</span>
																	</button>
																</div>
															</Link>
														</div>
													</div>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AllComponents;
