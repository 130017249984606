import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from '../utilityComponents'
import { Link, Redirect } from 'react-router-dom'
import Skeleton from '@yisheng90/react-loading';
import SignInModule from './SignInModule'
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap'
import Product from '../product1'
import ProductHeader from '../Header/ProductHeader'
import '../styles/provider.css'
import Footer from '../Footer/footer'
//import Product from  './product1'
import heart from '../../assets/heart4.svg'
import call from '../../assets/call-icon.png'
import '../styles/category.css'
import '../Header/ProductHeader.css';
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
import searchIcon from '../../assets/searchIcon.svg'
import map from '../../assets/map.svg'
import Accordion from 'react-bootstrap/Accordion'

import banner1image from '../images/Group-674.png'
import { BannerCat, Banner5 } from '../utilityComponents'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";





const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const marketing = [
    '- Sell your products and services',
    '- Use the Wakanda Wallet and Escrow Service',
    '- Get free business/e-commerce Website and a lot more…'
]

let URL = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"





let imageDat = {
    'Fashion': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFashion%2Fmens-clothes.jpg?alt=media&token=b2e86dc1-8887-4e77-b112-2fe5aa3980ad',
    'Food': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FFreshFood%2Fcarrots-crate-food-1508666.jpg?alt=media&token=ef7ad2a8-a72a-4d34-a41f-5d1ea6786622',
    'Beauty': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FBeauty%2FMakeup%2Fassorted-blur-close-up-1115128.jpg?alt=media&token=43abcfff-b9a9-4769-abd4-2719c5ae956b',
    'Electronics': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FElectronics%2FPhones.jpg?alt=media&token=a4b78d9b-458c-4e92-b766-f34294b275e4',
    'Cars': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fnew-cars.jpg?alt=media&token=0d2bceee-823e-4402-88ad-b693776f77f0',
    'Health': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHealth%2FPharmacies%2FPharmacy.jpg?alt=media&token=db11dfbf-2d57-4e71-8ed8-be3d1dcc0161',
    'Handyman': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FPlumbing.jpg?alt=media&token=9f763caf-1a91-439b-b48f-716c37372a5f',
}




const BlogHome = (props) => {

    const [shopCategory, setShopCategory] = useState("")
    const [shopProducts, setProducts] = useState(productData)
    const [redirect, setRedirect] = useState(false)
    const [url, setURL] = useState('')
    const [blogPictureURL, setBlogPictureURL] = useState(url)
    const [blogSubtitle, setBlogSubtitle] = useState('')
    const [blogTitle, setBlogTitle] = useState('')
    const [blogData, setBlogData] = useState({})
    const [blogDescription, setBlogDescription] = useState('')

    function convertTime(time) {
        if (!time) return
        //console.log("time received is: ", time)
        const MONTHS = {
            "1": "Jan",
            "2": "Feb",
            "3": "Mar",
            "4": "Apr",
            "5": "May",
            "6": "Jun",
            "7": "Jul",
            "8": "Aug",
            "9": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec"
        }
        let d = ''
        //console.log("time data passed is:", time)
        //let d = new Date(time._seconds * 1000)
        //let d = new Date(time)
        if (time.hasOwnProperty("seconds")) {
            d = new Date(time.seconds * 1000)
        } else if (time.hasOwnProperty("_seconds")) {
            d = new Date(time._seconds * 1000)
        } else { return }

        //console.log("converted time is:", d)
        let dYear = d.getFullYear()
        let dMonth = d.getMonth() + 1
        let dDate = d.getDate()
        let dHours = d.getHours()
        let dMinutes = d.getMinutes()
        let dSeconds = d.getSeconds()
        let currentTime = new Date()
        let minutes = currentTime.getMinutes()
        let month = currentTime.getMonth() + 1
        let date = currentTime.getDate()
        let hours = currentTime.getHours()
        let year = currentTime.getFullYear()
        let seconds = currentTime.getSeconds()
        //console.log("dYear is: ", dYear, "dMonth  is: ", dMonth, " dHours  is: ",dHours, " dDate  is: ",dDate, " dminutes ", dMinutes, "dseconds: ", dSeconds)
        //console.log("Year is: ", year, "Month  is: ", month, " dHours  is: ",dHours, " dDate  is: ",dDate, " minutes ", minutes, "seconds: ", seconds)

        let fullTime = ''

        if ((month == dMonth) && (year == dYear) && (date !== dDate)) {
            let diff = date - dDate
            fullTime = diff > 1 ? `${diff}d ago` : `${diff}d ago`
        }
        else if ((month == dMonth) && (year == dYear) && (date == dDate)) {
            let diff = hours - dHours

            if (diff >= 1) {
                fullTime = diff > 1 ? `${diff}h ago` : `${diff}h ago`
            }
            else if ((minutes - dMinutes) >= 1) {
                let diffm = minutes - dMinutes
                fullTime = diffm > 1 ? `${diffm}m ago` : `${diffm}m ago`
            } else {
                let diffs = seconds - dSeconds
                fullTime = diffs > 1 ? `${diffs}s ago` : `${diffs}s ago`
            }
        }
        else if ((month !== dMonth) && (year == dYear)) {
            let diff = month - dMonth
            fullTime = diff > 1 ? `${diff}mths ago` : `${diff}mth ago`
        }

        else {
            fullTime = `${MONTHS[dMonth]} ${dDate}, ${dYear}`
        }

        //console.log(" fulltime is: ", fullTime)
        return fullTime
    }

    const fetchBlogData = async () => {
        console.log('launching fetchProductsbyCategory, location is')

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'getAllBlog',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    // profileID:'00babaOjaGeneralBlog', 
                    location: 'lagos'
                })
            });
            const r2 = await r.json();
            console.log('r2 offers fetched in fetchBlogData are: ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                let data = r2.response
                setProducts(data)
                //await setPhoneNumber(data.phoneNumber)
            } else {
                console.log('something went wrong... ', r2.msg)
                alert('something went wrong, please try again')
            }

        } catch (err) {
            console.log("Error from firebase using fetchProductsbyCategory is: ", err);

        }

    }



    useEffect(() => {
        fetchBlogData()
    }, [])



    return (
        <div >
            {redirect && <Redirect to={url} />} 
            <div className="nw1-product-smaller-header" style={{ position: 'relative' }}>
                <div className="row"  >
                    <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 190,
                            height: 45,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            paddingBottom: 20
                        }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                        </div> 
                    </Link>
                    <div className="float-right d-none"><p className='sell d-none d-md-block' style={{ fontWeight: 'bold' }}>
                        SELL ON WAKANDA</p>
                    </div>
                    <div style={{ position: 'absolute', top: 20, right: 20 }}><SignInModule /></div>
                </div>
            </div>


            <div style={{ marginTop: 100 }}>

            </div>
            <section class='container mt-4' style={{ minHeight: '100vh' }}  >
                <div class='d-flex justify-content-center mt-4 mb-4'>
                    <div class='container mt-4 row'  >
                        {shopProducts.map((item, id) => {
                            if (shopProducts.length == 0) {
                                return (
                                    <div class='col-md-12 ' style={{ height: 50, overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                        <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                            <small class='text-truncate' style={{ fontSize: 14, color: 'black' }} >No Results...</small>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div class='col-md-4 col-sm-12 py-2' key={id}>
                                    <Link target="_blank" to={`/blog/${item.xID}`} style={{ textDecoration: 'none' }}>
                                        <div class='shadow-sm pt-0 ml-1 mr-1 zoomin' style={{ border: '1px solid rgba(100,100,100,0.1)', borderRadius: 10 }}>
                                            {item.blogTitle ? <div class='col-md-12 p-2 pt-0 d-flex justify-content-center align-items-center'
                                                style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                {uniformImageHolder(item.blogPictureURL || bgCover, '100%', '13rem', '', 10)}
                                            </div>
                                                :
                                                <div class='col-md-12 p-2 pt-0 d-flex justify-content-center align-items-center'><Skeleton width={"100%"} height={"13rem"} /></div>}

                                            <div class='col-md-12 pl-2' style={{ padding: 20, paddingBottom: 20, paddingTop: 5, width: "100%", height: '12rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <div class='p-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap' }}>
                                                    <div style={{ width: "100%", overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                        <p style={{ fontSize: 25, fontFamily: 'Abril Fatface', textOverflow: 'ellipsis', }} ><b>{item.blogTitle || "..."}</b></p>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: -15 }}>
                                                            {/* <div ><small style={{textDecoration:'none', color:'black'}}>...</small></div> */}
                                                            <div><small style={{ textDecoration: 'none', color: 'rgb(100,100,100)' }}>Created <b>{convertTime(item.timeCreated)}</b></small></div>
                                                        </div>
                                                    </div>
                                                    <div 
                                                    /* style={{
                                                        width: "100%", marginTop: 0, overflowX: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap', paddingBottom: 10,
                                                        border: '1px solid grey', borderWidth: 0, borderTopWidth: 1, borderColor: '#555', paddingTop: 10,
                                                    }} */
                                                    className='p-2 col overflow-hidden text-truncate text-wrap'
                                                        style={{ width: "100%", marginTop: 0,  height: 150 }}
                                                    >
                                                        <small style={{ color: 'grey', fontFamily: 'Libre Baskerville', overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap' }}> {item.blogDescription || "..."} </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <div class='container mt-n4  p-4' style={{ minHeight: 60, position: 'relative', marginTop: 0 }}>
                {BannerCat(imageDat[shopCategory], 'Enjoy More Benefits from the Wakanda Market App', marketing, 'GET STARTED', '#C1DE76')}
            </div>
            <Footer />
        </div >
    )
}

export default BlogHome