import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from './utilityComponents'
import { Link, Redirect } from 'react-router-dom'
import Skeleton from '@yisheng90/react-loading';
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap'
import Product from './product1'
import ProductHeader from './Header/ProductHeader'
import './styles/provider.css'
import Footer from './Footer/footer'
//import Product from  './product1'
import heart from '../assets/heart4.svg'
import './styles/category.css'
import './Header/ProductHeader.css';
import rectangle from '../assets/Rectangle.png'
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
import searchIcon from '../assets/searchIcon.svg'
import map from '../assets/map.svg'
import electronics from '../assets/electronics.jpg'
import fashion from '../assets/fashion.jpg'
import food from '../assets/food.jpg'
import beauty from '../assets/beauty.jpg'
import dropdown from '../assets/dropd.svg'
import electronicsIcon from '../assets/Electronics.svg'
import fashionIcon from '../assets/fashion.svg'
import foodIcon from '../assets/Food.svg'
import healthIcon from '../assets/Health.svg'



const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"/* 
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" */

let validLocations = ["lagos", "ibadan", "portHarcourt", "kaduna", "abeokuta", "ghana", "abuja"]
let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]
let CHOSEN_LOCATION = ''
const professionals = [
    { name: 'Public Speakers', color: '#4160E0' }, { name: 'Marketing', color: 'Green' },
    { name: 'Digital Marketing', color: '#C43699' }, { name: 'Website Development', color: '#4DC5E2' },
    { name: 'Graphics Design', color: '#4160E0' }, 
    { name: 'Translation', color: '#C43699' }, { name: 'Video', color: '#4DC5E2' },
    { name: 'Animation', color: '#4160E0' }, { name: 'Photography', color: 'Green' },
    { name: 'Music', color: '#C43699' }, { name: 'Marketing Materials, Printing, Branding', color: '#4DC5E2' },
    { name: 'Software Development', color: '#4160E0' }, { name: 'Legal', color: 'Green' },
    { name: 'App Development', color: '#C43699' }, { name: 'Computer & IT Services', color: '#4DC5E2' },
    { name: 'General Business', color: '#C43699' }, { name: 'Facility Management', color: '#4DC5E2' },
    { name: 'Teaching - Primary', color: '#4160E0' }, { name: 'Teaching - Secondary', color: 'Green' },
    { name: 'Teaching - Tertiary', color: '#C43699' }, { name: 'Training & Education', color: '#4DC5E2' },
    { name: 'Training - Soft Skills', color: '#C43699' }, { name: 'Coaching', color: '#4DC5E2' },
    { name: 'Visa & Concierge', color: '#4160E0' }, { name: 'Events & Catering', color: 'Green' },
    { name: 'Wedding Planning', color: '#C43699' }, { name: 'Trade Services', color: '#4DC5E2' },
    { name: 'Financial & Investment', color: '#4160E0' }, { name: 'Insurances', color: 'Green' },
    { name: 'Loans', color: '#C43699' }, { name: 'Business Consulting', color: '#4DC5E2' },
    { name: 'IT Consulting', color: '#4160E0' }, { name: 'Other Services', color: 'Green' },
    { name: 'PR', color: '#C43699' }
  ]

const CategoryPage = (props) => {

    const [shopNamefromURL, setShopNameFromURL] = useState("")
    const [initialURL, setInitialURL] = useState("")
    const [shopCategory, setShopCategory] = useState("")
    const [shopProducts, setProducts] = useState(productData)
    const [location, setLocation] = useState('lagos')
    const [search, setSearch] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [url, setURL] = useState('')

    const handleChange = async (event) => {
        console.log('value in handleChange ', event.target.value, 'location is also ', location)

        await setLocation(event.target.value)

        //setLocation(event.target.value)
        //await setLocation(event.target.value)
        await setProducts(productData)
        //await props.searchforstuff(SEARCH)
    };

    const handleLocationChange = async (value) => {
        console.log('value in handleChange ', value, 'location is also ', location)
        let a = value.split('#/')[1]
        console.log('value split is ', a)
        CHOSEN_LOCATION = a
        //let b = Array.isArray(a) ? a.split('#/')[1] : ""
        if ((a !== "") || (a !== undefined)) {
            console.log('value is now...', a, ' CHOSEN_LOCATION ', CHOSEN_LOCATION)
            await setLocation(a)
        }
        //setLocation(event.target.value)
        //await setLocation(event.target.value)
        await setProducts(productData)
        //await props.searchforstuff(SEARCH)
    };

    const handleCategoryChange = async (value) => {
        console.log('value in handleCategoryChange ', value, 'category is ', shopCategory)
        let a = value.split('#/')[1]
        if ((a !== "") || (a !== undefined)) {
            console.log('value is now...', a,)
            if (validLocations.includes(a)) {
                await setShopCategory(a)
            }

        }
        await setProducts(productData)
        //await props.searchforstuff(SEARCH)
    };

    const parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            //await this.setState({ initialURL: , shopNamefromURL: })
            await setInitialURL(fullURLsubStrings[2])
            let a = window.location.href.includes('#/') ? window.location.href.split('#/')[1] : ""
            /* let b = Array.isArray(a) ? a.split('/')[1] : "" */
            if (a !== "" || a !== undefined) {
                await setLocation(a)
            }
            let b = fullURLsubStrings[4].split('%20')
            console.log(' the split item is ', b, ' joined again we get ', b.join(' '))
            if (b.length > 1) {
                let c = b.join(' ')
                await setShopCategory(c)
            } else {
                await setShopCategory(fullURLsubStrings[4])
            }


            console.log('the category is: ', shopCategory, 'new location is ', a);
            //await this.setState({ url: fullURLsubStrings[2] })*/
            // return fullURLsubStrings[2]; 
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }

    const searchNow = () => {
        if (search == true) {
            fetchProductsbyCategory()
        }
    }
    const fetchProductsbyCategory = async () => {
        console.log('launching fetchProductsbyCategory, location is', location, ' shopcategory is ', shopCategory)
        if (shopCategory == "" || shopCategory == undefined) { return }
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'providers',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    category1: "Professionals",
                    category2: shopCategory,
                    amount: 50,
                    location: location || 'lagos'
                })
            });
            const r2 = await r.json();
            console.log('r2 provider fetched in fetchProductsbyCategory is ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                let data = r2.results
                //setShopData(r2.results)
                await setProducts(data)
            } else {
                console.log('no custom URL detected... ', r2.msg) 
            }

        } catch (err) {
            console.log("Error from firebase using fetchProductsbyCategory is: ", err);

        }

    }
    useEffect(() => {

        // parseURLandReturnRelevantURL()
        console.log('shopcategory is ', shopCategory, ' location is also', location)
        fetchProductsbyCategory()

    }, [shopCategory, location])


    useEffect(() => {

        parseURLandReturnRelevantURL()
        //fetchProductsbyCategory()

    }, [])



    return (
        <div>
            {redirect && <Redirect to={url} />}
            <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                <div className="nw1-product-smaller-header" >
                    <div className="row"  >
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 190,
                            height: 45,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            paddingBottom: 20
                        }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                        </div>

                        <div className="float-right d-none"><p className='sell d-none d-md-block' style={{ fontWeight: 'bold' }}>
                            SELL ON WAKANDA</p>
                        </div>
                    </div>
                </div>
            </Link>


            <section style={{ marginTop: 100, minHeight: '100vh' }}  >
                <div id='filter' class='d-flex justify-content-center mt-4 mb-4 container align-items-center'  >
                    <div class='container row p-2' style={{ backgroundColor: '#F0F0F0' }} 
                    >
                        <div style={{ width: '100%', padding: 20 }} class='col-md-2 d-flex justify-content-center align-items-center'>
                            <h6>Filter </h6>
                        </div>
                        <div style={{ width: '100%', padding: 20 }} class='col-md-2'>
                            {/* <h6><b>Location:</b></h6> */}
                            <Dropdown as={ButtonGroup} title="Location" onSelect={async (e) => {

                                await handleLocationChange(e)
                            }}>
                                <Button variant="light">{location}</Button>

                                <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                <Dropdown.Menu className="wk-dropdown">
                                    {locations.map((item, id) => {
                                        return (
                                            <Dropdown.Item href={`#/${item.value}`} key={id}>{item.name}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div style={{ width: '100%', padding: 20 }} class='col-md-2'>
                            {/* <h6><b>Category:</b></h6> */}
                            <Dropdown as={ButtonGroup} onSelect={async (e) => {
                                console.log('e passed is ', e)
                                //let a = e.split('#/')[1]
                                await setURL(e)
                                await setRedirect(true)
                                await handleCategoryChange(e)
                            }}>
                                <Button variant="light">{shopCategory}</Button>

                                <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                <Dropdown.Menu>
                                    {professionals.map((item, id) => {
                                        return (
                                            <Dropdown.Item href={`${item.name}`} key={id}>{item.name}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> 
                        <div style={{ width: '100%', padding: 20 }} class='col-md-6'>
                            <InputGroup >
                                <FormControl
                                    placeholder="what are you looking for?"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    style={{}}
                                    class='outline text-center'
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary">Search</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </div>

                </div>
                <div class='d-flex justify-content-center mt-4 mb-4'>

                    <div class='container mt-4 row'  >
                        {shopProducts.length == 0 && <div class='col-md-6 col-sm-12 py-2'>
                            <h5>Sorry! No results to show...!</h5>
                        </div>}
                        {shopProducts.length > 0 && shopProducts.map((item, id) => {
                            return (
                                <div class='col-md-6 col-sm-12 py-2' key={id} >
                                    <div class='shadow-sm p-0 pt-0  zoomin row col-md-12' style={{ border: '1px solid rgba(100,100,100,0.1)', borderRadius: 10 }}>
                                        {item.pictureURL || item.backgroundPictureURL ? <div class='col-md-4 p-2 pt-0 d-flex justify-content-center align-items-center'
                                            style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                            {uniformImageHolder(item.pictureURL || item.backgroundPictureURL || url, '100%', '13rem', '', 10)}
                                        </div>
                                            :
                                            <div class='col-md-4 p-2 pt-0 d-flex justify-content-center align-items-center'>
                                                <Skeleton width={"100%"} height={"12rem"} />
                                            </div>
                                        }

                                        <div class='col-md-8 pl-2' style={{ padding: 20, paddingBottom: 20, paddingTop: 0, width: "100%", }}>
                                            <div style={{ height: '6.5rem', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap' }}>
                                                    <small style={{ fontSize: 14, }} ><b>{item.name || "..."}</b></small>
                                                </div>
                                                <div style={{ width: "100%", marginTop: -5, overflowX: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap' }}>
                                                    <small style={{ fontSize: 12, }} > {item.description || "..."} </small>
                                                </div>
                                            </div>

                                            <hr />
                                            <div style={{ width: "100%", marginTop: -5, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <Link to={`/shop/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                    <small class='text-truncate col-md-12  pl-0' style={{ fontSize: 11 }} ><span class='text-success text-truncate'>{item.businessName || "..."}</span></small>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <button type="button" class="btn btn-lg btn-inline btn-success px-2 row mt-2 d-flex justify-content-center align-items-center" style={{ width: '100%', }}>
                                                            <span className='mx-1' ><small style={{ fontSize: 11 }} class='text-truncate' >Contact {item.name}</small></span>
                                                        </button>
                                                    </div>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>)
                        })}
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    )
}

export default CategoryPage