import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, Redirect } from 'react-router-dom'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isAndroid,
  isIOS,
  isMobile
} from "react-device-detect";
import Footer from './Footer/footer'
import WakandaLogo from '../assets/Wakanda-Logo.png'
import google from '../assets/google.png'
import apple from '../assets/Apple.png'
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
let OLDbg = 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680'
let COMMUNITYID = ''


const CommunityPage = (props) => {
  const [communityID, setCommunityID] = useState('')


  const parseURLandReturnID = async () => {
    try {
      const fullURL = window.location.href.split('#')[0]
      const fullURLsubStrings = fullURL.split('/');
      console.log(fullURLsubStrings);
      console.log('communityID is ', fullURLsubStrings[fullURLsubStrings.length - 1]);
      COMMUNITYID = fullURLsubStrings[fullURLsubStrings.length - 1]
      setCommunityID(COMMUNITYID)
    } catch (err) {
      console.log('issues with parsing URL ', err)
    }
  }

  useEffect(() => {
    parseURLandReturnID()
    if (isIOS) return window.location.replace(`wakanda://community/${communityID||COMMUNITYID}`)
    if (isAndroid) return window.location.replace(`wakanda://community/${communityID||COMMUNITYID}`)

  }, [])


  return (
    <div style={{ backgroundColor: '#F8F8F8', minHeight: '100vh', }}>
      <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
        <div className="nw1-product-smaller-header" >
          <div className="row"  >
            <div style={{
              backgroundImage: `url(${WakandaLogoWhite})`,
              width: 190,
              height: 45,
              backgroundPosition: 'top left',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              paddingBottom: 20
            }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
            </div>
            <div className="float-right d-none"><p className='sell d-none d-md-block' style={{ fontWeight: 'bold' }}>
              SELL ON WAKANDA</p>
            </div>
          </div>
        </div>
      </Link>
      <div className='container my-4' style={{ padding: 20, minHeight: '100vh', backgroundColor: 'white' }}>

        <BrowserView>
          {/* <h1 style={{color:'black'}}> This is rendered only in browser </h1> */}
          <div class='p-4 mx-auto my-auto' style={{}}>
            <div className="p-4">
              <div className='col-md-12 d-flex justify-content-center align-items' style={{ flexDirection: 'column' }}>
                <div className='col-md-12 d-flex justify-content-center' style={{ width: '100%', flexDirection: 'column' }}>
                  <h2 className='my-4' style={{ textAlign: 'center', fontFamily: 'Raleway' }}>Download Wakanda Market App to participate in this community</h2>
                  <img src={WakandaLogo} width='200' alt="wakanda-logo" className="mx-auto pt-4" />
                  <p className='text-dark p-1' style={{ fontSize: 18, textAlign: 'center' }}>One App for Africa</p>
                </div>
                <div className='col-md-12 p-4' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', width: '80%', alignSelf: 'center' }}>
                  <a href='https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'>
                    <img src={google} width='200' alt="wakanda-logo" className="mb-n1" />
                  </a>
                  <a href='https://apps.apple.com/ng/app/wakanda-market/id1451309384'>
                    <img src={apple} width='200' alt="wakanda-logo" className="mb-2 " />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </BrowserView>

        <MobileView>
          {(isIOS) && communityID && window.location.replace(`wakanda://community/${communityID}`)}
          {(isAndroid) && communityID && window.location.replace(`wakanda://community/${communityID}`)}
          <div class='p-4 mx-auto my-auto' style={{}}>
            <div className="p-4">
              <div className='col-md-12 d-flex justify-content-center align-items' style={{ flexDirection: 'column' }}>
                <div className='col-md-12 d-flex justify-content-center' style={{ width: '100%', flexDirection: 'column' }}>
                  <h3 style={{ textAlign: 'center' }}>Download Wakanda Market App to participate in this community</h3>
                  <img src={WakandaLogo} width='200' alt="wakanda-logo" className="mx-auto pt-4" />
                  <p className='text-dark p-1' style={{ fontSize: 14, textAlign: 'center' }}>One App for Africa</p>
                </div>
                <div className='col-md-12 p-4' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                  <a href='https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'>
                    <img src={google} width='200' alt="wakanda-logo" className="mb-n1" />
                  </a>
                  <a href='https://apps.apple.com/ng/app/wakanda-market/id1451309384'>
                    <img src={apple} width='200' alt="wakanda-logo" className="mb-2 " />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </MobileView>
      </div>
      <Footer />
    </div>
  );
}

export default CommunityPage;
