import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from '../utilityComponents'
import Skeleton from '@yisheng90/react-loading';

import { Link } from 'react-router-dom'
import '../styles/provider.css'
import Footer from '../Footer/footer'
//import Product from  './product1'
//import heart from '../assets/heart4.svg'
import '../styles/category.css'
import '../Header/ProductHeader.css';
////import rectangle from '../assets/Rectangle.png'
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
//import searchIcon from '../assets/searchIcon.svg'
//import map from '../assets/map.svg'
import electronics from '../../assets/Electronics.svg'
import fashion from '../../assets/fashion.jpg'
import food from '../../assets/food.jpg'
import beauty from '../../assets/beauty.jpg'
import wkacademy from '../../assets/Academy2.jpg'

import {Header, Footer2 } from '../utilityComponents'

// import dropdown from '../assets/dropd.svg'
// import electronicsIcon from '../assets/Electronics.svg'
// import fashionIcon from '../assets/fashion.svg'
// import foodIcon from '../assets/Food.svg'
// import healthIcon from '../assets/Health.svg'


let chadwick = 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/chadwick.jpeg?alt=media&token=0b01b488-39b5-4c26-a42a-d18691f80b5f'
const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"/* 
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" */

let bgCol = '#9B4CE3'

let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]
let CHOSEN_LOCATION = ''
let categories = [
    { name: 'Electronics', 'image': electronics, select: 'Phones' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Food', 'image': food, select: 'Fresh Food' },
    { name: 'Beauty', 'image': beauty, select: 'Hair' },
    { name: 'Fashion', 'image': fashion, select: 'Shoes' },
    { name: 'Cars', 'image': fashion, select: 'New Cars' },
    { name: 'Health', 'image': fashion, select: 'Pharmacies' },
    { name: 'Handyman', 'image': fashion, select: 'Plumbing' },
    { name: 'Appliances', 'image': fashion, select: 'Appliances' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Electronics', 'image': electronics, select: 'Tablets' },

]

export default function wakandaacademy() {
    return (
        <div>
            <div>
                {Header(WakandaLogoWhite, bgCol)}
                <div className='container col-md-8 ' style={{ marginTop: 50 }}>
                </div>
                
                <div class='' style={{ fontFamily: "Raleway", fontSize: 13, lineHeight: "1.6em", display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
                    <div style={{
                        //background: `url(${data.strPoster}) center center/cover no-repeat`,
                        //background:`url(${LeagueBackgroundImages[myleaguename]}) center center/cover no-repeat`,
                        backgroundImage: `url(${wkacademy})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "cover",
                        backgroundPosition: "50% 15%",
                        backgroundAttachment: "scroll",
                        width: "100%",
                        height: 500,
                    }} className=" mb-4">
                        
                    </div>
                    <p style={{ fontSize: 20, lineHeight: 1.9, width: '60%' }}>Every step is made simple!
                    Acquire all the necessary skills needed to become a pro on our e-commerce platform. Checking out an app might be a bit tedious at first, but here is a little help for you;
<p className='p-2'>
                            - Sign up<br />
- Own a shop and sell<br />
- Create a website <br />
- Upload pictures and videos of the product.<br />
- Upload offers<br />
- Trust scores<br />
                        </p>

                        <h4 className='py-4 font-weight-bold' style={{ color: '#935FF0' }}>Sign Up</h4>
                        <ul className='p-4'>
                            <li>Download the app from Google play store or share from another device.</li>
                            <li>Click on the Wakanda app on your phone.</li>
                            <li>Once it opens, go to the user-friendly icon at the bottom on the right-hand side and click.</li>
                            <li>Choose "My profile" from displayed options.</li>
                            <li>Click on the pen icon on the right side at the top.</li>
                        </ul>





                        <em>Welcome to Wakanda!</em> You are proudly on. Owning a shop is as important as signing up. Don't miss out in Making the money, join the train in a few steps.
                        <h4 className='py-4 font-weight-bold' style={{ color: '#935FF0' }}>Own a Shop</h4>


                        There are two icons that can perform this action on the Wakanda market app:


                        <ul className='py-4'>
                            <li>Sell on Wakanda and</li>
                            <li>My profile under the user-friendly profile.</li>

                        </ul>


                    </p>

                    <p style={{ fontSize: 20, lineHeight: 1.9, width: '60%' }}>Any of these will do what you want, but here, let us go through "My profile", it is simpler to open a shop right after signing up. </p>


                    <p style={{ fontSize: 20, lineHeight: 1.9, width: '60%' }}>If you make a mistake while setting up your shop, simply delete and start all over again. It is a quick process, you can get through in seconds.
                   <ul className='p-4'>
                            <li>Click on " Open a shop" beneath your profile. </li>
                            <li>Choose the "okay, I want to open a shop" option.</li>
                            <li>Agree to terms and conditions </li>
                            <li>Fill in all the required details</li>
                            <li>Start selling by posting offers.</li>
                        </ul> </p>

                    <p style={{ fontSize: 20, lineHeight: 1.9, width: '60%' }}>If you make a mistake while setting up your shop, simply delete and start all over again. It is a quick process, you can get through in seconds.

                <h4 className='py-4 font-weight-bold ' style={{ color: '#935FF0' }}>Create a website</h4>

                        <ul className='p-4'>
                            <li>Simply click on the purple icon that reads "Sell on Wakanda". </li>
                            <li>Click on the second icon " Get a website"..</li>
                            <li>Click on the first website icon and use a template for your personal business design</li>

                        </ul>

                        <h4 className='py-4 font-weight-bold ' style={{ color: '#935FF0' }}>
                            Uploading Pictures and Videos
</h4>

                        Once you are done setting up your profile and shop on Wakanda. It is just ideal that you give your business the professional look that makes more people patronize you.

                        Simply tap the user-friendly icon <br />
                        Go to the second option on the list displayed and click "My offer".<br />
                        Click on upload pictures. You can either take pictures immediately or upload from the phone gallery. <br />
                        You can add more pictures or even videos from the icon that reads, " add extra pictures", and "add videos." <br />
                        To add videos, simply enter the YouTube link to the video you want us to see and upload.<br />
                        You can upload as many pictures and videos as you want, after all, it is your shop, you own it.<br />

                        <h4 className='py-4 font-weight-bold ' style={{ color: '#935FF0' }}>
                            Uploading Offers
</h4>
Here, pictures of video(s) of what is sold are uploaded in very simple steps.

First,
Click on the human-friendly Icon.<br />
Choose the second option "my offers".<br />
Click on the additional symbol.<br />
The following options roll show up:

<ol className='p-4'>
                            <li>Add pictures- simply click and upload a picture of the product to be offered </li>
                            <li>Enter a title - the product sure does have a name. Enter what your product is called in here. For example, plantain chips, butter cakes, etc…
</li>
                            <li>Enter a description- a good description of what you are offering is a good face your product needs to skyrocket in the sale. Describe your offer well, in simple words that can be understood easily.</li>
                            <li>Enter price- enter the amount the proud t is worth. It is the price everyone checking out the product will see. </li>
                            <li>Offer Delivery- if you are not offering delivery as a seller, simply let this be and don't click. Once clicked on, it means the seller sees the delivery of the product after being caught.
</li>
                            <li>Add extra pictures- upload more pictures of products here. Make your pictures as clear as it should be. Wakanda does not request for top-notch digital pictures, but a clear picture goes a long way in describing your value.

</li>
                            <li>Add video(s)- add videos of products, services, whatever you are offering.
                            This leads you to a place that lets you upload a YouTube link.


</li>
                            <li>Click the category that matches what you have offered. For instance, plantain chips would fall under food, wigs will fall under fashion. Click the category and start selling. Good job!



</li>
                        </ol>

                        <h4 className='py-4 font-weight-bold ' style={{ color: '#935FF0' }}>
                            Trust Scores
</h4>

Our trust scores aren't anything hard, it is our way of keeping you reimbursed. Once you pass the trust score, you are eligible for loans from Wakanda. Get loans to grow your business and make more profits, it is our ideal idea of a good life.


                    </p>
                </div>
                <div style={{ height: '60vh' }}></div>
                {Footer2(bgCol)}

            </div>
        </div>
    )
}
