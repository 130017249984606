import React, { Component } from 'react'
import icon from '../../assets/plus.svg'
import {Card, Button} from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import Newsletter from '../Newsletter/newsletter'
import Footer from '../Footer/footer'

export default class faq extends Component {

    state = {
        animated: "animated fadeIn",
        display: "none",
        faq: [
            { q: "What is Wakanda market?", a: "Wakanda Market is a platform that connects buyers to providers of products and services. Providers can sell pretty much anything that can be legally sold. The platform is a mobile app available on the play store and iOS App store for now. There are various product and service categories that are offered. Wakanda Market features the latest offers from providers on the platform and an easy way to make special requests, an ability to chat with providers directly via the app or even call them if the need may arise. ", showAn: 1, icon },
            { q: "What makes Wakanda Market unique?", a: "Unlike platforms that stock products for sellers of products and in some cases do deliveries and take a monetary cut from each sale of products on sale, Wakanda Market does not stock, does not do deliveries and does not take any money from sales on the platform. Wakanda market also allows providers to offer and sell services like handyman services, photography, art etc… also there is an escrow service to facilitate transactions", showAn: 2, icon },
            { q: "How can I make money from the platform?", a: "Sign up on the platform, list your products, place offers then sit back, relax and wait for orders ", showAn: 3, icon },
            { q: "How much do I pay to sign up?", a: "Signing up is free ", showAn: 4, icon },
            { q: "How do I open a shop?", a: "You can open a shop from your profile page. Tap the ‘me’ tab… which is the leftmost tab on the bottom; this should take you to a list of new options. Then tap on ‘profile’. This should load the profile page. Once you’re on the profile page you can then open a shop from the profile page.", showAn: 5, icon },
            { q: "How do I create offers?", a: "From the profile menu of the profile page, tap on ‘offers’ <br /> From the ‘offers’ page, tap on the ‘+’ button to create new offers", showAn: 6, icon },
            { q: "How long does it take for my offers to be approved?", a: "After your offers have been created, approval typically takes a maximum of 24 hrs. Once the offer is approved, your offer will be displayed on the homepage once users sign in ", showAn: 7, icon },
            { q: "How do I collect the money in my Wallet?", a: "You can transfer the money in your wallet to your chosen bank account. A fee of N50 will be charged for each outbound transfer.To make the transfer: <br/> 1. Simply go to the profile page, and then tap on wallet from the profile menu<br/>2. From the wallet screen shown tap the ‘...’ icon on the top right and tap ‘Pay to Bank’.<br/> 3. Select your bank from the list of banks.<br/>4. Enter the bank account and the amount then tap ‘send now’. ", showAn: 8, icon },
            { q: "How do I pay for stuff on the platform?", a: "Wakanda Market features a wallet for every user and provider. In order to make purchases on the platform, you need to fund your wallet via your bank ATM Card.", showAn: 9, icon },
            { q: "How do I sign up?", a: "Signup using your phone number. Make sure that the first ‘0’ in your number is not included because there’s already ‘+234’ displayed so just type your phone number without the first ‘0’ eg +234 70419007345 ", showAn: 10, icon },
            { q: "How do I set up my profile?", a: "Once you’re signed in, tap the ‘me’ tab… which is the leftmost tab on the bottom, this should take you to a list of new options. Then tap on ‘profile’. This should load the profile page.Once you’re on the profile page you can tap on the icon on the top right corner to edit your profile. You can change your photo, edit your name and yes you can open a shop from the profile page", showAn: 11, icon },
            { q: "I can’t find the Wakanda market App on Play Store on my android phone?", a: "If you can’t find the app on the play store, simply change the location settings on your app to Nigeria or \n  1. Go to www.wakanda-market.com from your phone browser 2. Click on the Google play icon (this will load the download page for the app on the app store) 3. Click download, install 4. Then signup and enjoy!", showAn: 12, icon },
            { q: `My app keeps crashing on Android, what do i do?`, a: `Please close the app, and open it again. You can check the play store for an update and update the app \nObjections I have not heard about it before? It's a very new platform`, showAn: 13, icon },
            { q: "It is not popular?", a: "The platform only recently launched. The platform works already, and more buyers are coming in", showAn: 14, icon },
            { q: "How will the products be delivered?", a: "The buyer and the provider will decide among themselves how delivery will be done. WakandaMarket does not offer delivery services", showAn: 15, icon },
            { q: "Can I open a merchant account using my user login details?", a: "Absolutely, once you have created a user account, you can use create a seller account through the app. However, you will need to update your business details.", showAn: 16, icon },
            { q: "Do I need to upload any documents to set up my account?", a: "You do not need to upload any document.", showAn: 17, icon },
            { q: "Can I offer products, services, and content at the same time?", a: "Definitely! You can sell your products on Wakanda market, upload content, and also provide vendor services at the same time.", showAn: 18, icon },
            { q: "How many products can I sell on my online store?", a: "You can sell an unlimited number of products on Wakanda market, as long as your products are in line with the terms and conditions provided.", showAn: 19, icon },
            { q: "When will I see funds in my merchant account?", a: "When you make sales on Wakanda market, your funds will appear in your merchant wallet but will only be available for withdrawal after the escrow period.", showAn: 20, icon },

        ],
        showAnswer: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
            11: false,
            12: false,
            13: false,
            14: false,
            15: false,
            16: false,
            17: false,
            18: false,
            19: false,
            20: false,
            21: false,
        },
        showDefaultAnswer: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
            11: false,
            12: false,
            13: false,
            14: false,
            15: false,
            16: false,
            17: false,
            18: false,
            19: false,
            20: false,
            21: false,
        },


    }

    render() {
        return (
            <div className="container-fluid bg-white p-4 mt-n4" id="faq" >
                <div class=" container">
                    <h1 class=" font-weight-bold pb-2 ml-4" style={{ color: '#00A85A' }} >FAQ</h1> 
                        {this.state.faq.map((item, id) => {
                            return (
                                <Accordion defaultActiveKey="0" class='shadow-sm'>
                                    <Card className="mb-n2" style={{backgroundColor:'#fefefe', border:'0px solid #bababa', borderRadius:5}}>    
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color:'#32aa4b', textAlign:'left', border:'0px solid #bababa'}}>
                                         <Card.Header style={{border:'0px solid #bababa'}}>   
                                                {item.q}   
                                        </Card.Header>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body><p>{item.a}</p></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                            )

                        })}
                    </div> 
                    
               
               {/*  <Footer />
 */}
            </div>
        )
    }
}








{/* <dl key={id} className="m-4 animated fadeIn" style={{ borderBottom: '1px solid #DDDDDD',}}>
                                <dt style={{
                                    flexBasis: '20%',
                                    padding: '2px 4px',
                                    color: ' #707070',
                                    textAlign:'left',
                                    fontSize:12
                                    
                                    

                                }} onClick={() => {
                                    let click = this.state.showDefaultAnswer
                                    click[item.showAn] = (click[item.showAn] == true) ? false:  (click[item.showAn] == false)?true:false
                                    this.setState({ showAnswer: click, animated: this.state.animated=="animated fadeIn"?"":"animated fadeIn" })
                                    console.log('showAnswer is ', this.state.showAnswer)
                                    console.log('showDefaultAnswer is ', this.state.showDefaultAnswer)
                                }}>{item.q} <span style={{textAlign: 'right'}}><img src={item.icon} style={{width: 20, height:20, float:'right', color:'red', padding: '2px 4px',}}/></span></dt>
                                 <dd className="" style={{
                                    flexBasis: '70%',
                                    flexGrow: 1,
                                    margin: 0,
                                    padding: '4px 2px',
                                    textAlign: 'left',
                                    color: ' #707070',
                                    fontSize:12,
                                    display:this.state.showAnswer[item.showAn] == true ? "block":this.state.showAnswer[item.showAn] == false ?"none":"block",
                                    transition:"display 2s;"

                                }}>{item.a}</dd>
                            </dl> */}