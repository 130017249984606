import React, { useEffect, useState } from 'react'
import Skeleton from '@yisheng90/react-loading';
import Provider from '../provider'
import ElementSlider from '../slider'
let url = "https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let pictureURL = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let backgroundPictureURL = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let businessName = 'Wakanda World'
let description = ''
let products = [{ url }, { url }, { url }]




let data = [{ pictureURL, backgroundPictureURL, businessName, description, products },
{ pictureURL, backgroundPictureURL, businessName, description, products },
{ pictureURL, backgroundPictureURL, businessName, description, products },
{ pictureURL, backgroundPictureURL, businessName, description, products }
];

let width = 200;


const Providers = (props) => {

    const [allProviders, setProviders] = useState([1,2,3,4,5,6,,7,8,9,10])
    const  [classifiedImageData, setClassifiedImageData] = useState()


    const fetchProviderData = async () => {
        //console.log('attempting to fetch data with URL', this.state.initialURL)
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'providers',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    amount: 20,
                    location: props.location || 'lagos'
                })
            });
            const r2 = await r.json();
            //console.log('r2 in providers is ', r2)
            if (r2.msg == 'SUCCESS') {
                //console.log('r2 is xxx', r2)
                setProviders(r2.results)

            } else {
                console.log('no custom URL detected... ', r2.msg)

                //this.fetchDataWithShopName()
                //this.setState({ hasCustomDomain: false, loading: false })

            }

        } catch (err) {
            console.log("Error from firebase using fetchdatawithurl is: ", err);
            //this.setState({ hasCustomDomain: false, redirect: true })

        }

    }



    const fetchClassifiedImageData = async () => {
        //console.log('attempting to fetch data with URL', this.state.initialURL)
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/classifyPost", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    postUrl: 'https://images.pexels.com/photos/2090785/pexels-photo-2090785.jpeg', 
                })
            });
            const r2 = await r.json();
            console.log('r2 imageclassifier data is ', r2)
            if (r2.msg == 'SUCCESS') {
                //console.log('r2 is xxx', r2)
                setClassifiedImageData(r2.data)

            } else {
                console.log('no custom URL detected... ', r2.msg)

                //this.fetchDataWithShopName()
                //this.setState({ hasCustomDomain: false, loading: false })

            }

        } catch (err) {
            console.log("Error from firebase using fetchdatawithurl is: ", err);
            //this.setState({ hasCustomDomain: false, redirect: true })

        }

    }


/* 
    useEffect(() => {
        fetchProviderData() 
    }, []) */

    useEffect(() => {
        fetchProviderData() 
        // searchStuff()
    }, [props.location])

    return (
        // <div style={{ display: "flex", flexDirection: "row", overflow: "scroll-x" }}>
        <ElementSlider dataAvailable={data} top={200} height={200} >
            {allProviders.map((item, id) => {
                return (
                    <div class="container" key={id} style={{  }}>
                        <Provider
                            url={url}
                            /* bgCover={item.backgroundPictureURL?item.backgroundPictureURL:backgroundPictureURL}
                            providerPhoto={item.pictureURL?item.pictureURL:pictureURL} */
                            bgCover={item.backgroundPictureURL }
                            providerPhoto={item.pictureURL }
                            providerName={item.businessName}
                            description={item.description}
                            xID={item.xID}
                            products={(item.posts && item.posts.length > 0)?item.posts:products}
                        />
                    </div>)
            })}
        </ElementSlider>
        //  </div> 
    )
}

export default Providers