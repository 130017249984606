import React, { useEffect, useState } from 'react'
import { uniformImageHolder, nonUniformImageHolder, displayprice, getAllProductImages } from './utilityComponents'
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl, Spinner, Modal } from 'react-bootstrap'
import HelmetMetaData from './HelmetMetaData'
import Skeleton from '@yisheng90/react-loading';
import { Link, Route, Switch } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import MetaTags from './MetaTags'
import Product from './product1'
import heart from '../assets/heart3.svg'
import ProductHeader from './Header/ProductHeader'

import call from '../assets/call-icon.png'
import shoppingCartIcon from '../assets/add_shopping_cart-24px.svg'
import iPhone from '../assets/silveriphone.jpg'
import './styles/provider.css'
import ElementSlider from './slider'
import electronics from '../assets/electronics.jpg'
import fashion from '../assets/fashion.jpg'
import food from '../assets/food.jpg'
import beauty from '../assets/beauty.jpg'
import Newsletter from './Newsletter/newsletter'
import Footer from './Footer/footer'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    EmailShareButton,
} from 'react-share';
import {
    FacebookShareCount,
    GooglePlusShareCount,
    LinkedinShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
} from 'react-share';

import WakandaLogo from '../assets/Wakanda-Logo.png'
import google from '../assets/google.png'
import apple from '../assets/Apple.png'
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
let categories = [
    { name: 'Electronics', 'image': electronics },
    { name: 'Fashion', 'image': fashion },
    { name: 'Food', 'image': food },
    { name: 'Beauty', 'image': beauty }

]

let nomba = '5551234567'
const borderStyle = {

}
let url = "https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let productImages = []



const Shops = (props) => {
    const [xID, setxID] = useState("")
    const [providerID, setProviderID] = useState("")
    const [backgroundPictureURL, setBackgroundPictureURL] = useState("")


    const parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            //await this.setState({ initialURL: , shopNamefromURL: })
            //await setInitialURL(fullURLsubStrings[2])
            await setxID(fullURLsubStrings[fullURLsubStrings.length - 1])
            await setProviderID(fullURLsubStrings[fullURLsubStrings.length - 2])
            console.log('product xID is: ', xID, 'provider is ', providerID);
            //await this.setState({ url: fullURLsubStrings[2] })*/
            // return fullURLsubStrings[2]; 
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }





    useEffect(() => {
        document.title = 'Wakand Market'
    }, [])



    return (
        <div className='bg-white' style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', right: 0, overflow: 'hidden' }} >

            <ProductHeader backgroundPictureURL={backgroundPictureURL} />
            <h3> Not Found! </h3>
            <div>
                <Footer />
            </div> 
        </div>
    )
}

export default Shops