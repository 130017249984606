import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from './utilityComponents'
import { Link, Redirect } from 'react-router-dom'
import Skeleton from '@yisheng90/react-loading';
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl, Accordion } from 'react-bootstrap'
import './styles/provider.css'
import './styles/category.css'
import './Header/ProductHeader.css';
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
import WakandaJobsLogo from '../assets/Wakanda-Jobs.png'
import jobImage from '../assets/professional.jpg'
import { Banner5, BannerJobs, Header, Footer2 } from './utilityComponents'




const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

let bgCol = '#4160E0'
const marketing = [
    '- Get the latest jobs when they are posted',
    '- Join the discussion board',
    '- State your work qualifications and be contacted!'
]


let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]
let CHOSEN_LOCATION = ''
let validLocations = ["lagos", "ibadan", "portHarcourt", "kaduna", "abeokuta", "ghana", "abuja"]
const jobs = [
    { name: 'Sales & Marketing', color: '#4160E0' }, { name: 'Physical Workmanship', color: '#F46E56' },
    { name: 'Technical, IT', color: '#13C170' }, { name: 'Creatives, Design, DJ, Entertainer', color: '#C43699' },
    { name: 'Waiters, Hostesses, Representatives', color: '#4DC5E2' }, { name: 'Teaching, Nanny, Nursing', color: '#4DC5E2' }, { name: 'Household, Cooking, Errands', color: '#4DC5E2' },
    { name: 'Office and Admin', color: '#4DC5E2' },{ name: 'Other', color: '#4DC5E2' },
]
const JOBS = [
    { name: "Sales and Marketing" },
    { name: "Physical Workmanship" },
    { name: "Technical, IT" },
    { name: "Creatives, Design, DJ, Entertainer" },
    { name: "Waiters, Hostesses, Representatives" },
    { name: "Teaching, Nanny, Nursing" },
    { name: "Household, Cooking, Errands" },
    { name: "Office and Admin" },
    { name: "Extra Cash" },
    { name: "Other" },]


let jobData = {
    'Sales and Marketing': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fsales-marketing.jpg?alt=media&token=2e4d024e-af63-47ba-b117-f33d061d4451',
    'Physical Workmanship': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fphysical-workmanship.jpg?alt=media&token=876dc123-9787-470f-817e-8616140319e6',
    'Technical, IT': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Ftechnical-IT.jpg?alt=media&token=c5506621-3d60-4958-b51e-ebf51b5d3beb',
    'Creatives, Design, DJ, Entertainer': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fcreatives.jpg?alt=media&token=74f2b989-9a86-4c93-b87e-731f9e893d9a',
    'Waiters, Hostesses, Representatives': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fwaitress.jpg?alt=media&token=869149a5-9cd4-4634-91e4-b9aad10fa520',
    'Teaching, Nanny, Nursing': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fteaching.jpg?alt=media&token=56b04ad6-1e92-4347-bbaa-88faf519962f',
    'Household, Cooking, Errands': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fhousehold-cooking.jpg?alt=media&token=7d85e93f-ade8-458c-a355-0faff1428bd4',
    'Office and Admin': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Foffice-admin.jpg?alt=media&token=c0caac04-3bbf-42d7-a238-2b9e7ff2a24b',
    'Extra Cash': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fextra-cash.jpg?alt=media&token=161fb594-dcc2-407f-8ad1-8a73570151a5',
    'Other': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FQuick%20Jobs%2Fother.jpg?alt=media&token=4ddf5ff9-a98a-4d1a-a415-c3b7c87bfc49',
}

const JobsPage = (props) => {

    const [shopNamefromURL, setShopNameFromURL] = useState("")
    const [initialURL, setInitialURL] = useState("")
    const [shopCategory, setShopCategory] = useState("")
    const [shopProducts, setProducts] = useState(productData)
    const [location, setLocation] = useState('lagos')
    const [search, setSearch] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [url, setURL] = useState('')

    const handleChange = async (event) => {
        //  console.log('value in handleChange ', event.target.value, 'location is also ', location)

        await setLocation(event.target.value)

        //setLocation(event.target.value)
        //await setLocation(event.target.value)
        await setProducts(productData)
        //await props.searchforstuff(SEARCH)
    };

    const handleLocationChange = async (value) => {
        //  console.log('value in handleChange ', value, 'location is also ', location)
        let a = value.split('#/')[1]
        // console.log('value split is ', a)
        CHOSEN_LOCATION = a
        if ((a !== "") || (a !== undefined)) {
            // console.log('value is now...', a, ' CHOSEN_LOCATION ', CHOSEN_LOCATION)
            await setLocation(a)
        }
        await setProducts(productData)
    };

    const handleCategoryChange = async (value) => {
        // console.log('value in handleCategoryChange ', value, 'category is ', shopCategory)
        console.log('firing up handleCategoryChange, value passed is... ', value)
        setShopCategory(value)
    };

    const parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            //await this.setState({ initialURL: , shopNamefromURL: })
            await setInitialURL(fullURLsubStrings[2])
            let a = window.location.href.includes('#/') ? window.location.href.split('#/')[1] : ""
            /* let b = Array.isArray(a) ? a.split('/')[1] : "" */
            if (a !== "" || a !== undefined) {
                await setLocation(a)
            }
            let b = fullURLsubStrings[4].split('%20')
            console.log(' the split item is ', b, ' joined again we get ', b.join(' '))
            if (b.length > 1) {
                let c = b.join(' ')
                await setShopCategory(c)
            } else {
                await setShopCategory(fullURLsubStrings[4])
            }

            if (a == "jobs") {
                await setShopCategory("")
            }


            // console.log('the category is: ', shopCategory, 'new location is ', a);
            //await this.setState({ url: fullURLsubStrings[2] })*/
            // return fullURLsubStrings[2]; 
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }

    const searchNow = () => {
        if (search == true) {
            fetchProductsbyCategory()
        }
    }
    const fetchProductsbyCategory = async () => {
        //console.log('launching fetchProductsbyCategory, location is', location, ' shopcategory is ', shopCategory)
        // if (shopCategory == "" || shopCategory == undefined) { return }
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'offers',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    category1: "QuickJobs",
                    category2: "",
                    amount: 20,
                    location: location || 'lagos'
                })
            });
            const r2 = await r.json();
            //console.log('r2 provider fetched in fetchProductsbyCategory is ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                let data = r2.finalResults
                //setShopData(r2.results)
                await setProducts(data)
            } else {
                // console.log('no custom URL detected... ', r2.msg) 
            }

        } catch (err) {
            console.log("Error from firebase using fetchProductsbyCategory is: ", err);

        }

    }
    useEffect(() => {

        //console.log('shopcategory is ', shopCategory, ' location is also', location)
        fetchProductsbyCategory()

    }, [shopCategory, location])


    useEffect(() => {
        parseURLandReturnRelevantURL()
    }, [])



    return (
        <div>
            {redirect && <Redirect to={url} />}
            <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                {Header(WakandaJobsLogo, bgCol)}
                <div className='container col-md-8 ' style={{ marginTop: 50 }}></div>
            </Link>
            <div class='container  p-4' style={{ position: 'relative', marginTop: 40 }}>
                {Banner5(shopCategory == '' ? jobImage : jobData[shopCategory], 'Hiring? ', 'Post jobs and find workers in your area for free!', 'GET WAKANDA', '#4160E0')}
            </div>

            <section style={{ marginTop:20, minHeight: '100vh' }}  >
                <div id='filter' class='d-flex justify-content-center mb-4 container align-items-center '  >
                    <div class='container col-md-12 pb-2 pt-2'
                        style={{ backgroundColor: 'rgba(0,0,0,0.08)', borderRadius: 5 }}
                    >
                        <div class=''>
                            <Accordion defaultActiveKey="0" >
                                <Accordion.Toggle as={Button} variant="link" eventKey="1"
                                    class='gradient-buttons pt-1 col-md-12 d-flex justify-content-between mr-2 my-0 ' style={{ color: 'black', textAlign: 'left', border: '0px solid #bababa' }} block>
                                    <div >
                                        <div type="button" class="btn btn-light btn-block">
                                            <small><b>Filter : Select Category</b></small>
                                            <small><b>&nbsp;<i class="fas fa-caret-down"></i></b></small>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <div class='gradient-buttons pt-1 col-md-12 d-flex justify-content-around mr-2 my-0 flex-wrap'>
                                        {jobs.map((item, id) => {
                                            return (
                                                <button className='p-1 col-md-2 m-1' key={id} style={{ backgroundColor: shopCategory === item.name ? '#647bd6' : '#D0D0D0', borderWidth: 0, borderRadius: 5,  overflow:'hidden', textOverflow: 'ellipsis',lineHeight:1  }}
                                                    onClick={() => {
                                                        handleCategoryChange(item.name)
                                                    }}>
                                                    <Link to={`/jobs/${item.name}`} style={{ textDecoration: 'none', color: shopCategory === item.name ? 'white' : 'black' }}
                                                        onPress={() => {
                                                            handleCategoryChange(item.name)
                                                        }}>
                                                        <small>{item.name}</small>
                                                    </Link>
                                                </button>
                                            )
                                        })}
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        </div>
                        <Accordion defaultActiveKey="0" class='shadow-sm '>

                            <Accordion.Toggle as={Button} variant="link" eventKey="1" class='gradient-buttons pt-1 col-md-12 d-flex justify-content-between mr-2 my-0'
                                style={{ color: 'black', textAlign: 'left', border: '0px solid #bababa', }} block>
                                <div >
                                    <div type="button" class="btn btn-light btn-block">
                                        <small><b>Filter : Select Location</b></small>
                                        <small><b>&nbsp;<i class="fas fa-caret-down"></i></b></small>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <div class='gradient-buttons pt-1 col-md-12 d-flex justify-content-around mr-2 my-0 flex-wrap'>
                                    {locations.map((item, id) => {
                                        return (
                                            <button className='p-1 col-md-1 mx-1 my-1 ' key={id} style={{ textDecoration: 'none', backgroundColor: location === item.value ? '#00A85A' : '#D0D0D0', borderWidth: 0, borderRadius: 5 }}
                                                onClick={() => {
                                                    handleLocationChange(item.value)
                                                }}>
                                                <small style={{ textDecoration: 'none', color: location === item.value ? 'white' : 'black' }}>{item.name === 'Port Harcourt' ? 'PH' : item.name}</small>
                                            </button>
                                        )
                                    })}
                                </div>
                            </Accordion.Collapse>
                        </Accordion>

                    </div>

                </div>
                <div class='container d-flex justify-content-center mt-4 mb-4'>

                    <div class=' mt-4 row'  >
                        {shopProducts.length == 0 && <div>
                            <h5>Sorry! No results to show...!</h5>
                        </div>}
                        {shopProducts.map((item, id) => {
                            return (
                                <div class='col-md-6 col-sm-12 py-2' key={id}>
                                    <Link to={`jobs/${item.providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                    <div class='shadow-sm pt-0 ml-1 mr-1 zoomin row ' style={{ border: '1px solid rgba(100,100,100,0.1)', borderRadius: 10 }}>
                                        {item.pictureURL || item.backgroundPictureURL ? <div class='col-md-4 p-2 pt-0 d-flex justify-content-center align-items-center'
                                            style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                            {uniformImageHolder(item.pictureURL || item.backgroundPictureURL || url, '100%', '13rem', '', 10)}
                                        </div>
                                            :
                                            <div class='col-md-4 p-2 pt-0 d-flex justify-content-center align-items-center'><Skeleton width={"100%"} height={"12rem"} /></div>}

                                        <div class='col-md-8 pl-2' style={{ padding: 20, paddingBottom: 20, paddingTop: 0, width: "100%", }}>
                                            <div style={{ height: '6.5rem', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap' }}>
                                                    <small style={{ fontSize: 14, }} ><b>{item.title || "..."}</b></small>
                                                </div>
                                                <div style={{ width: "100%", marginTop: -5, overflowX: 'hidden', textOverflow: 'ellipsis', textWrap: 'wrap' }}>
                                                    <small style={{ fontSize: 12, color: 'grey' }} > {item.text || "..."} </small>
                                                </div>
                                            </div>

                                            <hr />
                                            <div style={{ width: "100%", marginTop: -5, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <small class='text-truncate col-md-12  pl-0' style={{ fontSize: 11 }} ><span class='text-success text-truncate'><span style={{ color: 'black' }}>offered by</span> {item.profileNameBusiness || "..."}</span></small>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                    <button type="button" class="btn btn-lg btn-inline px-2 row mt-2 d-flex justify-content-center align-items-center"
                                                        style={{ width: '100%', backgroundColor: '#4160E0' }}>
                                                        <span className='mx-1' ><small style={{ fontSize: 11 }} class='text-truncate text-white pb-2' >View Details {item.name}</small></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </Link>
                                </div>)
                        })}
                    </div>
                </div>
            </section>
            <div class='container mt-n4  p-4' style={{ minHeight: 60, position: 'relative', marginTop: 0 }}>
                {BannerJobs(shopCategory == '' ? jobImage: jobData[shopCategory], 'Find many more jobs near you on the app!', marketing, 'GET STARTED', '#4160E0')}
            </div>
            { Footer2(bgCol)}
        </div >
    )
}

export default JobsPage