import React, { useState } from "react";

const SliderArrow = (props) => {
    const { type, ...rest } = props;
    const [hover, setHover] = useState(false)

    return (
        <div style={{
            display: 'flex', justifyContent: type === "next" && 'flex-end',
            right: type === "next" && 0,
            left: type === "prev" && 0
        }}>
            <div type={type} {...rest} style={{
                zIndex: 1,
                width: 30,
                height: 30,
                position: 'absolute',
                top: props.top,
                backgroundColor: hover ? '#707070' : '#0000001A',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 5,
                opacity: 0.6,
                cursor: hover && 'pointer'
            }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {type === "next" ? <img src={require("../images/next.png")} alt="next" style={{ width: 15, height: 15, color: '#fff' }} /> : <img src={require('../images/back.png')} alt="previous" style={{ width: 15, height: 15, color: '#fff' }} />}
            </div>
        </div>
    );
};

export default SliderArrow;
