import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header/header'
import Footer from './components/Footer/footer';
import Newsletter from './components/Newsletter/newsletter';
import ProductHeader from './components/Header/ProductHeader';

function HeaderComponent() {
  return (
    <div style={{overflowX: 'hidden'}} className="App">
       {/* <Header/> */}
       <ProductHeader/>
       <Footer/>
       <Newsletter/>
    </div>
  );
}

export default HeaderComponent;
