import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { uniformImageHolder, displayprice } from '../utilityComponents'
import Skeleton from '@yisheng90/react-loading';
import Product from '../product1'
//import './styles/provider.css'
import ElementSlider from '../slider'
import Providers from '../Provider/providers'
import './shops.css'

let url = "https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]


const Shops = (props) => {
    const [allProducts, setProducts] = useState(data)

    const fetchProducts = async () => {
        console.log('offers will now be fetched for this location ', props.location)
        if(!props.location) return
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'offers',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    amount: 50,
                    location: props.location || 'lagos',
                    //category1:'Electronics'
                })
            });
            const r2 = await r.json();
            //console.log('r2 offers fetched is ', r2)
            if (r2.msg == 'SUCCESS') {
                //console.log('r2 is xxx', r2)
                setProducts(r2.finalResults)

            } else {
                console.log('no custom URL detected... ', r2.msg)

                //this.fetchDataWithShopName()
                //this.setState({ hasCustomDomain: false, loading: false })

            }

        } catch (err) {
            console.log("Error from firebase using fetchdatawithurl is: ", err);
            //this.setState({ hasCustomDomain: false, redirect: true })

        }

    }



    useEffect(() => {
        fetchProducts()
    }, [props.location])


    return (
        <div className='text-center'>

            <ElementSlider dataAvailable={allProducts} top={70} >
                {allProducts && allProducts.map((item, id) => {
                    return (
                        <div class="container " style={{ display: 'flex', flexDirection: "column", overflow: 'hidden', }}>
                            {item.pictureURL ? <div className="fadeinshop " style={{}}>
                                <Link to={`/${item.providerID}/${item.xID}`}>{uniformImageHolder(item.pictureURL, '9rem', '9rem', '2px solid rgb(128,128,128)')}</Link>
                            </div> : <div class='col-md-12 fadeinshop'
                                style={{
                                    padding: 0,
                                    borderRadius: 10,
                                    borderBottomLeftRadius: 20,
                                    borderBottomRightRadius: 20,
                                    marginTop: 10,
                                    overflow: 'hidden'
                                }}>
                                    <Skeleton width={"9rem"} height={"9rem"} />
                                </div>}
                            <div style={{ width: '9rem', borderBottomRightRadius: 5, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <small class='text-truncate' style={{ marginTop: 10, textOverflow: 'ellipsis', textAlign: 'center' }}>
                                    {item.title || item.text || "..."}
                                </small>
                            </div>

                            <small class='text-truncate' style={{ marginTop: 10, textOverflow: 'ellipsis', textAlign: 'center', fontWeight: 'bold' }}>
                                {displayprice(item.price, item.priceType, item.priceCurrency)}
                            </small>
                        </div>
                    )
                }
                )}
            </ElementSlider>
        </div>
    )
}

export default Shops