import React, { useEffect, useState } from 'react'
import { uniformImageHolder, nonUniformImageHolder, displayprice, getAllProductImages } from './utilityComponents'
import { Dropdown, DropdownButton, Button, InputGroup, FormControl, Spinner, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet';
import HelmetMetaData from './HelmetMetaData'
import Skeleton from '@yisheng90/react-loading';
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import { Footer2 } from './utilityComponents'
import MetaTags from './MetaTags'
import Product from './product1'
import heart from '../assets/heart3.svg'
import ProductHeader from './Header/ProductHeader'

import call from '../assets/call-icon.png'
import shoppingCartIcon from '../assets/add_shopping_cart-24px.svg'
import iPhone from '../assets/silveriphone.jpg'
import './styles/provider.css'
import ElementSlider from './slider'
import electronics from '../assets/electronics.jpg'
import fashion from '../assets/fashion.jpg'
import food from '../assets/food.jpg'
import beauty from '../assets/beauty.jpg'
import Newsletter from './Newsletter/newsletter'
import Footer from './Footer/footer'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    EmailShareButton,
} from 'react-share';
import {
    FacebookShareCount,
    GooglePlusShareCount,
    LinkedinShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
} from 'react-share';

import WakandaLogo from '../assets/Wakanda-Logo.png'
import google from '../assets/google.png'
import apple from '../assets/Apple.png'
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
let categories = [
    { name: 'Electronics', 'image': electronics },
    { name: 'Fashion', 'image': fashion },
    { name: 'Food', 'image': food },
    { name: 'Beauty', 'image': beauty }

]

let bgCol = '#4160E0'

const borderStyle = {

}
let url = "https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let productImages = []



const JobDetails = (props) => {
    const [shopNamefromURL, setShopNameFromURL] = useState("")
    const [productExists, setProductExists] = useState("")
    const [xID, setxID] = useState("")
    const [postIDs, setPostIDs] = useState("")
    const [product, setProduct] = useState("")
    const [postImages, setPostImages] = useState([])
    const [imageInView, setImageInView] = useState('')
    const [providerID, setProviderID] = useState("")
    const [shopProducts, setShopProducts] = useState([])
    const [businessName, setBusinessName] = useState("")
    const [description, setDescription] = useState("")
    const [pictureURL, setPictureURL] = useState("")
    const [backgroundPictureURL, setBackgroundPictureURL] = useState("")
    const [phone, setPhone] = useState(false)
    const [phoneDisplay, setPhoneDisplay] = useState('Show Contact')
    const [phoneNumber, setPhoneNumber] = useState("")
    const [metaData, setMetaData] = useState({})
    const [modalState, setModalState] = useState(true)
    const [discountAvailability, setDiscountAvailability] = useState(false)
    const [discountAmt, setDiscountAmt] = useState(0)
    const [registerNumberActivity, setRegisterNumberActivity] = useState(false)
    const [discountPhoneNumber, setDiscountPhoneNumber] = useState('')
    const [discountMsg, setDiscountMsg] = useState('')
    const [show, setShow] = useState(true)
    const [areaCode, setAreaCode] = useState('+234')
    const [showDiscountInputBox, setShowDiscountInputBox] = useState(false)
    const [location, setLocation] = useState('lagos')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const shareUrl = 'https://wakandamarket.com/';

    const parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            //await this.setState({ initialURL: , shopNamefromURL: })
            //await setInitialURL(fullURLsubStrings[2])
            await setxID(fullURLsubStrings[fullURLsubStrings.length - 1])
            await setProviderID(fullURLsubStrings[fullURLsubStrings.length - 2])
            console.log('product xID is: ', xID, 'provider is ', providerID);
            //await this.setState({ url: fullURLsubStrings[2] })*/
            // return fullURLsubStrings[2]; 
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }


    const fetchShopOwnerDetailsbyxID = async () => {
        //console.log('attempting to fetch data with URL', this.state.initialURL)
        if (providerID.length == '') { return }
        console.log('the providerID received is fetchShopOwnerDetailsbyxID in: ', providerID);
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'provider',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    providerID: providerID,
                    location: 'lagos'
                })
            });
            const r2 = await r.json();
            console.log('r2 provider fetched in fetchShopOwnerDetailsbyxID is ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                let data = r2.result
                await filterProduct(data.posts || [])
                //setShopData(r2.results)
                await setShopProducts(data.posts)
                await setBusinessName(data.businessName)
                await setDescription(data.description)
                await setPictureURL(data.pictureURL)
                await setPhoneNumber(data.phoneNumber)
                await setBackgroundPictureURL(data.profilePictureURL)
                await setDiscountAmt(data.discountAvailableNGN)
                if (data.discountAvailableNGN > 0) {
                    await setDiscountAvailability(true)
                }

            } else {
                console.log('no custom URL detected... ', r2.msg)

                //this.fetchDataWithShopName()
                //this.setState({ hasCustomDomain: false, loading: false })

            }

        } catch (err) {
            console.log("Error from firebase using fetchdatawithurl is: ", err);
            fetchShopOwnerDetailsbyxID()

        }

    }

    const showCallLog = async () => {
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldSet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    //type: 'provider',
                    authPhrase: "Ap2§Ô˝øS5aºƒ3¶",
                    action: "reportCall",
                    phoneNumber: discountPhoneNumber,
                    profileID: providerID,
                    postID: xID,
                    deviceCategory: isMobile ? 'isMobile' : 'isChrome'

                })
            });
            const r2 = await r.json();
            console.log('r22 provider fetched in showCallLog is ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r22 is xxx', r2)
                //let data = r2.result
                //setDiscountMsg(r2.userMessage || '')
            }

        } catch (err) {
            console.log("Error from firebase using getDiscount is: ", err);
            setRegisterNumberActivity(false)

        }
    }


    const getDiscount = async () => {

        if (!discountPhoneNumber.startsWith("+")) {
            alert("Error, invalid phone number");
            setRegisterNumberActivity(false)
            return;
        }
        if (discountPhoneNumber.startsWith("+234") && discountPhoneNumber.length > 14) {
            alert("Your phone number is too long");
            setRegisterNumberActivity(false)
            return;
        }
        if (discountPhoneNumber.startsWith("+234") && discountPhoneNumber.length < 14) {
            alert("Your phone number is too short");
            setRegisterNumberActivity(false)
            return;
        }
        if (discountPhoneNumber.startsWith("+233") && discountPhoneNumber.length > 13) {
            alert("Your phone number is too long");
            setRegisterNumberActivity(false)
            return;
        }
        if (discountPhoneNumber.startsWith("+233") && discountPhoneNumber.length < 13) {
            alert("Your phone number is too short");
            setRegisterNumberActivity(false)
            return;
        }
        if (discountPhoneNumber.length < 10) {
            alert("Phone number too short");
            setRegisterNumberActivity(false)
            return;
        }
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldSet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    //type: 'provider',
                    action: "registerDiscount",
                    phoneNumber: discountPhoneNumber,
                    authPhrase: "Ap2§Ô˝øS5aºƒ3¶",
                    profileID: providerID
                })
            });
            const r2 = await r.json();
            console.log('r2 provider fetched in getDiscount is ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                //let data = r2.result
                setDiscountMsg(r2.userMessage || '')
            }

        } catch (err) {
            console.log("Error from firebase using getDiscount is: ", err);
            setRegisterNumberActivity(false)

        }
        setRegisterNumberActivity(false)
    }

    const getLocationByIP = async () => {
        /* navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
        }) */

        fetch("https://ipapi.co/json/")
            .then(response => response.json())
            .then(data => {
                console.log('location based on ip of host is ', data)
                if (data.city === 'Lagos') {
                    setLocation('lagos')
                } else if (data.city === 'Oyo') {
                    setLocation('ibadan')
                } else if (data.city === 'Ibadan') {
                    setLocation('ibadan')
                } else if (data.city === 'Port Harcourt') {
                    setLocation('portHarcourt')
                } else if (data.city === 'Kaduna') {
                    setLocation('kaduna')
                } else if (data.city === 'Abeokuta') {
                    setLocation('abeokuta')
                } else if (data.country_name === 'Ghana') {
                    setLocation('ghana')
                    setAreaCode('+233')
                } else if (data.city === 'Abuja') {
                    setLocation('abuja')
                } else if (data.city === 'Abuja') {
                    setLocation('abuja')
                } else {
                    setLocation('lagos')
                }
                console.log('location from ip is ', location)
            })
    }

    useEffect(() => {
        getLocationByIP()
        //handleIcon()
        //topFunction()
    }, [])
    const handleSelect = (e) => {
        console.log(e);
        setAreaCode(e)
    }

    const filterProduct = async (items) => {
        //console.log('items received in filterproducts are: ', items, ' xID is ', xID);
        if (Array.isArray(items) && items.length > 0) {
            let productItem = '';

            productItem = items.filter(item => {
                if (item.xID == xID) {
                    return true;
                }

            });
            productImages = getAllProductImages(productItem[0]);
            let a = Object.values(productImages)
            setPostImages(a[0])
            console.log('productImages values are: ', a);
            await setImageInView(productItem[0])
            await setProduct(productItem[0]);
        }
    }



    // const pressCall = () => {
    //     const url = 'tel://+2347061276649'
    //     Link.openURL(url)
    // }

    const shareQuote = () => {
        return (
            <div>
                {product.hasOwnProperty('title') && <h3 className=" mt-0 py-2" style={{ color: "#1C1B1B", marginBottom: 30, textAlign: 'left' }} >{product.title} </h3>}
                <hr />
                {product.hasOwnProperty('title') && <p>{product.text}</p>}
            </div>
        )
    }
    useEffect(() => {
        fetchShopOwnerDetailsbyxID()
        filterProduct()
    }, [providerID])

    useEffect(() => {
        setImageInView(product.pictureURL)
    }, [product])
    useEffect(() => {

        parseURLandReturnRelevantURL()
        console.log('xID is ', xID)

    }, [xID, providerID])

    useEffect(() => {
        document.title = product.hasOwnProperty('title') ? product.title : 'Wakanda Market'
    }, [product])


    //let phoneNum = {phoneNumber}
    let fone = JSON.stringify({ phoneNumber })
    let newfone = fone.substring(13)
    let numm = `tel:${newfone}`

    return (
        <div className='bg-white' style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', right: 0, overflow: 'hidden' }} >
            {product && <HelmetMetaData title={product.title}
                description={product.text}
                image={product.pictureURL}
            ></HelmetMetaData>}

            <div className="bg-white" style={{ width: '100%', }}>
                <div className="nw1-product-bigger-header " style={{ backgroundColor: '#7c8fdd' }} >
                    <div style={{
                        backgroundImage: `url(${pictureURL})`,
                        width: '100%',
                        height: 300,
                        backgroundPosition: 'center center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'repeat',
                    }} className=''>
                    </div>
                </div>
                <div className="nw1-product-smaller-header " style={{ backgroundColor: '#4160E0' }} >
                    <div className="row"  >
                        <Link to='/jobs' style={{ textDecoration: 'none', width: '100%' }}>
                            <div style={{
                                backgroundImage: `url(${WakandaLogoWhite})`,
                                width: 190,
                                height: 45,
                                backgroundPosition: 'top left',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                paddingBottom: 20
                            }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                            </div>
                        </Link>
                    </div>

                </div>


            </div>

            <div class='shadow-md container-fluid' style={{ paddingBottom: 20, backgroundColor: '#F5F5F5', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, width: '100%', }}>
                {/*  {backgroundPictureURL && <div style={{ paddingBottom: 20, backgroundColor: '#F5F5F5', borderBottomLeftRadius: 20, borderBottomRightRadius: 20, width: '100%', height: 100 }}>

                </div>} */}
                <div className='container' style={{
                    display: 'flex', padding: 20, paddingLeft: 30, paddingBottom: 0,
                    //marginTop: -50, 
                }}>
                    {/* <div style={{ float: 'left', marginTop: -50, }}> {uniformImageHolder(pictureURL || providerPhoto, 100, 100, '4px solid #F5F5F5', 15)}</div> */}
                    {pictureURL ? <div style={{ float: 'left', marginTop: -50, }}>
                        {uniformImageHolder(pictureURL, 100, 100, '4px solid #F5F5F5', 15)}
                    </div>
                        :
                        <div style={{ float: 'left', marginTop: -40, borderRadius: 15, overflow: 'hidden' }}>
                            <Skeleton width={100} height={100} />
                        </div>
                    }
                    <div style={{ float: 'right', display: 'flex', flexDirection: "column", marginLeft: 10, textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'wrap' }}>
                        {businessName && <Link to={`/shop/${providerID}`} style={{ textDecoration: 'none' }}>
                            <small class='text-truncate' style={{ textDecoration: 'none', color: 'black' }}><b>{businessName || ""}</b></small>
                        </Link>}
                        <small class='text-truncate text-wrap' style={{ marginTop: 5, fontSize: 11, color: '#666' }}>{description || ""}</small>
                        {(businessName || description || pictureURL) && <div style={{ transition: 'all 1s ease', marginTop: 10, marginLeft: 0 }}>
                            <button type="button" class="btn px-4  m-1" style={{ backgroundColor: '#3048A8', color:'#ffffff'}} onClick={() => { setPhone(true); setPhoneDisplay(phoneNumber); showCallLog() }}>
                                {phone == true ? <a href={numm} style={{ fontWeight: 'bold', paddingLeft: 3 }} style={{ textDecoration: 'none' }}>
                                    <small style={{ margin: 10, fontWeight: 'bold', color: '#ffffff', textDecoration: 'none', }}>&nbsp;<img src={call} alt="" />{phoneDisplay}</small>
                                </a> : <small style={{ margin: 10, fontSize: 14, fontWeight: 'bold' }}>{phoneDisplay}</small>}
                            </button>
                        </div>}
                    </div>
                </div>
            </div>

            <section class='container-fluid m-1' style={{}}>
                <div className="bg-white container pt-4 mt-4">
                    <div className="row mx-auto mb-4">
                        <div class=' col-md-6'>
                            <div className="col-md-10 animated animate__backInLeft animate__slower">
                                {product ? <div style={{
                                    backgroundImage: `url(${imageInView || product.pictureURL})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: "contain",
                                    backgroundPosition: "center center",
                                    backgroundAttachment: 'relative',
                                    backgroundColor: 'rgba(255,255,255,0.3)',
                                    overflow: 'hidden',
                                    height: "auto",
                                    maxWidth: '104%',
                                    width: '100%',
                                    height: 400,
                                    border: '1px solid rgba(204, 204, 204, 0.5)',
                                    marginBottom: 20
                                }}></div> :
                                    <Skeleton width={'100%'} height={400} />}
                            </div>
                            {postImages.length > 1 && <div className='row m-2 text-wrap'>
                                {postImages.map((image, id) => {
                                    return (
                                        <div key={id} style={{
                                            backgroundImage: `url(${image})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: "cover",
                                            backgroundPosition: "center center",
                                            backgroundAttachment: 'relative',
                                            backgroundColor: 'rgba(255,255,255,0.3)',
                                            overflow: 'hidden',
                                            height: "auto",
                                            maxWidth: '104%',
                                            width: 100,
                                            height: 80,
                                            border: '1px solid rgba(204, 204, 204, 0.5)',
                                            marginRight: 10
                                        }}
                                            className='fadein'
                                            onClick={() => setImageInView(image)}
                                        ></div>
                                    )
                                })}
                            </div>}
                        </div>


                        <div class="mr-md-3 overflow-hidden col-md-5 m-0 animated animate__backInRight animate__slower text-wrap" >

                            {product.hasOwnProperty('title') && <h3 className=" mt-0 py-2" style={{ color: "#1C1B1B", marginBottom: 30, textAlign: 'left' }} >{product.title} </h3>}
                            {/* <div>
                                <a href={numm}><Button title='call' >Testing</Button></a>
                            </div> */}
                            <hr />
                            {product.hasOwnProperty('title') && <p>{product.text}</p>}
                            <div class='row d-flex align-items-center ml-1' style={{ textOverflow: 'ellipsis' }}>
                                {((product.hasOwnProperty("postPriceDiscount") && (product.postPriceDiscount <= 0)) || (!product.hasOwnProperty("postPriceDiscount"))) && <h3 style={{ color: 'black', fontWeight: 'bold' }} className="py-2 text-truncate ">
                                    {displayprice(product.price, product.priceType, product.priceCurrency) || '...'}
                                </h3>}
                                {(product.hasOwnProperty("postPriceDiscount") && (product.postPriceDiscount > 0)) && <h6 style={{ color: 'black', fontWeight: 'bold' }} className="py-2 text-truncate ">
                                    <strike>{displayprice(product.price, product.priceType, product.priceCurrency) || '...'}</strike>
                                </h6>}
                                {(product.hasOwnProperty("postPriceDiscount") && (product.postPriceDiscount > 0)) && <h6 style={{ color: 'green', fontWeight: 'bold', marginLeft: 10 }} className="py-2 text-truncate ">
                                    ( {Math.floor(product.postPriceDiscount / product.price * 100)}<i>{"% off"}</i> )
                                </h6>}
                            </div>
                            <div style={{ marginTop: -10 }}>
                                {(product.hasOwnProperty("postPriceDiscount") && (product.postPriceDiscount > 0)) && <h3 style={{ color: 'red', fontWeight: 'bold', textOverflow: 'ellipsis' }} className='text-truncate '>
                                    {product.priceCurrency === 'NGN' ? '₦' : '₵'} {(product.price - product.postPriceDiscount) || '...'}
                                </h3>}
                            </div>

                            <hr />
                            <div>

                                {businessName && <p className='my-4' style={{ fontSize: 11 }}>Posted By  <span style={{ color: '#00A85A' }}>{businessName || ""}</span></p>}
                                <div class='col-md-12 row' style={{ transition: 'all 1s ease' }}>
                                    <button type="button" class="btn btn-sm px-4 py-1 py-sm-2 btn-inline  ml-1" style={{ backgroundColor: '#3048A8', color:'#ffffff' }} onClick={() => { setPhone(true); setPhoneDisplay(phoneNumber) }}>{phone == true ? <a href={numm} style={{ color: '#ffffff', textDecoration: 'none', fontWeight: 'bold', paddingLeft: 3 }}><small style={{ margin: 10, fontWeight: 'bold' }}>
                                        &nbsp;<img src={call} alt="" />{phoneDisplay}</small></a> : <small style={{ margin: 10, fontWeight: 'bold', fontSize: 16 }}>{phoneDisplay}</small>}
                                    </button>
                                </div>
                                {product.hasOwnProperty('title') && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', height: 100, width: '100%', alignItems: 'center' }} className='pl-0'>
                                    Share:
                                    <FacebookShareButton quote={`${product.title} `} url={`http://wakandamarket.com/${providerID}/${xID}`}>
                                        <SocialIcon url="http://facebook.com" />
                                    </FacebookShareButton>
                                    <TwitterShareButton title={`Buy ${product.title} // ${product.text.substring(0, 30)} `} hashtag={'#wakandamarket'} url={`http://wakandamarket.com/${providerID}/${xID}`} via={'WakandaMarket'}>
                                        <SocialIcon url="http://twitter.com" />
                                    </TwitterShareButton>
                                    <WhatsappShareButton title={`Buy ${product.title} // ${product.text.substring(0, 30)} `} hashtag={'#wakandamarket'} url={`http://wakandamarket.com/${providerID}/${xID}`} via={'WakandaMarket'}>
                                        <SocialIcon url="http://web.whatsapp.com" />
                                    </WhatsappShareButton>
                                </div>}

                                {discountAvailability && <div style={{ width: '100%', paddingTop: 20 }} class='col-md-12'>
                                    <InputGroup >
                                        <DropdownButton
                                            as={InputGroup.Prepend}
                                            variant="outline-secondary"
                                            title={areaCode}
                                            id="input-group-dropdown-1"
                                            onSelect={handleSelect}
                                        >
                                            <Dropdown.Item eventKey='+233'>+233</Dropdown.Item>
                                            <Dropdown.Item eventKey='+234'>+234</Dropdown.Item>
                                        </DropdownButton>
                                        <FormControl
                                            placeholder="Add your Phone Number "
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            style={{}}
                                            class='outline text-center'
                                            disabled={registerNumberActivity}
                                            onChange={(e) => {
                                                let A = `${areaCode}${e.target.value}`
                                                setDiscountPhoneNumber(A)
                                                console.log('phone number set for discount is ', discountPhoneNumber)
                                            }}
                                        />
                                        {/*  <InputGroup.Append>
                                            {!registerNumberActivity && <Button variant="outline-secondary" onClick={() => {
                                                setRegisterNumberActivity(true)
                                                getDiscount()
                                            }}>Get Discount</Button>}
                                            {registerNumberActivity && <Spinner animation="grow" />} 
                                        </InputGroup.Append>*/}
                                    </InputGroup>

                                    {!registerNumberActivity && <Button variant="primary mt-1 p-1 col-md-12" onClick={() => {
                                        setRegisterNumberActivity(true)
                                        getDiscount()
                                    }} block>Get Discount</Button>}
                                    {registerNumberActivity && <Spinner animation="grow" />}
                                    {/* <div style={{ width: '100%' }} class='col-md-12 m-2 d-flex justify-content-center align-items'>
                                        {!registerNumberActivity && <Button variant="primary m-3 p-1" onClick={() => {
                                            setRegisterNumberActivity(true)
                                            getDiscount()
                                        }} block>Get Discount</Button>}
                                        {registerNumberActivity && <Spinner animation="grow" />}
                                    </div> */}
                                    {discountMsg.length > 0 && <div class='mt-2'>
                                        <small class='text-success text-align-center'>{discountMsg}</small>
                                        <BrowserView>
                                            <div className='col-md-12 p-4' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', }}>

                                                <a href='https://play.google.com/store/apps/details?id=com.african_founders.Wakanda' target='_blank' className='col-md-6 col-sm-12 col-xs-12' style={{ textDecoration: 'none', width: '100%' }}>
                                                    <div className='col-md-12' style={{
                                                        backgroundImage: `url(${google})`,
                                                        backgroundPosition: 'center center',
                                                        backgroundRepeat: 'no-repeat',
                                                        //backgroundAttachment: 'fixed',
                                                        backgroundSize: '100%',
                                                        width: '100%',
                                                        height: 50,
                                                        borderRadius: 5
                                                    }}>
                                                    </div>
                                                </a>
                                                <a href='https://apps.apple.com/ng/app/wakanda-market/id1451309384' target='_blank' className='col-md-6 col-sm-12 col-xs-12' style={{ textDecoration: 'none', width: '100%' }}>
                                                    <div className='col-md-12' style={{
                                                        backgroundImage: `url(${apple})`,
                                                        backgroundPosition: 'center center',
                                                        backgroundRepeat: 'no-repeat',
                                                        //backgroundAttachment: 'fixed',
                                                        backgroundSize: '100%',
                                                        width: '100%',
                                                        height: 50,
                                                        borderRadius: 5
                                                    }}>
                                                    </div>
                                                </a>
                                            </div>
                                        </BrowserView>

                                        <MobileView>
                                            <div className='col-md-12 p-4' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                                {(isAndroid) && <div className='col-md-6' style={{
                                                    backgroundImage: `url(${google})`,
                                                    backgroundPosition: 'center center',
                                                    backgroundRepeat: 'no-repeat',
                                                    //backgroundAttachment: 'fixed',
                                                    backgroundSize: '84%',
                                                    width: '50%',
                                                    height: 50,
                                                    borderRadius: 5
                                                }}>
                                                </div>}
                                                {(isIOS) && <div className='col-md-6' style={{
                                                    backgroundImage: `url(${apple})`,
                                                    backgroundPosition: 'center center',
                                                    backgroundRepeat: 'no-repeat',
                                                    //backgroundAttachment: 'fixed',
                                                    backgroundSize: '84%',
                                                    width: '50%',
                                                    height: 50,
                                                    borderRadius: 5
                                                }}>
                                                </div>}
                                            </div>
                                        </MobileView>
                                    </div>}
                                </div>}
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div class=" font-weight-bold p-2 pt-3 row animated fadeIn px-4 mb-4"
                        style={{ color: '#00A85A', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#F5F5F5' }}>
                        <h6 style={{ textAlign: 'center' }}><span style={{ color: 'black' }}>More from</span> {businessName}</h6>
                        <Link to={`/shop/${providerID}`} style={{ textDecoration: 'none' }}>
                            <div style={{ paddingBottom: 10 }}> <small style={{ textDecoration: 'none', fontWeight: 'bold', color: 'black' }}>see more</small></div>
                        </Link>
                    </div>
                </div>

                <section style={{ marginTop: 1, minHeight: '30vh' }}>

                    <div class='d-flex justify-content-center  mb-4 p-0'>
                        <div class='container row p-0 m-0'>
                            {shopProducts.map((item, id) => {
                                if (item == product) return
                                if (shopProducts.indexOf(item) >= 8) return
                                return (
                                    <div class='col-md-2 zoomin  py-2 ' style={{ width: '50%' }} key={id}>
                                        <div class='shadow-sm p-0 pt-0' style={{ border: '1px solid rgba(100,100,100,0.1)', borderRadius: 10 }}>
                                            {item.pictureURL ? <Link to={`/${providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                <div class=''
                                                    style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                    {uniformImageHolder(item.pictureURL || url, '100%', '12rem', '', 10)}
                                                </div>
                                            </Link>
                                                :
                                                <Skeleton width={"100%"} height={"12rem"} />}
                                            {/* <div class='col-md-12 p-0 pt-0' style={{ marginTop: -10 }}>{uniformImageHolder(item.pictureURL || url, '100%', '12rem', '', 10)}</div> */}
                                            <div class='col-md-12 ' style={{ padding: 20, paddingBottom: 20, paddingTop: 0, width: "100%", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                <div style={{ width: "100%", marginTop: 10, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                    <Link to={`/${providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                        <small class='text-truncate' style={{ fontSize: 14, textOverflow: 'ellipsis', color: 'black' }} >{item.title || item.text || "..."}</small>
                                                    </Link>
                                                </div>
                                                <div style={{ width: "100%", marginTop: -5, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                    <small class='text-truncate' style={{ fontSize: 13, textOverflow: 'ellipsis', }} ><b>{displayprice(item.price, item.priceType, item.priceCurrency) || "..."}</b></small>
                                                </div>
                                            </div>
                                        </div>

                                    </div>)
                            })}
                        </div>
                    </div>
                </section>

                
            </section>

            <div >
                {/*  <Newsletter/> */}
                {Footer2(bgCol)}
            </div>

            {discountAvailability && <Modal show={!show} onHide={handleClose}>
                {/* <Modal.Header closeButton> 
                </Modal.Header> */}
                <Modal.Body>
                    <div class='p-4 mx-auto my-auto col-md-12'>
                        <div className="p-1">
                            <div className='col-md-12 d-flex justify-content-center align-items' style={{ flexDirection: 'column' }}>
                                <div className='col-md-12 d-flex justify-content-center' style={{ width: '100%', flexDirection: 'column' }}>{/* 
                                    <h3 style={{ textAlign: 'center' }}>Download</h3>
                                    <h2 style={{ textAlign: 'center', color: 'green', marginTop: -10 }}>Wakanda Market App</h2> */}
                                    <h3 style={{ textAlign: 'center', marginTop: -5, color: 'green', fontWeight: 'bold' }}>Get N{discountAmt} discount on your first order from this seller!</h3>
                                    <img src={WakandaLogo} width='200' alt="wakanda-logo" className="mx-auto pt-4" />
                                    <p className='text-dark p-1' style={{ fontSize: 14, textAlign: 'center' }}>One App for Africa</p>
                                </div>

                                {!showDiscountInputBox && <Button variant="primary m-3 p-3" onClick={() => { setShowDiscountInputBox(true) }}
                                    style={{ backgroundColor: 'black', borderColor: 'black' }}>
                                    Get Now
                                </Button>}

                                <div style={{ width: '100%', paddingTop: 20 }} class='col-md-12'>
                                    {showDiscountInputBox && <InputGroup>
                                        <DropdownButton
                                            as={InputGroup.Prepend}
                                            variant="outline-secondary"
                                            title={areaCode}
                                            id="input-group-dropdown-1"
                                            onSelect={handleSelect}
                                        >
                                            <Dropdown.Item eventKey='+233'>+233</Dropdown.Item>
                                            <Dropdown.Item eventKey='+234'>+234</Dropdown.Item>
                                        </DropdownButton>
                                        <FormControl
                                            placeholder="Enter Your Phone Number 80..."
                                            aria-label="discount phone number entry"
                                            aria-describedby="basic-addon2"
                                            style={{}}
                                            class='outline text-center'
                                            disabled={registerNumberActivity}
                                            onChange={(e) => {
                                                let A = `${areaCode}${e.target.value}`
                                                setDiscountPhoneNumber(A)
                                                console.log('phone number set for discount is ', discountPhoneNumber)
                                            }}
                                        />
                                    </InputGroup>}

                                    {showDiscountInputBox && <div style={{ width: '100%' }} class='col-md-12 m-2 d-flex justify-content-center align-items'>
                                        {!registerNumberActivity && <Button variant="primary m-3 p-1" onClick={() => {
                                            setRegisterNumberActivity(true)
                                            getDiscount()
                                        }} block>Get Discount</Button>}
                                        {registerNumberActivity && <Spinner animation="grow" />}
                                    </div>}

                                    {discountMsg.length > 0 && <div class='mt-2'>
                                        <small class='text-success text-align-center'>{discountMsg}</small>
                                        <BrowserView>
                                            <div className='col-md-12 p-4' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', }}>

                                                <a href='https://play.google.com/store/apps/details?id=com.african_founders.Wakanda' target='_blank' className='col-md-6 col-sm-12 col-xs-12' style={{ textDecoration: 'none', width: '100%' }}>
                                                    <div className='col-md-12' style={{
                                                        backgroundImage: `url(${google})`,
                                                        backgroundPosition: 'center center',
                                                        backgroundRepeat: 'no-repeat',
                                                        //backgroundAttachment: 'fixed',
                                                        backgroundSize: '100%',
                                                        width: '100%',
                                                        height: 50,
                                                        borderRadius: 5
                                                    }}>
                                                    </div>
                                                </a>
                                                <a href='https://apps.apple.com/ng/app/wakanda-market/id1451309384' target='_blank' className='col-md-6 col-sm-12 col-xs-12' style={{ textDecoration: 'none', width: '100%' }}>
                                                    <div className='col-md-12' style={{
                                                        backgroundImage: `url(${apple})`,
                                                        backgroundPosition: 'center center',
                                                        backgroundRepeat: 'no-repeat',
                                                        //backgroundAttachment: 'fixed',
                                                        backgroundSize: '100%',
                                                        width: '100%',
                                                        height: 50,
                                                        borderRadius: 5
                                                    }}>
                                                    </div>
                                                </a>
                                            </div>
                                        </BrowserView>

                                        <MobileView>
                                            <div className='col-md-12 p-4' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                                                {(isAndroid) && <div className='col-md-6' style={{
                                                    backgroundImage: `url(${google})`,
                                                    backgroundPosition: 'center center',
                                                    backgroundRepeat: 'no-repeat',
                                                    //backgroundAttachment: 'fixed',
                                                    backgroundSize: '84%',
                                                    width: '50%',
                                                    height: 50,
                                                    borderRadius: 5
                                                }}>
                                                </div>}
                                                {(isIOS) && <div className='col-md-6' style={{
                                                    backgroundImage: `url(${apple})`,
                                                    backgroundPosition: 'center center',
                                                    backgroundRepeat: 'no-repeat',
                                                    //backgroundAttachment: 'fixed',
                                                    backgroundSize: '84%',
                                                    width: '50%',
                                                    height: 50,
                                                    borderRadius: 5
                                                }}>
                                                </div>}
                                            </div>
                                        </MobileView>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <div onClick={handleClose}
                    style={{
                        zIndex: 20, padding: 10, backgroundColor: 'red', top: -10, right: -10,
                        width: 50, height: 50, display: 'flex', justifyContent: 'center',
                        alignItems: 'center', borderRadius: 25, position: 'absolute'
                    }}>
                    <small style={{ color: 'white', fontWeight: 'bold' }}>X</small>
                </div>
            </Modal>}


        </div>
    )
}

export default JobDetails