import React from 'react'
import { JobCard } from '../utilityComponents'

import salesicon from '../../assets/sales.svg'
import workmanshipicon from '../../assets/worker (1).svg'
import technicalicon from '../../assets/it.svg'
import creativesicon from '../../assets/computer.svg'
import waitersicon from '../../assets/waiter.svg'
import teachersicon from '../../assets/teacher.svg'
import householdicon from '../../assets/housekeeper.svg'
import officeicon from '../../assets/money.svg'
import moneyicon from '../../assets/money.svg'
import othericon from '../../assets/plus.svg'

import ElementSlider from '../slider'



const jobs = [
    { name: 'Sales and Marketing', color: '#4160E0', image: salesicon }, { name: 'Physical Workmanship', color: '#F46E56', image: workmanshipicon },
    { name: 'Technical, IT', color: '#13C170', image: technicalicon }, { name: 'Creatives, Design, DJ, Entertainer', color: '#C43699' , image: creativesicon},
    { name: 'Waiters, Hostesses, Representatives', color: '#4DC5E2' , image: waitersicon},{ name: 'Teaching, Nanny, Nursing', color: '#4DC5E2', image: teachersicon }, { name: 'Household, Cooking, Errands', color: '#4DC5E2', image: householdicon },
    { name: 'Office and Admin', color: '#4DC5E2', image: officeicon },
]
const Jobs = (props) => {
    return (
        // <div class='container' >
        //     <div class='mt-2 container' style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly" }}>
            <ElementSlider dataAvailable={props.data || jobs} top={70} >
                {jobs.map((item, id) => {
                    return (
                        <div key={id} className="p-0 zoomoutin ">{JobCard(item, 160)}</div>
                    )
                })}
               </ElementSlider>
        //  </div> 
        //</div>
    )
}

export default Jobs