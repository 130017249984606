import React from 'react'
import Skeleton from '@yisheng90/react-loading';
import { Spinner } from 'react-bootstrap'
import { uniformImageHolder, displayprice } from './utilityComponents'
import './styles/provider.css'




const Product = (props) => {
    //console.log('product props passed is ', props.product)
    return (
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-start", padding: 0 }}>
            {props.productImage ? <div class='col-md-12' style={{ paddingLeft: 0 }}>
                {uniformImageHolder(props.productImage, 80, 80, '1px solid rgb(128,128,128,0.3)', 10)}
            </div>
                : <div class='col-md-12'
                    style={{
                        padding: 0,
                        borderRadius: 10,
                        marginTop: 10,
                        overflow: 'hidden'
                    }}>
                    <Skeleton width={80} height={80} />
                </div>}
            {displayprice(props.product.price,props.product.priceType, props.product.priceCurrency) || props.product.title ? <div class='col-md-12' style={{ paddingBottom: 20, paddingTop: 10, width: 80, paddingLeft: 5 }}>
                <div style={{ width: 80, marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                    <small class='text-truncate' style={{ fontSize: 12, }} ><b>{props.product.title || props.product.text || "..."}</b></small>
                </div>
                <div style={{ width: 80, marginTop: -5, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                    <small class='text-truncate' style={{ fontSize: 11, }} >{displayprice(props.product.price,props.product.priceType, props.product.priceCurrency)}</small>
                </div>
            </div> : <div class='col-md-12' style={{ paddingBottom: 30, paddingTop: 5, width: '100%', paddingLeft:0, alignSelf:'center' }}>
                    <Skeleton width={70} height={10} />
                </div>}
        </div>
    )
}

export default Product