import React, { useEffect, useState } from 'react'
import YouTube from 'react-youtube';
import { uniformImageHolder, displayprice } from './utilityComponents'
import Skeleton from '@yisheng90/react-loading';

import { Banner3, Banner4 } from './utilityComponents'
import { Link } from 'react-router-dom'
import './styles/provider.css'
import Footer from './Footer/footer'
import './styles/category.css'
import './Header/ProductHeader.css';
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
import banner1image from './images/BFtext.png'
import woman from './images/1.jpeg'
let chadwick = 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/chadwick.jpeg?alt=media&token=0b01b488-39b5-4c26-a42a-d18691f80b5f'
const marketing = [
    '- Sell your products and services',
    '- Use the Wakanda Wallet and Escrow Service',
    '- Get free business/e-commerce Website and a lot more…'
]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"

function getFaviconEl() {
    return document.getElementById("favicon");
}

const opts = {
    height: '300',
    width: '640',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
    },
};



const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
}

const BFComponent = (props) => {

    const handleIcon = () => {
        const favicon = getFaviconEl(); // Accessing favicon element
        favicon.href = chadwick;
    };

    useEffect(() => {
        handleIcon()

    }, [])

    return (
        <div style={{ backgroundColor: '#eee' }}>
            <div className="nw1-product-smaller-header" >
                <div className="row">
                    <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 190,
                            height: 45,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            paddingBottom: 20
                        }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                        </div>
                    </Link>
                </div>



            </div>

            <div style={{ minHeight: 100 }}>
                <div style={{ height: 45 }}>

                </div>
                <div class='mt-4' style={{ minHeight: 60, position: 'relative', }}>
                    {Banner3(banner1image, 'It’s time to celebrate the biggest sales and shopping event of the year', ' ', 'LEARN MORE', '#FFF')}
                </div>

            </div>
            <section style={{ marginBottom: 20 }} id='more'>
                <div class='container p-2 pb-4' style={{ backgroundColor: '#fff', fontFamily: "Raleway", fontSize: 13, lineHeight: "1.6em", display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 20, borderRadius: 10 }}>
                    {/* <div class='container p-4 text-center' style={{ minHeight: 100 }}>
                        <h5 style={{ color: '#000', fontWeight: 'regular' }}>Join the Wakanda family by simply submitting your deals, and be guaranteed more visibility to your store and products, and definitely more sales!</h5>
                    </div> */}
                    <div class='container p-4 pt-0' style={{ minHeight: 60, position: 'relative', marginTop: 0 }}>
                        {Banner4(woman, 'Join the Wakanda family by simply submitting your deals, and be guaranteed more visibility to your store and products, and definitely more sales!', '', 'GET STARTED', '#fff')}
                    </div>
                    <div class='p-2'>
                        <p style={{ fontSize: 16 }}>Click on the links below to find our comprehensive guide on how to make the most of the Black Friday HOLIDAY SALES.<br />￼</p>
                        <p style={{ fontSize: 16 }}>Submit your deals (discounted products and services), and watch your sales multiply many times during Black Friday!</p>
                        <p style={{ fontSize: 16 }}>￼<br />Follow WAKANDA&rsquo;s Sign Up Process, to guarantee the best experience for your customers.</p>
                        <p style={{ fontSize: 16 }}> ￼<br />Learn how to use Social Media and WhatsApp to increase your sales during the Black Friday HOLIDAY sales.</p>
                        <p style={{ fontSize: 16 }}>￼<br />Read up on the FAQs to avoid re-inventing the wheel.</p>
                        <p style={{ fontSize: 16 }}><br />￼<br />Learn How to Participate in the WAKANDA MARKET First-Time Buyer Discount for all your customers.</p>
                    </div>

                </div>
            </section>

            <section className='container-fluid m-2' style={{ backgroundColor: '#fff' }}>
                <div class="container mt-4" style={{ padding: 20 }}>
                    <h2 class='text-dark text-center' >
                        Watch Our Video Guides to Get started
                    </h2>
                </div>
                <div class='row container mx-auto' style={{}}>
                    <div className='px-3  px-md-4 col-md-6 text-white overflow-hidden'>
                        <YouTube videoId="eYOUattBDik" opts={opts} onReady={_onReady} />
                    </div>
                    <div className='px-3  px-md-4 col-md-6 text-white overflow-hidden'>
                        <YouTube videoId="yh6ioCokynY" opts={opts} onReady={_onReady} />
                    </div>
                </div>
                <div class='row container mx-auto' style={{}}>
                    <div className='px-3  px-md-4 col-md-6 text-white overflow-hidden'>
                        <YouTube videoId="8EhM16cKzWc" opts={opts} onReady={_onReady} />
                    </div>
                    <div className='px-3  px-md-4 col-md-6 text-white overflow-hidden'>
                        <YouTube videoId="igVBgQcXUug" opts={opts} onReady={_onReady} />
                    </div>
                    <div className='px-3  px-md-4 col-md-6 text-white overflow-hidden'>
                        <YouTube videoId="iIKY_QBX2GU" opts={opts} onReady={_onReady} />
                    </div>
                    <div className='px-3  px-md-4 col-md-6 text-white overflow-hidden'>
                        <YouTube videoId="lMi20BiN8JI" opts={opts} onReady={_onReady} />
                    </div>
                </div>

            </section>

            <Footer />
        </div>
    )
}

export default BFComponent