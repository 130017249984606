import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	uniformImageHolder,
	displayprice,
	Banner3,
	Banner4,
	Banner6,
} from './utilityComponents';
import Skeleton from '@yisheng90/react-loading';
import Slider from 'react-slick';
import './styles/provider.css';
import Footer from './Footer/footer';
import './styles/category.css';
import './Header/ProductHeader.css';
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg';
/* import banner1image from './images/BFtext.png' */
//import banner1image from './images/HS2.png'
import banner1image from '../assets/new-year-discounts.png';
import woman from './images/1.jpeg';

import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect';

import google from '../assets/google.png';
import apple from '../assets/Apple.png';
let chadwick =
	'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/chadwick.jpeg?alt=media&token=0b01b488-39b5-4c26-a42a-d18691f80b5f';
const marketing = [
	'- Sell your products and services',
	'- Use the Wakanda Wallet and Escrow Service',
	'- Get free business/e-commerce Website and a lot more…',
];

let url =
	'https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg';
let categories = [
	'Electronics',
	'Fashion',
	'Food',
	'Beauty',
	'Cars',
	'Health',
	'Handyman',
	'Appliances',
];
function getFaviconEl() {
	return document.getElementById('favicon');
}
const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

let locations = [
	{ name: 'Lagos', value: 'lagos' },
	{ name: 'Ibadan', value: 'ibadan' },
	{ name: 'Port Harcourt', value: 'portHarcourt' },
	{ name: 'Kaduna', value: 'kaduna' },
	{ name: 'Abeokuta', value: 'abeokuta' },
	{ name: 'Ghana', value: 'ghana' },
	{ name: 'Abuja', value: 'abuja' },
];

const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const BFComponent = (props) => {
	const [location, setLocation] = useState('lagos');
	const [products, setProducts] = useState([]);

	const handleIcon = () => {
		const favicon = getFaviconEl(); // Accessing favicon element
		favicon.href = chadwick;
	};

	const handleLocationChange = async (value) => {
		console.log('value in handleChange ', value, 'location is also ', location);
		//let a = value.split('#/')[1]
		await setLocation(value);
		await setProducts(productData);
	};

	const getLocationByIP = async () => {
		/* navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
        }) */

		fetch('https://ipapi.co/json/')
			.then((response) => response.json())
			.then((data) => {
				console.log('location based on ip of host is ', data);
				if (data.city === 'Lagos') {
					setLocation('lagos');
				} else if (data.city === 'Oyo') {
					setLocation('ibadan');
				} else if (data.city === 'Ibadan') {
					setLocation('ibadan');
				} else if (data.city === 'Port Harcourt') {
					setLocation('portHarcourt');
				} else if (data.city === 'Kaduna') {
					setLocation('kaduna');
				} else if (data.city === 'Abeokuta') {
					setLocation('abeokuta');
				} else if (data.country_name === 'Ghana') {
					setLocation('ghana');
					//setRedirect(true)
				} else if (data.city === 'Abuja') {
					setLocation('abuja');
				} else if (data.city === 'Abuja') {
					setLocation('abuja');
				} else {
					setLocation('lagos');
				}
				console.log('location from ip is ', location);
				fetchProducts();
			});
	};
	const fetchProducts = async () => {
		let a = CheckURL();
		console.log('a is', a);
		try {
			const r = await fetch(
				'https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet',
				{
					method: 'POST',
					//mode: "no-cors",
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						type: 'offers',
						authPhrase: 'A¶§Ô˝D3ßºƒn',
						amount: 1000,
						location:
							a === 'blackfridaygh' || a === 'holidaysalegh'
								? 'ghana'
								: location,
						//category1:'Electronics'
					}),
				}
			);
			const r2 = await r.json();
			//console.log('r2 offers fetched is ', r2)
			if (r2.msg == 'SUCCESS') {
				console.log('r2 is xxx', r2);
				/* setProducts(r2.finalResults) */
				setProducts(r2.discountOffers);
			} else {
				console.log('no custom URL detected... ', r2.msg);

				//this.fetchDataWithShopName()
				//this.setState({ hasCustomDomain: false, loading: false })
			}
		} catch (err) {
			console.log('Error from firebase using fetchdatawithurl is: ', err);
			//this.setState({ hasCustomDomain: false, redirect: true })
		}
	};

	const CheckURL = () => {
		try {
			const fullURL = window.location.href.split('#')[0];
			const fullURLsubStrings = fullURL.split('/');
			console.log(fullURLsubStrings);
			return fullURLsubStrings[fullURLsubStrings.length - 1].toLowerCase();
		} catch (err) {
			console.log('issues with parsing URL ', err);
		}
	};
	useEffect(() => {
		fetchProducts();
		//handleIcon()
	}, []);
	useEffect(() => {
		fetchProducts();
		//handleIcon()
	}, [location]);

	return (
		<div
			style={{
				backgroundColor: '#eee',
				overflowX: 'hidden',
				scrollBehavior: 'smooth',
			}}
		>
			<div className="nw1-product-smaller-header">
				<div className="row">
					<Link to="/" style={{ textDecoration: 'none', width: '100%' }}>
						<div
							style={{
								backgroundImage: `url(${WakandaLogoWhite})`,
								width: 190,
								height: 45,
								backgroundPosition: 'top left',
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								paddingBottom: 20,
							}}
							className="col-lg-3 col-sm-12 mt-2  mb-2"
						></div>
					</Link>
				</div>
			</div>

			<div style={{ minHeight: 100 }}>
				<div
					style={{
						backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/bg-bf.png?alt=media&token=8f51dd96-677a-4d69-92cd-41cd3dc39417')`,
						width: '100%',
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundRepeat: 'repeat',
						height: 200,
						marginTop: 50,
					}}
				></div>
			</div>

			<section style={{ marginBottom: 20, marginTop: -120 }} id="more">
				<div
					class="container p-2 pb-4"
					style={{
						backgroundColor: '#fff',
						fontSize: 13,
						lineHeight: '1.6em',
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						marginTop: 20,
						borderRadius: 10,
					}}
				>
					<div
						class="mt-4"
						style={{ minHeight: 60, position: 'relative', marginTop: -60 }}
					>
						{Banner6(
							banner1image,
							'2021 is over, 2022 is here! Welcome to a new season!',
							' ',
							'SHOP NOW',
							'#000'
						)}
					</div>
					<div
						class="container p-2 col-md-12 row d-flex justify-content-center "
						style={{ backgroundColor: '#F0F0F0' }}
					>
						<div class="ml-2 pt-1 col-md-1 d-flex  mr-2">
							<small>
								<b>Locations:</b>
							</small>
						</div>
						<div class="col-md-10 row d-flex justify-content-around ml-1">
							{locations.map((item, id) => {
								return (
									<button
										className="p-1 col-md-1 mx-1 my-1"
										key={id}
										style={{
											borderRadius: 3,
											textDecoration: 'none',
											backgroundColor:
												location === item.value ? '#00A85A' : '#D0D0D0',
											borderWidth: 0,
										}}
										onClick={() => {
											handleLocationChange(item.value);
										}}
									>
										<small
											style={{
												textDecoration: 'none',
												color: location === item.value ? 'white' : 'black',
											}}
										>
											{item.name === 'Port Harcourt' ? 'PH' : item.name}
										</small>
									</button>
								);
							})}
						</div>
					</div>
					<div class="d-flex justify-content-center mt-4 mb-4">
						<div class="container mt-4 row ">
							{products.map((item, id) => {
								return (
									<div
										class="col-md-3 col-sm-6 py-2 p-1 p-sm-2"
										style={{ width: '50%' }}
										key={id}
									>
										<div
											class="shadow-sm p-0 m-0 pt-0 zoomin"
											style={{
												border: '1px solid rgba(100,100,100,0.1)',
												borderRadius: 10,
											}}
										>
											{item.pictureURL ? (
												<div
													class="col-md-12 p-0 pt-0 "
													style={{
														marginTop: -10,
														overflowX: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{uniformImageHolder(
														item.pictureURL || url,
														'100%',
														'10rem',
														'',
														10
													)}
												</div>
											) : (
												<Skeleton width={'100%'} height={'13rem'} />
											)}

											<div
												class="col-md-12 "
												style={{
													padding: 20,
													paddingBottom: 20,
													paddingTop: 0,
													width: '100%',
													position: 'relative',
												}}
											>
												<div
													style={{
														height: 50,
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														marginTop: 20,
														paddingBottom: 10,
														minHeight: 70,
													}}
												>
													<div
														style={{
															width: '100%',
															overflowX: 'hidden',
															textOverflow: 'ellipsis',
														}}
													>
														<small
															class="text-truncate"
															style={{ fontSize: 14 }}
														>
															{item.title || '...'}
														</small>
													</div>
													{/*  <div style={{ width: "100%", marginTop: -5, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                        <small class='text-truncate' style={{ fontSize: 15, }} ><b>{displayprice(item.price, item.priceType, item.priceCurrency) || "..."}</b></small>
                                                    </div>
                                                    {item.hasOwnProperty('postPriceDiscount') && <div style={{ width: "100%", marginTop: -5, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                        <small class='text-truncate text-success' style={{ fontSize: 14, }} ><b>{`${item.priceCurrency == 'NGN' ? '₦' : '₵'}${item.postPriceDiscount} OFF` || "..."}</b></small>
                                                    </div>} */}
													<div
														className="d-flex flex-row align-items-center "
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
														}}
													>
														{((item.hasOwnProperty('postPriceDiscount') &&
															item.postPriceDiscount <= 0) ||
															!item.hasOwnProperty('postPriceDiscount')) && (
															<small
																class="text-truncate"
																style={{ fontSize: 13 }}
															>
																<b>
																	{displayprice(
																		item.price,
																		item.priceType,
																		item.priceCurrency
																	) || '...'}
																</b>
															</small>
														)}
														{item.hasOwnProperty('postPriceDiscount') &&
															item.postPriceDiscount > 0 && (
																<small
																	style={{ color: 'black', fontWeight: 'bold' }}
																	className="text-truncate"
																>
																	<strike>
																		{displayprice(
																			item.price,
																			item.priceType,
																			item.priceCurrency
																		) || '...'}
																	</strike>
																</small>
															)}
														{item.hasOwnProperty('postPriceDiscount') &&
															item.postPriceDiscount > 0 && (
																<small
																	class="text-truncate ml-2"
																	style={{ fontSize: 13, color: '#FF1E78' }}
																>
																	<b>
																		{item.priceCurrency === 'NGN' ? '₦' : '₵'}
																		{item.price - item.postPriceDiscount ||
																			'...'}
																	</b>
																</small>
															)}
													</div>
												</div>
												<div
													style={{
														width: '100%',
														marginTop: -5,
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													<div style={{ height: 5, marginTop: -15 }}>
														<hr />
													</div>
													<small
														class="text-truncate col-md-12  pl-0"
														style={{ fontSize: 11 }}
													>
														sold by{' '}
														<span class="text-success text-truncate">
															{item.profileNameBusiness || '...'}
														</span>
													</small>
													{(item.pictureURL ||
														item.title ||
														item.profileNameBusiness) && (
														<Link
															to={`/${item.providerID}/${item.xID}`}
															target="_blank"
															style={{ textDecoration: 'none' }}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'row',
																	width: '100%',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<button
																	type="button"
																	class="btn btn-lg btn-inline btn-success px-2 row mt-2 d-flex justify-content-center align-items-center"
																	style={{
																		width: '100%',
																		backgroundColor: 'black',
																		borderWidth: 0,
																	}}
																>
																	<span className="mx-2">
																		<small
																			style={{ fontSize: 12 }}
																			class="text-truncate"
																		>
																			Shop Now<span></span>
																		</small>
																	</span>
																</button>
															</div>
														</Link>
													)}
												</div>
											</div>
											{item.hasOwnProperty('postPriceDiscount') &&
												item.postPriceDiscount > 0 && (
													<div
														style={{
															position: 'absolute',
															top: 20,
															right: 20,
															backgroundColor: '#FF1E78',
															padding: 5,
															paddingBottom: 0,
															paddingTop: 0,
															borderRadius: 10,
														}}
													>
														<small
															style={{
																color: 'white',
																fontWeight: 'bold',
																fontSize: 10,
																marginTop: -20,
															}}
														>
															{Math.floor(
																(item.postPriceDiscount / item.price) * 100
															)}
															<i>{'% off'}</i>
														</small>
													</div>
												)}
											<div
												style={{
													backgroundImage: `url(${banner1image})`,
													width: 60,
													backgroundSize: 'contain',
													backgroundPosition: 'center center',
													backgroundRepeat: 'no-repeat',
													height: 60,
													position: 'absolute',
													top: 10,
													left: 20,
												}}
											></div>
										</div>
									</div>
								);
							})}
						</div>
					</div>

					<div class="container mt-4" style={{ padding: 20 }}>
						<div
							class="rainbow"
							style={{
								alignItems: 'center',
								backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/bf-bgroun-grey.jpg?alt=media&token=62d8f974-7d05-4db4-9395-e473692ce980)`,
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								backgroundRepeat: 'no-repeat',
								//backgroundColor:' #cccccc'
							}}
						>
							<div class="container d-md-flex flex-md-equal w-100  align-items-center pl-md-3">
								<div
									class="  px-md-5 text-center overflow-hidden mt-4  align-items-center"
									style={{
										backgroundImage:
											`url(https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/bf-balloons.png?alt=media&token=2a584241-3a27-41e9-b7af-669910d349ee)` ||
											'',
										backgroundSize: 'contain',
										backgroundPosition: 'center center',
										backgroundRepeat: 'no-repeat',
										height: 200,
									}}
								>
									<div
										class=" box-shadow mx-auto"
										style={{ width: '80vw', borderRadius: '21px 21px 0 0' }}
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="mt-4 text-center" id="download">
				<div className="container">
					<div className="row">
						<div className="col-md-8 mx-auto text-center ">
							<h2 className="section-heading" style={{ color: 'black' }}>
								{'Download Wakanda Market App'}
							</h2>
							<p style={{ color: 'black' }}>
								{'Signup and get great discounts today!'}
							</p>
							{/* <div className="badge row col-md-12">
                                <a className="badge-link col-md-5 col-sm-12" href="https://itunes.apple.com/ng/app/wakanda-market/id1451309384?mt=8" target="_blank"  ><img class="animated bounceInUp" src={iosDownload} style={{ width: '200px' }} alt="" /></a>
                                <a className="badge-link col-md-5 col-sm-12" href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target="_blank" ><img class="animated bounceInUp" src={googleDownload} style={{ width: '200px' }} alt="" /></a>
                            </div> */}
							<BrowserView>
								<div
									className="col-md-12 p-4 text-center d-flex align-items-center justify-content-center"
									style={{
										flexDirection: 'row',
										display: 'flex',
										justifyContent: 'space-evenly',
									}}
								>
									<a
										href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda"
										target="_blank"
										className="col-md-5 col-sm-12 col-xs-12"
										style={{ textDecoration: 'none', width: '100%' }}
									>
										<div
											className="col-md-12"
											style={{
												backgroundImage: `url(${google})`,
												backgroundPosition: 'center center',
												backgroundRepeat: 'no-repeat',
												//backgroundAttachment: 'fixed',
												backgroundSize: 'contain',
												width: '100%',
												height: 90,
												borderRadius: 5,
											}}
										></div>
									</a>
									<a
										href="https://apps.apple.com/ng/app/wakanda-market/id1451309384"
										target="_blank"
										className="col-md-5 col-sm-12 col-xs-12"
										style={{ textDecoration: 'none', width: '100%' }}
									>
										<div
											className="col-md-12"
											style={{
												backgroundImage: `url(${apple})`,
												backgroundPosition: 'center center',
												backgroundRepeat: 'no-repeat',
												//backgroundAttachment: 'fixed',
												backgroundSize: 'contain',
												width: '100%',
												height: 90,
												borderRadius: 5,
											}}
										></div>
									</a>
								</div>
							</BrowserView>

							<MobileView>
								<div
									className="col-md-12 p-4"
									style={{
										flexDirection: 'row',
										display: 'flex',
										justifyContent: 'space-evenly',
										width: '100%',
									}}
								>
									{isAndroid && (
										<div
											className="col-md-6"
											style={{
												backgroundImage: `url(${google})`,
												backgroundPosition: 'center center',
												backgroundRepeat: 'no-repeat',
												//backgroundAttachment: 'fixed',
												backgroundSize: 'contain',
												width: '50%',
												height: 50,
												borderRadius: 5,
											}}
										></div>
									)}
									{isIOS && (
										<div
											className="col-md-6"
											style={{
												backgroundImage: `url(${apple})`,
												backgroundPosition: 'center center',
												backgroundRepeat: 'no-repeat',
												//backgroundAttachment: 'fixed',
												backgroundSize: 'contain',
												width: '50%',
												height: 50,
												borderRadius: 5,
											}}
										></div>
									)}
								</div>
							</MobileView>
						</div>
					</div>
				</div>
			</section>
			<section
				className="container-fluid m-2"
				style={{ backgroundColor: '#fff' }}
			>
				<div class="container mt-4" style={{ padding: 20 }}>
					<div
						class="rainbow"
						style={{
							alignItems: 'center',
							padding: 20,
							paddingTop: 50,
							backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/bf-bground.jpg?alt=media&token=4e21e754-fd45-4699-9a6f-f491c38e150e)`,
							backgroundSize: 'cover',
							backgroundPosition: 'center center',
							backgroundRepeat: 'repeat',
							//backgroundColor:' #cccccc'
						}}
					>
						<div class="container d-md-flex flex-md-equal w-100  align-items-center pl-md-3">
							<div
								class="  px-md-5 text-center overflow-hidden mt-4  align-items-center"
								style={{
									backgroundImage:
										`url(https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/supersale.jpg?alt=media&token=69ca2285-618a-4cb4-8f9a-92b9a88bef6d)` ||
										'',
									backgroundSize: 'contain',
									backgroundPosition: 'center center',
									backgroundRepeat: 'no-repeat',
									height: 200,
									marginBottom: 60,
								}}
							>
								<div
									class="box-shadow mx-auto"
									style={{ width: '80vw', borderRadius: '21px 21px 0 0' }}
								></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
};

export default BFComponent;
