import React, { useEffect, useState } from 'react'
import './newsletter.css'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const Newsletter = () => {

  const [email, setEmail] = useState("")
  const [status, setStatus] = useState()
  const [user, setUser] = useState()


  let token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjA4YTc1ZWZjMWFiYjQxNWI5OGJlZGQ1MTYxMWIxZTBmM2EzZTliZTNlMTgxZTdmMGMzZjg4ODI5OGZjOWUyMDI0ZDY2YWVlMTFkMmU4MDVlIn0.eyJhdWQiOiI0IiwianRpIjoiMDhhNzVlZmMxYWJiNDE1Yjk4YmVkZDUxNjExYjFlMGYzYTNlOWJlM2UxODFlN2YwYzNmODg4Mjk4ZmM5ZTIwMjRkNjZhZWUxMWQyZTgwNWUiLCJpYXQiOjE2MDIwNzIxMDYsIm5iZiI6MTYwMjA3MjEwNiwiZXhwIjoxNjMzNjA4MTA2LCJzdWIiOiIzMjMyOCIsInNjb3BlcyI6W119.Z2n2TgoeX0Tkb4FRkX0cvAXuZug0ltGAFnKpzcxFGMUQ6PEa-MSNIKNvF0jKVMCJMHxC1pEKXaxfzoYm8Cp4ZZ1ClTWhanHza_v8OX7Y8yFlyF5aWUDline5gtUHyUFRZ4fxxjvQ6hfvWL4luOZVhoPwkvgxP71dAZan6vVcw-JVjZbXngMXtPed1lpmU4ul4QS2FfmcOm0o8ePq7Wp4hDwNHsrFEGSjLSYQPKdL35HGsL5CJzBSxiqx6bJwTGOcD5PlobXtO_m51pntmts5V2IY-7P9V8ac2ejKaSD9Kw8fRAWS4veDonlVJFV4OubGfJwkXVwT2LGCC4BT-Wxc79Gy72zZMbkeQOanxHfFWu0zO-jD99F72gVWsH_D3zZOAh_1uWt1EcBhEKsnXPCK642yxBPnkGL0f6gtcbF__exZOVQwzhtDP7SWuW9m57mfgp1knzTlQKhV4NScG8lF1yzJvnYA2AM9c85m_jzi8L7x3BYzRjG_4_KJa6IrshUdz8_v1YIv6nNPXq2r865obYeZE0qgy4QlQeis5f0rT4nXbhQ4M2OusZ-ClmzfvUtabXuw8YV7f87bfzSV_rKG0M-ul8g2ApuLPDjgHKGgCPOHpimjQzAAGEcBxP34rHXJ7I05C_G1yhWyY0d0ejz2AQehgUo2JV8oANMd3Hlqemk'

  // const subscribe = async (event) => {
  //   // Use your Sendfox List ID  
  //   event.preventDefault();
  //   try {
      
  //     const a = await fetch('https://cors-anywhere.herokuapp.com/https://api.sendfox.com/contacts',{  
  //       method: 'POST', 
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: token,
          
  //         // 'X-API-KEY':apiKey 
  //       },
  //       body: JSON.stringify({
  //         "email":email,
  //         "lists": "170505"
  //       })
  //     })
  //    const res = await a.json()   
  //     console.log('response: ', res);
  //     if(res.email == email)setStatus(true)
  //     if(res.email !== email)setStatus(false)
      
  //   }
  //   catch (error) {
  //     console.log('error: ', error);
  //   };
    

  // }

  const subscribe = async (event) => {
    // Use your Sendfox List ID  
    event.preventDefault();
    try {

      //Email Input validation
      if (typeof email !== undefined){
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(!pattern.test(email)){
          setStatus(false)
        }
      }
      
      const a = await fetch('https://us-central1-af-shop.cloudfunctions.net/emailSubscription',{  
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          //Authorization: token,   
          // 'X-API-KEY':apiKey 
        },
        body: JSON.stringify({
          "email":email,
          "lists": "170505"
        })
      })
     const res = await a.json()   
      console.log('response: ', res);
      if(res.msg == 'SUCCESS')setStatus(true)
   
      
    }
    catch (error) {
      console.log('error: ', error);
    };
    
  }


  // const subscribe =  (event) => {
  //   // Use your Sendfox List ID  
  //   event.preventDefault();
  //   fetch({
  //     url: 'https://api.sendfox.com/contacts',
  //     body: JSON.stringify(email),
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: apiKey
  //     }})
  //     .then( response  => response.json())
  //     .then(data => console.log(data));
  // }

  return (
    <div className=" mt-4 text-center" style={{ backgroundColor: '#F5F5F5', paddingLeft: 10, paddingRight: 10, paddingBottom: 30, paddingTop: 30 }} >
      <form class="form-signin" onSubmit={subscribe} noValidate>
        <input type="hidden" name="u" value="a7917ecc9f3435b71a66bfe0f" />
        <input type="hidden" name="id" value="f3538b9f8b" />
        <div class="text-center mx-auto" style={{ width: '90%' }}>
          <h1 class="h3 mb-3 font-weight-normal" style={{ color: '#00A85A' }}>Join Our Mailing List</h1>
          <p style={{ fontSize: 12 }}>Subscribe to our Newsletter to  receive the latest offers directly in your inbox</p>
        </div>
        <div class="form-label-group row mx-auto newsletter" style={{ textAlign: 'center', padding: 20 }}>

          <input type="email" name="Email" id="inputEmail" pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" size="30" required class=" form-control m-0 col-md-10 mx-auto news " value={email}
            onChange={(e) => { setEmail(e.target.value); console.log(' email entered is ', email) }} autoCapitalize="off"
            autoCorrect="off" style={{ height: 50, fontSize: 14, textAlign: 'center', outline: 'none' }} placeholder="Enter your email"  />
          <button 
          //onClick={(e) => { subscribe(e) }} 
          class="btn btn-sm btn-inline  col-md-2 ml-sm-0 ml-md-n4 my-sm-2 my-lg-0" style={{ backgroundColor: '#00A85A', color: 'white', fontWeight: 'bold', }} type="submit">JOIN</button>

        </div>
        {status && <p style={{textAlign:'center', color: '#00A85A'}}><small>Successfully Subscribed!</small></p>}
        {status == false && <p style={{textAlign:'center', color: 'red'}}><small>Invalid Email!</small></p>}

      </form>
    </div>
  )
}

export default Newsletter
