import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from '../utilityComponents'
import Skeleton from '@yisheng90/react-loading';

import { Link } from 'react-router-dom'
import '../styles/provider.css'
import Footer from '../Footer/footer'
//import Product from  './product1'
//import heart from '../assets/heart4.svg'
import '../styles/category.css'
import '../Header/ProductHeader.css';
////import rectangle from '../assets/Rectangle.png'
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
//import searchIcon from '../assets/searchIcon.svg'
//import map from '../assets/map.svg'
import electronics from '../../assets/Electronics.svg'
import fashion from '../../assets/fashion.jpg'
import food from '../../assets/food.jpg'
import beauty from '../../assets/beauty.jpg'
import wkwallets from '../../assets/Wallets2.jpg'

import { Header, Footer2 } from '../utilityComponents'
// import dropdown from '../assets/dropd.svg'
// import electronicsIcon from '../assets/Electronics.svg'
// import fashionIcon from '../assets/fashion.svg'
// import foodIcon from '../assets/Food.svg'
// import healthIcon from '../assets/Health.svg'


let chadwick = 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/chadwick.jpeg?alt=media&token=0b01b488-39b5-4c26-a42a-d18691f80b5f'
const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"/* 
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" */

let bgCol = '#C43699'

let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]
let CHOSEN_LOCATION = ''
let categories = [
    { name: 'Electronics', 'image': electronics, select: 'Phones' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Food', 'image': food, select: 'Fresh Food' },
    { name: 'Beauty', 'image': beauty, select: 'Hair' },
    { name: 'Fashion', 'image': fashion, select: 'Shoes' },
    { name: 'Cars', 'image': fashion, select: 'New Cars' },
    { name: 'Health', 'image': fashion, select: 'Pharmacies' },
    { name: 'Handyman', 'image': fashion, select: 'Plumbing' },
    { name: 'Appliances', 'image': fashion, select: 'Appliances' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Electronics', 'image': electronics, select: 'Tablets' },

]

export default function wakandawallet() {
    return (
        <div>
            <div>
                {Header(WakandaLogoWhite, bgCol)}
                <div className=' col-md-8 p-2' style={{ marginTop: 50 }}>
                </div>
                <div class='' style={{ fontFamily: "Raleway", fontSize: 13, lineHeight: "1.6em", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{
                        backgroundImage: `url(${wkwallets})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "cover",
                        backgroundPosition: "50% 15%",
                        backgroundAttachment: "scroll",
                        width: "100%",
                        height: 500,
                    }} className=" mb-4">
                        {/* <div>
                            <h1 className='display-3' style={{ marginTop: 120, marginLeft: 480, color: '#C43699' }}>Wakanda Wallets</h1>
                        </div> */}
                    </div>


                    <p style={{ fontSize: 20, lineHeight: 1.9, width: '60%' }}>Our Wakanda wallet is our way of ensuring you join the cashless society and operate as you should daily without fear of the unknown. To use the Wakanda wallet, simply follow these few steps below;
<ul className='p-4'>
                            <li><em>Click on the Alphabet A with naira lines on it.</em></li>
                            <li><em>Choose "Fund" to add money from your normal account to your Wakanda account. </em></li>
                            <li><em>Click on pay</em></li>
                            <li><em>It displays sellers on the Wakanda market app</em></li>
                            <li><em>Click on your seller's name</em></li>
                            <li><em>Fill in your details and click on "pay now"</em></li>
                        </ul>

                    You just successfully paid for a product or service in these few steps using your Wakanda wallet. Payment can also be done via bank transfer and in very few steps too.


                    <ul className='p-4'>
                            <li><em>Click on the three horizontal dots at the top on the right
</em></li>
                            <li>Click on "pay to bank" </li>
                            <li>Click on pay</li>
                            <li>It displays a long list of banks. Click on your bank</li>
                            <li>Fill in bank details and click on pay now.</li>
                        </ul>


                    You must think this is easier than you thought, you wouldn't be wrong thinking like that.

</p>
                    <h3 className='font-weight-bold'>Escrow</h3>
                    <p style={{ fontSize: 20, lineHeight: 1.9, width: '60%' }}>

                        The escrow is a good way to control mishaps on the app. We do not want scandals on the app, so this solution just seems fine. <br />It helps safeguard payment arrangements until the transaction is successful to avoid cases of fraud arising. We trust our sellers but certain measures are necessary to keep the wheel rolling. <br />The escrow us either "for" you or by "you". It simply means, the amount you sent out for payment is by you and the amount you are expecting for payment is for you.

</p>

                </div>
                <div style={{ height: '60vh' }}></div>
                {Footer2(bgCol)}

            </div>
        </div>
    )
}
