import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from './utilityComponents'
import Skeleton from '@yisheng90/react-loading';

import { Link } from 'react-router-dom'
import './styles/provider.css'
import Footer from './Footer/footer'
//import Product from  './product1'
import heart from '../assets/heart4.svg'
import './styles/category.css'
import './Header/ProductHeader.css';
import rectangle from '../assets/Rectangle.png'
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
import searchIcon from '../assets/searchIcon.svg'
import map from '../assets/map.svg'
import electronics from '../assets/electronics.jpg'
import fashion from '../assets/fashion.jpg'
import food from '../assets/food.jpg'
import beauty from '../assets/beauty.jpg'
import dropdown from '../assets/dropd.svg'
import electronicsIcon from '../assets/Electronics.svg'
import fashionIcon from '../assets/fashion.svg'
import foodIcon from '../assets/Food.svg'
import healthIcon from '../assets/Health.svg'


let chadwick = 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/chadwick.jpeg?alt=media&token=0b01b488-39b5-4c26-a42a-d18691f80b5f'
const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"/* 
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" */


let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]
let CHOSEN_LOCATION = ''
let categories = [
    { name: 'Electronics', 'image': electronics, select: 'Phones' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Food', 'image': food, select: 'Fresh Food' },
    { name: 'Beauty', 'image': beauty, select: 'Hair' },
    { name: 'Fashion', 'image': fashion, select: 'Shoes' },
    { name: 'Cars', 'image': fashion, select: 'New Cars' },
    { name: 'Health', 'image': fashion, select: 'Pharmacies' },
    { name: 'Handyman', 'image': fashion, select: 'Plumbing' },
    { name: 'Appliances', 'image': fashion, select: 'Appliances' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Electronics', 'image': electronics, select: 'Tablets' },

]

function getFaviconEl() {
    return document.getElementById("favicon");
}

const Chadwick = (props) => {

    const handleIcon = () => {
        const favicon = getFaviconEl(); // Accessing favicon element
        favicon.href = chadwick;
    };
    
    useEffect(() => {
        handleIcon()

    }, [])

    return (
        <div>
            <div className="nw1-product-smaller-header" >
                <div className="row">
                    <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 190,
                            height: 45,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            paddingBottom: 20
                        }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                        </div>
                    </Link>
                </div>



            </div>
            <div className='container col-md-8 p-2' style={{ marginTop: 100 }}>
                <div classnNme='container' style={{
                    backgroundImage: `url(${chadwick})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    //backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '100vh',
                    borderRadius: 5
                }}>
                    <div classnNme='container' style={{
                        backgroundImage: `url(${chadwick})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        //backgroundAttachment: 'fixed',
                        backgroundSize: 'cover',
                        width: '100%',
                        height: '100vh',
                        borderRadius: 5,
                        alignSelf: 'center'
                    }}>
                        <div className='col-md-12 ' style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', borderradius: 5, bottom: 0 }}>
                            <h4 className="modal-title " style={{ color: '#FFD700', fontFamily: 'Libre Baskerville', }}>Gone Too Soon</h4>
                            <h5 className="modal-title " style={{ color: '#F2F2F2', fontFamily: 'Open Sans' }}>R.I.P Chadwick Boseman</h5>
                            <h6 className="modal-title " style={{ color: '#F2F2F2', fontFamily: 'Open Sans' }}>1976 - 2020</h6>
                        </div>
                    </div>


                </div>

            </div>
            <div class='p-2' style={{ fontFamily: "Raleway", fontSize: 13, lineHeight: "1.6em", display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
                <p>The world calls you Chadwick,</p>
                <p>We say you are our T'challa,</p>
                <p>The black panther on whose shoulders Wakanda rest</p>
                <p> And in reminisce, we recall your wisdom in words;</p>
                <p>"In my culture, death is not the end, it's more</p>
                <p> of a stepping-off point you reach with both hands</p>
                <p>  and Bask and Sekmet, they lead you into a green veld</p>
                <p>  where...you can run forever."</p>
                <p>  So now in the depth of our thoughts,</p>
                <p>  We do not chant as the world, saying "rest in peace",</p>
                <p>  But for us, we hail our T'challa and say run with speed!</p>
                <p>  For as long as you run, Wakanda Lives.</p>
                <p>  Wakanda Forever!</p>
            </div>
            <Footer />
        </div>
    )
}

export default Chadwick