import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Fade from 'react-bootstrap/Fade'
import Animate from 'animate.css-react'
import 'animate.css/animate.css'
import '../styles/animate.css'
import './header.css';
import WakandaHand from '../../assets/Wakanda-Hand2.png'
import WakandaHandGroup from '../../assets/wakandagroup2.png'
import WakandaHandGroupMobile from '../../assets/wakandagroup.png'
import WakandaLogo from '../../assets/Wakanda-Logo.png'
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
import WakandaHealthLogo from '../../assets/Wakanda-Health.png'
import WakandaJobsLogo from '../../assets/Wakanda-Jobs.png'
import WakandaAcademyLogo from '../../assets/Wakanda-Academy.png'
import WakandaEventsLogo from '../../assets/Wakanda-events.png'
import WakandaWalletsLogo from '../../assets/Wakanda-Wallets.png'
import searchIcon from '../../assets/searchIcon.svg'
import map from '../../assets/map.svg'
import electronics from '../../assets/electronics.jpg'
import fashion from '../../assets/fashion.jpg'
import food from '../../assets/food.jpg'
import beauty from '../../assets/beauty.jpg'
import dropdown from '../../assets/dropd.svg'
import electronicsIcon from '../../assets/Electronics.svg'
import fashionIcon from '../../assets/fashion.svg'
import foodIcon from '../../assets/Food.svg'
import healthIcon from '../../assets/Health.svg'
import more from '../../assets/ios-more.svg'
import google from '../../assets/google.png'
import apple from '../../assets/Apple.png'
import Providers from '../Provider/providers'
import imageData from '../imageData'

import {
    isSafari, CustomView, browserName
} from "react-device-detect";




let categories = [

    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Food', 'image': food, select: 'Fresh Food' },
    { name: 'Beauty', 'image': beauty, select: 'Hair' },
    { name: 'Electronics', 'image': electronics, select: 'Phones' },
    { name: 'Cars', 'image': fashion, select: 'New Cars' },
    { name: 'Health', 'image': fashion, select: 'Pharmacies' },
    { name: 'Handyman', 'image': fashion, select: 'Plumbing' },
    // { name: 'Appliances', 'image': fashion, select: 'Appliances' },


]


let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]

let SEARCH = ''

function Header(props) {
    const [open, setOpen] = useState(false);
    const [location, setLocation] = useState(props.location)
    const [categoryImages, setCategoryImages] = useState(imageData.images)
    const [searchTerm, setSearchTerm] = useState('')
    //console.log('categoryimages are', categoryImages)

    const getLocationByIP = async () => {
        /* navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
        }) */

        fetch("https://ipapi.co/json/")
            .then(response => response.json())
            .then(data => {
                console.log('location based on ip of host is ', data)
                if (data.city === 'Lagos') {
                    setLocation('lagos')
                    props.changelocation('lagos')
                } else if (data.city === 'Oyo') {
                    setLocation('ibadan')
                    props.changelocation('ibadan')
                } else if (data.city === 'Ibadan') {
                    setLocation('ibadan')
                    props.changelocation('ibadan')
                } else if (data.city === 'Port Harcourt') {
                    setLocation('portHarcourt')
                    props.changelocation('portHarcourt')
                } else if (data.city === 'Kaduna') {
                    setLocation('kaduna')
                    props.changelocation('kaduna')
                } else if (data.city === 'Abeokuta') {
                    setLocation('abeokuta')
                    props.changelocation('abeokuta')
                } else if (data.city === 'Abuja') {
                    setLocation('abuja')
                    props.changelocation('abuja')
                } else if (data.country_name === 'Ghana') {
                    setLocation('ghana')
                    props.changelocation('ghana')
                } else {
                    setLocation('lagos')
                    props.changelocation('lagos')
                }
                console.log('location from ip is ', location)
            })
    }

    const handleChange = async (event) => {
        console.log('event in handleChange ', event.target.value)
        setLocation(event.target.value)
        await props.changelocation(event.target.value)
        await props.searchforstuff(SEARCH)
    };

    const CheckURL = () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            return fullURLsubStrings[fullURLsubStrings.length - 1].toLowerCase()

        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }

    useEffect(() => {
        setOpen(!open)
        //getLocationByIP()
    }, [])

    useEffect(() => {
        getLocationByIP()
    }, [])

    useEffect(() => {
        setSearchTerm("")
    }, [props.cancelSearch === ""])
    /* useEffect(() => {
        setLocation(props.location)
    }, [props.location]) */

    return (
        <div className=''>
            <div class='bg-white ' >
                <div className="nw1-bigger-header overflow-hidden" style={{}}>
                    <div className="row " >
                        <div className="col-md-3 d-sm-none d-md-block"></div>
                        <div className="col-md-9 d-none d-sm-block pt-2" >
                            <CustomView condition={browserName === "Safari"}>
                                <div className="row" style={{ marginTop: 15, marginLeft: 500 }}>
                                    <div className='col-md-6 d-flex flex-column animated animate_backInLeft animate_slower' style={{}}>
                                        <img src={WakandaLogo} width='80' alt="wakanda-logo" className="mb-0 pt-4" />
                                        <p className='text-dark ' style={{ fontSize: 10 }}>One App for Africa</p>
                                        <a href='https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'><img src={google} width='100' alt="wakanda-logo" className="mb-n1" /></a>
                                        <a href='https://apps.apple.com/ng/app/wakanda-market/id1451309384'> <img src={apple} width='100' alt="wakanda-logo" className=" mb-2 " /></a>
                                    </div>

                                    <Fade in={open} timeout={1000}>
                                        <div className='col-md-6 ml-auto animated animate__backInRight' style={{}} >
                                            <img src={WakandaHand} width='320' alt="wakanda-logo" className=" " />
                                        </div>
                                    </Fade>
                                </div>
                            </CustomView>

                            <CustomView condition={browserName === "Chrome"}>
                                <div className="row" style={{ marginTop: 95, marginLeft: 500 }}>
                                    <div className='col-md-6 d-flex flex-column animated animate_backInLeft animate_slower' style={{}}>
                                        <img src={WakandaLogo} width='80' alt="wakanda-logo" className="mb-0 pt-4" />
                                        <p className='text-dark ' style={{ fontSize: 10 }}>One App for Africa</p>
                                        <a href='https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'><img src={google} width='100' alt="wakanda-logo" className="mb-n1" /></a>
                                        <a href='https://apps.apple.com/ng/app/wakanda-market/id1451309384'><img src={apple} width='100' alt="wakanda-logo" className=" mb-2 " /></a>
                                    </div>

                                    <Fade in={open} timeout={1000}>
                                        <div className='col-md-6 ml-auto animated animate__backInRight' style={{}} >
                                            <img src={WakandaHand} width='320' alt="wakanda-logo" className=" " />
                                        </div>
                                    </Fade>
                                </div>
                            </CustomView>

                        </div>
                    </div>
                    {/* Mobile Screen */}
                    <div className=" d-sm-block d-md-none pb-2  animated animate__backInUp">
                        <div className="" style={{ textAlign: 'center', marginRight: 265, marginTop: 70 }}>
                            <img src={WakandaLogo} width='100' alt="wakanda-hand" className="pl-4 " /></div>
                        <p style={{ fontSize: 14, color: 'black', marginTop: 10, marginBottom: 60 }}>One App for Africa </p>
                        <div className="row">
                            <div style={{ width: 120, marginTop: 35, marginLeft: -60, paddingRight: 50 }}>
                                <img className="img-responsive ml-n4 mb-2" width='100' src={google}></img>
                                <img className="img-responsive ml-n4" width='100' src={apple}></img>
                            </div>
                            <div className="col-sm-6" style={{ marginTop: -150, marginLeft: 60 }}><img src={WakandaHand} width='220' alt="wakanda-hand" className=" " /></div>
                        </div>

                    </div>
                    {/* <div className="row" style={{  marginRight: 250,  }}>
                            <a href='https://play.google.com/store/apps/details?id=com.african_founders.Wakanda'><img src={google} width='80' alt="wakanda-logo" className="d-block  " /></a>
                            <a href='https://apps.apple.com/ng/app/wakanda-market/id1451309384'> <img src={apple} width='80' alt="wakanda-logo" className="d-block  " /></a>
                   <img src={WakandaHand} width='220' alt="wakanda-hand" className=" " />
                    </div> */}
                    {/* <div className='row' style={{}}>
                        <div className="col-sm-6">
                        <img class="img-responsive" width='80' src={google}></img>
                        <img class="img-responsive" width='80' src={apple}></img>
                        </div>
                        <div className="col-sm-6"><img src={WakandaHand} width='220' alt="wakanda-hand" className=" " /></div>
                    </div> */}


                </div>
                <div className="nw1-smaller-header" >
                    <div className="row">
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 200,
                            height: 50,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }} className='col-md-3 mt-2 d-none d-sm-block'>
                        </div>
                        <div style={{ padding: 0 }} className='d-sm-block d-md-none'><p style={{ color: '#000000', fontSize: 9, textAlign: 'left', fontWeight: 'bold' }} className='pt-3 px-2 '>MARKET</p></div>

                        <div className='col nw1-category-header'>
                            <div className='row'>
                                <div className='col py-2 px-4 d-none d-sm-block fadein' style={{ backgroundColor: '#F46E56' }} >
                                    <a href='https://DoctorDial.com.ng' target="_blank" style={{ color: 'inherit' }}><div style={{
                                        backgroundImage: `url(${WakandaHealthLogo})`,
                                        height: 48,
                                        backgroundPosition: '50% 50%',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }} ></div></a>

                                </div>
                                <div className='col py-2 px-4 d-none d-sm-block fadein' style={{ backgroundColor: '#4160E0' }}>
                                    <Link to='/jobs' target="_blank"><div style={{
                                        backgroundImage: `url(${WakandaJobsLogo})`,
                                        height: 48,
                                        backgroundPosition: '50% 50%',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}></div></Link></div>
                                <div className='col py-2 px-4 d-none d-sm-block fadein' style={{ backgroundColor: '#935FF0' }}>
                                    <Link to='/wakandaacademy' target="_blank"> <div style={{
                                        backgroundImage: `url(${WakandaAcademyLogo})`,
                                        height: 48,
                                        backgroundPosition: '50% 50%',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}></div></Link>
                                </div>
                                <div className='col py-2 px-4 d-none d-sm-block fadein' style={{ backgroundColor: '#C9BA49' }}>
                                    <Link to='/wakandaevents' target="_blank"><div style={{
                                        backgroundImage: `url(${WakandaEventsLogo})`,
                                        height: 48,
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}></div></Link>
                                </div>
                                <div className='col py-2 pr-4 d-none d-sm-block  fadein' style={{ backgroundColor: '#C43699' }}>
                                    <Link to='/wakandawallets' target="_blank"><div style={{
                                        backgroundImage: `url(${WakandaWalletsLogo})`,
                                        height: 48,
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}></div></Link>
                                </div>

                            </div>

                            {/* mobile screen header */}
                            <div className="row">
                                <div style={{ padding: 0, backgroundColor: '#F46E56' }} className=' col d-sm-block d-md-none'><p style={{ color: '#ffffff', fontSize: 9, textAlign: 'center', fontWeight: 'bold' }} className='pt-3 pr-2 pl-2 '>HEALTH</p></div>
                                <div style={{ padding: 0, backgroundColor: '#4160E0' }} className=' col d-sm-block d-md-none'><Link to='/jobs' target="_blank"><p style={{ color: '#ffffff', fontSize: 9, textAlign: 'center', fontWeight: 'bold' }} className='pt-3 px-0 '> JOBS</p></Link></div>
                                <div style={{ padding: 0, backgroundColor: '#935FF0' }} className='col d-sm-block d-md-none'><Link to='/wakandaacademy' target="_blank"><p style={{ color: '#ffffff', fontSize: 9, textAlign: 'center', fontWeight: 'bold' }} className='pt-3 px-2 '>ACADEMY</p></Link></div>
                                <div style={{ padding: 0, backgroundColor: '#C9BA49' }} className='col d-sm-block d-md-none'> <Link to='/wakandaevents' target="_blank"><p style={{ color: '#ffffff', fontSize: 9, textAlign: 'center', fontWeight: 'bold' }} className='pt-3 px-1 '>EVENTS</p></Link></div>
                                <div style={{ padding: 0, backgroundColor: '#C43699' }} className='col d-sm-block d-md-none'><Link to='/wakandawallets' target="_blank"><p style={{ color: '#ffffff', fontSize: 9, textAlign: 'center', fontWeight: 'bold' }} className='pt-3 pl-2 pr-4 '>WALLET</p></Link></div>

                            </div>
                        </div>
                    </div>
                    <div className="col d-flex  pt-lg-4 mt-4 mb-n4 d-sm-block d-md-none" style={{ justifyContent: 'space-between' }}>
                        <div className='sell px-2' onClick={() => {
                            props.showSearchModal()
                        }}>
                            &nbsp;&nbsp;  <img src={searchIcon} alt="search" width='22' />
                            &nbsp;<input type='text' className='header-input' placeholder="Search" readOnly={true} />&nbsp;</div>
                        <p className='sell px-2 mt-1'></p>
                        <p className='sell px-2 mt-1'>
                            {/* <img src={map} alt="map" width='18' />&nbsp;Nigeria/Lagos &nbsp;<img src={dropdown} width='10' /> */}
                            <select id='countries' name='countries' className="px-2" onChange={handleChange} value={location}>
                                <i className="fa fa-caret-down" aria-hidden="true"></i>
                                {locations.map((item, id) => {
                                    return (
                                        <option style={{ backgroundImage: `url(${map})`, height: 30 }}
                                            key={id} value={item.value}
                                            onChange={(e) => {
                                                setLocation(item.value)
                                                console.log('new location is ', location)
                                            }}
                                        >&nbsp;{item.name}</option>
                                    )
                                })}
                            </select>
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-4  pt-4 text-center text-md-left animated bounce">
                            <p style={{ color: '#1C1B1B', fontSize: 18, fontWeight: 'bolder', letterSpacing: 1, fontFamily: 'Raleway' }} className='d-block mx-auto mx-md-0'>
                                Africa's Businesses Online<br /><span style={{ fontWeight: 'lighter' }}>Find the Shops you love</span>
                            </p>
                        </div>

                        <div className="col-md-2 d-none d-md-block pt-lg-4 sell-wakanda-div mt-2 "><a href='https://blackfriday.wakandamarket.com' style={{ color: 'inherit' }}><p className='sell'>SELL ON WAKANDA</p></a></div>
                        <div className="col d-none d-md-flex pt-lg-4 mt-2 flex-row" style={{ justifyContent: 'center' }}><p className='sell px-2'>
                            <img src={searchIcon} alt="search" width='18' />&nbsp;&nbsp;
                            <input type='text' className='header-input' placeholder="Search"
                                onChange={(e) => {
                                    props.searchforstuff(e.target.value);
                                    setSearchTerm(e.target.value); SEARCH = e.target.value;
                                }} />&nbsp;</p>
                            <p className='sell px-2 mt-1'>
                                {/* <img src={map} alt="map" width='18' />&nbsp;Nigeria/Lagos &nbsp;<img src={dropdown} width='10' /> */}
                                <select id='countries' name='countries' className="px-2" onChange={handleChange} value={location}>
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    {locations.map((item, id) => {
                                        return (
                                            <option style={{ backgroundImage: `url(${map})`, height: 30 }}
                                                key={item.value || id} value={item.value}
                                                onChange={(e) => {
                                                    setLocation(item.value)
                                                    console.log('new location is ', location)
                                                }}
                                            >&nbsp;{item.name}</option>
                                        )
                                    })}

                                </select>
                            </p>
                        </div>


                    </div>

                    <div className="  d-sm-block d-md-none text-center">
                        <a href='https://blackfriday.wakandamarket.com' style={{ color: 'inherit' }}> <button type="button" class="btn btn-outline-light btn-sm text-center px-3 py-2 mb-3 sell ">SELL ON WAKANDA</button></a>
                    </div>

                    <div class="row d-sm-block d-md-none text-center px-3" style={{ backgroundColor: '#01331c' }}>
                        <div className="col "><Link to={`category/Electronics`}><img src={electronicsIcon} className='pt-2' width='50' /></Link></div>
                        <div className="col"><Link to={`category/Fashion`}><img src={fashionIcon} className='pt-2' width='35' /></Link></div>
                        <div className="col"><Link to={`category/Food`}><img src={foodIcon} className='pt-2' width='25' /></Link></div>
                        <div className="col"><Link to={`category/Health`}><img src={healthIcon} className='pt-2' width='30' /></Link></div>
                        <div className="col"><Link to={`category/`}><img src={more} className='pt-2 mt-0' width='20' /></Link><p className='mt-n1 font-weight-bold' style={{ fontSize: 10 }}>MORE</p></div>
                    </div>
                    <div className="row">
                        {categories.map((category, id) => {
                            return (
                                <div key={id} className='d-none d-sm-block'>
                                    <Fade in={open} timeout={1000}>
                                        <Link to={`/category/${category.name}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <div className='col-md-2 ' style={{ textAlign: 'center' }}>
                                                <div style={{
                                                    backgroundImage: `url(${categoryImages[category.select]})`,
                                                    backgroundPosition: '50% 50%',
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                    borderRadius: 10,
                                                }} className="fadein cat-img" >
                                                </div>
                                                <div style={{ width: 40 }}>
                                                    <p style={{ fontSize: 9, color: '#1C1B1B', fontWeight: 'bold' }} className='pt-1' >{category.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </Fade>
                                </div>
                            )
                        })}
                    </div>
                </div>


            </div>
            {searchTerm.length === 0 && <div class='container-fluid p-4 pb-0' style={{ backgroundColor: 'white' }}>
                <div className="p-4 pb-0"  >
                    <h6 style={{ color: '#707070', fontWeight: 'bold' }}>Top Picks from Providers</h6>
                </div>
            </div>}
            {searchTerm.length === 0 && <div className='container-fluid' style={{ marginTop: -15 }}>
                <Providers location={location} />
            </div>}
        </div>

    );
}

export default Header;

