import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from '../utilityComponents'
import Skeleton from '@yisheng90/react-loading';


import { Link } from 'react-router-dom'
import '../styles/provider.css'
import Footer from '../Footer/footer'
//import Product from  './product1'
//import heart from '../assets/heart4.svg'
import '../styles/category.css'
import '../Header/ProductHeader.css';
////import rectangle from '../assets/Rectangle.png'
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
//import searchIcon from '../assets/searchIcon.svg'
//import map from '../assets/map.svg'
import electronics from '../../assets/Electronics.svg'
import fashion from '../../assets/fashion.jpg'
import food from '../../assets/food.jpg'
import beauty from '../../assets/beauty.jpg'
import wkjobs from '../../assets/Jobs.jpg'

import { Footer2 } from '../utilityComponents'

// import dropdown from '../assets/dropd.svg'
// import electronicsIcon from '../assets/Electronics.svg'
// import fashionIcon from '../assets/fashion.svg'
// import foodIcon from '../assets/Food.svg'
// import healthIcon from '../assets/Health.svg'


let chadwick = 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/chadwick.jpeg?alt=media&token=0b01b488-39b5-4c26-a42a-d18691f80b5f'
const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"/* 
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" */

let bgCol = '#3D5AD4'

let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]
let CHOSEN_LOCATION = ''
let categories = [
    { name: 'Electronics', 'image': electronics, select: 'Phones' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Food', 'image': food, select: 'Fresh Food' },
    { name: 'Beauty', 'image': beauty, select: 'Hair' },
    { name: 'Fashion', 'image': fashion, select: 'Shoes' },
    { name: 'Cars', 'image': fashion, select: 'New Cars' },
    { name: 'Health', 'image': fashion, select: 'Pharmacies' },
    { name: 'Handyman', 'image': fashion, select: 'Plumbing' },
    { name: 'Appliances', 'image': fashion, select: 'Appliances' },
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Electronics', 'image': electronics, select: 'Tablets' },

]

export default function wakandajobs() {
    return (
        <div>
             <div>
            
            <div className='container col-md-8 p-2' style={{ marginTop: 30 }}>
            </div>
            <div class='' style={{ fontFamily: "Raleway", fontSize: 13, lineHeight: "1.6em", display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
            <div style={{
                        //background: `url(${data.strPoster}) center center/cover no-repeat`,
                        //background:`url(${LeagueBackgroundImages[myleaguename]}) center center/cover no-repeat`,
                        backgroundImage: `url(${wkjobs})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "cover",
                        backgroundPosition: "50% 15%",
                        backgroundAttachment: "scroll",
                        width: "100%",
                        height: 500,
                    }} className=" mb-4">
                        {/* <div>
                            <h1 className='display-4' style={{ marginTop: 120, marginLeft: 500, color: '#935FF0' }}>Wakanda Academy</h1>
                        </div> */}
                    </div>
                <h1 className='display-1' style={{margin: '0 auto'}}>Coming soon</h1>
              
            </div>
            <div style={{ height: '60vh'}}></div>
            {Footer2(bgCol)}
            
        </div>
        </div>
    )
}
