import React from 'react'
import heart from '../../assets/heart.svg'
import footerLogo from '../../assets/footer-logo.svg'
import googlePlay from '../../assets/googlePlay.png'
import appStore from '../../assets/APPSTORE.png'

import { Link } from 'react-router-dom'

import './footer.css';

const Footer = (props) => {
    return (
        <footer class="page-footer pt-4"  style={{ marginBottom:-2, width:"100%"}}>
            <div class="text-center  p-3 pr-4" style={{backgroundColor: '#00A85A'}}>
                <div class="row pt-4">
                    <div class="col-md-3 mt-md-0 mt-3 " style={{textAlign: 'center'}}>
                        <div style={{
                            backgroundImage: `url(${footerLogo})`,
                            height: 80,
                            backgroundPosition: 'center center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }} className=''>
                        </div>
                    </div>


                    <hr class=" w-100 d-md-none pb-3" />
                    <div class="col-md-3 mb-md-0 mb-3">
                        <ul class="list-unstyled">
                            <li>
                              <p style={{color: 'white'}}><a target="_blank" href="https://www.african-founders.com/terms-and-conditions" style={{color:'#ffffff'}}><small>Terms of use</small></a></p>
                            </li>
                         <li>
                            <p style={{color: 'white'}}><a target="_blank" href="https://www.african-founders.com/terms-and-conditions" style={{color:'#ffffff'}}><small>Privacy and Security</small></a></p>
                            </li>
                            <li>
                            <p style={{color: 'white'}}><a href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" style={{color:'#ffffff'}} target='_blank'><small>Feedback</small></a></p>
                            </li>
                        </ul>

                    </div>

                    <div class="col-md-6 mb-md-0 mb-3 px-4 text-md-right" >

                        <ul class="list-unstyled ">
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target='_blank'><img src={googlePlay} width='100' alt='google play'/></a>
                                <a href="https://apps.apple.com/ng/app/wakanda-market/id1451309384" target='_blank'>&nbsp;<img src={appStore} width='100' alt='appstore'/></a>
                            </li>
                            <li>
                                <p className='text-white pt-4'><p  style={{fontSize: 14, }}>CONNECT WITH US ON </p><span><a href='https://facebook.com/wakandamrkt/' target='_blank' style={{color: 'inherit'}} ><i class="fab fa-facebook fa-lg"></i></a>&nbsp;&nbsp;&nbsp;<a href='https://instagram.com/wakandamarketapp' target='_blank' style={{color: 'inherit'}}><i class="fab fa-instagram fa-lg"></i></a>&nbsp;&nbsp;&nbsp;<a href='https://twitter.com/wakandamarket?lang=en-gb' target='_blank' style={{color: 'inherit'}}><i class="fab fa-twitter fa-lg"></i></a></span></p>
                            </li>
                         
                        </ul>

                    </div>

                </div>

            </div>
            <div class="footer-copyright text-center " style={{backgroundColor: '#A9D047' }}><p className="p-1" style={{fontSize: 12, marginBottom: 0}}><small>Made with <img src={heart} alt="heart" width="10"/> by African Founders</small></p>
            </div>
        </footer>
    )
}

export default Footer
