import React from 'react';
import ReactPixel from 'react-facebook-pixel'; 
import AllComponents from './components/allComponents'
import './App.css'; 
 
//const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
/* const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}; */
ReactPixel.init('1056214991486673');
ReactPixel.pageView();

function App() {
  return (
    <div>
       <AllComponents/>
    </div>
  );
}

export default App;
