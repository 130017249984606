import React, { useEffect, useState } from 'react'
//import { uniformImageHolder, displayprice } from '../utilityComponents'
import { Link, Redirect } from 'react-router-dom'
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl, DropdownButton } from 'react-bootstrap'
import RichTextEditor from './RichTextEditor'
import { EditorState, convertToRaw } from 'draft-js';
import SignInModule from './SignInModule'
import '../styles/provider.css'
import Footer from '../Footer/footer'
//import Product from  './product1'
import heart from '../../assets/heart4.svg'
import call from '../../assets/call-icon.png'
import '../styles/category.css'
import '../Header/ProductHeader.css';
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
import searchIcon from '../../assets/searchIcon.svg'
import map from '../../assets/map.svg'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";



let DEFAULT_POST = EditorState.createEmpty()
let BLOGID = ''
const BlogPost = (props) => {

    const [redirect, setRedirect] = useState(false)
    const [url, setURL] = useState('')
    const [blogData, setBlogData] = useState({})
    const [post, setPost] = useState(DEFAULT_POST)
    const [blogID, setBlogID] = useState('')
    const [title, setTitle] = useState('')
    const [profileID, setProfileID] = useState('')
    const [selectedBlogTitle, setSelectedBlogTitle] = useState('')
    const [showSuccessDialogue, setShowSuccessDialogue] = useState(false)


    const CreateBlogPost = async () => {
        console.log('launching CreateBlogPost, blogID is ', blogID)
        if (!blogID) { alert('Please select where to post from the dropdown.'); return }
        if (!title) { alert('Please provide a title!'); return }
        if (DEFAULT_POST == post) { alert('blog post is empty!'); return }
        if (!blogID || !profileID) return
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    //type: 'forum',
                    action: 'addBlogPost',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    aOpenness: "public",
                    aType: "forum",
                    aSpecialCategory: "" || ["general", "jobOffer", "jobRequest", "productOffer", "productRequest", "productOffers", "event"],
                    aStatus: "LIVE",
                    //networkID: req.body.networkID || null,
                    //communityID: req.body.communityID || null,
                    // location: geoPoint(location coords),
                    // options: req.body.options || {},
                    moderatorProfileIDs: profileID,
                    groupTitle: title,
                    groupDetails: JSON.stringify(convertToRaw(post.getCurrentContent())) || "",
                    topics: [],
                    // profileID:'00babaOjaGeneralBlog',
                    blogID: blogID,
                    location: 'lagos'
                    //blogID: blogID || '00babaOjaGeneralBlog',
                })
            });
            const r2 = await r.json();
            console.log('server response in CreateBlogPost is: ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                setShowSuccessDialogue(true)
                setPost(DEFAULT_POST)
                //let data = r2.response

            } else {
                console.log('something went wrong... ', r2.msg)
                alert('something went wrong, please try again')
            }

        } catch (err) {
            console.log("Error from firebase using CreateBlogPost is: ", err);

        }

    }

    let BLOGID = ''
    let SELECTED = ''
    const handleSelect = (e) => {
        console.log('e is ', e.xID);
        BLOGID = e.xID
        SELECTED = e.blogTitle
        setBlogID(BLOGID)
        setSelectedBlogTitle(SELECTED)
    }

    const getSavedData = () => {
        let ProfileID = localStorage.getItem('blogProfileID');
        let blog = JSON.parse(localStorage.getItem('blog'));
        console.log('profileID is ', ProfileID)
        console.log('blog is ', blog)
        setBlogData(blog)
        setProfileID(ProfileID)
    }


    useEffect(() => {
        getSavedData()
    }, [])

    /* useEffect(() => {
        if(selectedBlogTitle) return
        setBlogID(BLOGID)
        setSelectedBlogTitle(SELECTED) 
        console.log('selected blog is ', selectedBlogTitle)
    }, [blogID, selectedBlogTitle]) */

    return (
        <div style={{ minHeight: '100vh' }}>
            {redirect && <Redirect to={url} />}
            <div className="nw1-product-smaller-header" style={{ position: 'relative' }}>
                <div className="row"  >
                    <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 190,
                            height: 45,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            paddingBottom: 20
                        }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                        </div> 
                    </Link>
                    <div className="float-right d-none"><p className='sell d-none d-md-block' style={{ fontWeight: 'bold' }}>
                        SELL ON WAKANDA</p>
                    </div>
                    <div style={{ position: 'absolute', top: 16, right: 20 }}><SignInModule /></div>
                </div>
            </div>

            <div className="container col-md-10 col-sm-12" style={{ display: 'flex', justifyContent: 'left', width: "90%", marginTop: 100, minHeight: '100vh' }}>
                <div className='col-md-10 col-sm-12'> 
                    <div style={{ width: '100%' }}>
                        {Array.isArray(blogData) && <div class="dropdown show">
                            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <small> {selectedBlogTitle || `Choose Blog to Publish`}</small>
                            </a>

                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                {blogData.map((item, id) => {
                                    if (item.blogTitle === selectedBlogTitle) return
                                    return (
                                        <a class="dropdown-item" key={id} onClick={(e) => {
                                            //console.log('data passed is ', e) 
                                            BLOGID = item.xID
                                            SELECTED = item.blogTitle
                                            setBlogID(BLOGID)
                                            setSelectedBlogTitle(SELECTED)
                                        }}><small>{item.blogTitle}</small></a>
                                    )
                                }
                                )}
                            </div>
                        </div>}
                    </div>
                    
                    {showSuccessDialogue && <div className='mt-4 mb-2 p-4' style={{
                        backgroundColor: '#F1FFF1', width: '100%', borderRadius: 10,
                        borderWidth: 1, border: '1px #99ff99 solid', position: 'relative'
                    }}>
                        <small style={{ color: '#62A362' }}>Success! your post has been published.</small>
                        <Link to={`/blog/${blogID}`} target='_blank'><small> Click to view post</small></Link>
                        <div style={{ position: 'absolute', top: 20, right: 20, color: '#62A362' }} onClick={() => { setShowSuccessDialogue(false) }}>
                            X
                            </div>
                    </div>}

                    <div className="jumbotron container mt-4 p-4" style={{ padding: 40 }}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text >Title: </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl as="input" aria-label="With textarea" onChange={(e) => {
                                console.log('e is ', e.target.value)
                                let text = e.target.value
                                setTitle(text)
                            }} />
                        </InputGroup>
                        <RichTextEditor setContent={(e) => {
                            console.log(' post is ', e)
                            //JSON.stringify(convertToRaw(e.getCurrentContent()))
                            setPost(e)
                        }} />
                    </div>
                    <div>
                        <Button variant="primary" size="sm" onClick={CreateBlogPost}>
                            Save
                        </Button>{' '}
                        {/* <Button variant="secondary" size="sm">
                            Save draft
                        </Button>{' '} */}
                        <Button variant="danger" size="sm">
                            Cancel
                        </Button>
                    </div>

                </div>

            </div>

            <div className='pt-4'>

            </div>

            <Footer />
        </div >
    )
}

export default BlogPost