import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice, Header } from './utilityComponents'
import { Link, Redirect } from 'react-router-dom'
import Skeleton from '@yisheng90/react-loading';
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap'
import Product from './product1'
import ProductHeader from './Header/ProductHeader'
import './styles/provider.css'
import Footer from './Footer/footer'
//import Product from  './product1'
import heart from '../assets/heart4.svg'
import call from '../assets/call-icon.png'
import './styles/category.css'
import './Header/ProductHeader.css';
import rectangle from '../assets/Rectangle.png'
import WakandaLogoWhite from '../assets/Wakanda-Logo-white.svg'
import searchIcon from '../assets/searchIcon.svg'
import map from '../assets/map.svg'
import electronics from '../assets/electronics.jpg'
import fashion from '../assets/fashion.jpg'
import Fashion from '../assets/fashion.jpg'
import food from '../assets/food.jpg'
import beauty from '../assets/beauty.jpg'
import dropdown from '../assets/dropd.svg'
import electronicsIcon from '../assets/Electronics.svg'
import fashionIcon from '../assets/fashion.svg'
import foodIcon from '../assets/Food.svg'
import healthIcon from '../assets/Health.svg'
import Accordion from 'react-bootstrap/Accordion'

import banner1image from './images/Group-674.png'
import { BannerCat, Banner5 } from './utilityComponents'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";





const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const marketing = [
    '- Sell your products and services',
    '- Use the Wakanda Wallet and Escrow Service',
    '- Get free business/e-commerce Website and a lot more…'
]

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"/* 
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" */


let locations = [
    { name: "Lagos", value: "lagos" },
    { name: "Ibadan", value: "ibadan" },
    { name: "Port Harcourt", value: "portHarcourt" },
    { name: "Kaduna", value: "kaduna" },
    { name: "Abeokuta", value: "abeokuta" },
    { name: "Ghana", value: "ghana" },
    { name: "Abuja", value: "abuja" }
]
let CHOSEN_LOCATION = ''
let categories = [
    { name: 'Fashion', 'image': fashion, select: 'Bags' },
    { name: 'Food', 'image': food, select: 'Fresh Food' },
    { name: 'Beauty', 'image': beauty, select: 'Hair' },
    { name: 'Electronics', 'image': electronics, select: 'Phones' },
    { name: 'Cars', 'image': fashion, select: 'New Cars' },
    { name: 'Health', 'image': fashion, select: 'Pharmacies' },
    { name: 'Handyman', 'image': fashion, select: 'Plumbing' },

]




let imageDat = {
    'Fashion': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFashion%2Fmens-clothes.jpg?alt=media&token=b2e86dc1-8887-4e77-b112-2fe5aa3980ad',
    'Food': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FFreshFood%2Fcarrots-crate-food-1508666.jpg?alt=media&token=ef7ad2a8-a72a-4d34-a41f-5d1ea6786622',
    'Beauty': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FBeauty%2FMakeup%2Fassorted-blur-close-up-1115128.jpg?alt=media&token=43abcfff-b9a9-4769-abd4-2719c5ae956b',
    'Electronics': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FElectronics%2FPhones.jpg?alt=media&token=a4b78d9b-458c-4e92-b766-f34294b275e4',
    'Cars': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FCars%2Fnew-cars.jpg?alt=media&token=0d2bceee-823e-4402-88ad-b693776f77f0',
    'Health': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHealth%2FPharmacies%2FPharmacy.jpg?alt=media&token=db11dfbf-2d57-4e71-8ed8-be3d1dcc0161',
    'Handyman': 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FPlumbing.jpg?alt=media&token=9f763caf-1a91-439b-b48f-716c37372a5f',
}




const CategoryPage = (props) => {

    const [shopNamefromURL, setShopNameFromURL] = useState("")
    const [initialURL, setInitialURL] = useState("")
    const [shopCategory, setShopCategory] = useState("")
    const [shopProducts, setProducts] = useState(productData)
    const [location, setLocation] = useState('lagos')
    const [search, setSearch] = useState(false)
    const [phone, setPhone] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [redirect, setRedirect] = useState(false)
    const [url, setURL] = useState('')
    const [catig, setCatig] = useState('')

    

    const handleLocationChange = async (value) => {  
        await setLocation(value)
        await setProducts(productData)
    };

    const handleCategoryChange = async (value) => {
        console.log('firing up handleCategoryChange, value passed is... ', value)
        await setShopCategory(value)
        setProducts(productData)
    };

    const parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            await setInitialURL(fullURLsubStrings[2])
            let a = window.location.href.includes('#/') ? window.location.href.split('#/')[1] : ""
            if (a !== "" || a !== undefined) {
                await setLocation(a)
            }
            await setShopCategory(fullURLsubStrings[4])
            console.log('SHOP CATEGORY IS ', shopCategory)
            await setCatig(shopCategory)

        } catch (err) {
            console.log('issues with parsing URL ', err)
        }
    }

     
    const fetchProductsbyCategory = async () => {
        if (shopCategory == "" || shopCategory == undefined) { return }
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'offers',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    category1: shopCategory,
                    amount: 1000,
                    location: location || 'lagos'
                })
            });
            const r2 = await r.json();
            console.log('r2 offers fetched in fetchProductsbyCategory are: ', r2)
            if (r2.msg == 'SUCCESS') {
                let data = r2.finalResults
                await setProducts(data)
                await setPhoneNumber(data.phoneNumber)
            } else {
                // console.log('no custom URL detected... ', r2.msg)
            }

        } catch (err) {
            // console.log("Error from firebase using fetchProductsbyCategory is: ", err);

        }

    }
    useEffect(() => {
        fetchProductsbyCategory()

    }, [shopCategory, location])



    useEffect(() => {
        parseURLandReturnRelevantURL()
    }, [])

    return (
        <div>
            {redirect && <Redirect to={url} />}
            {Header(WakandaLogoWhite)}

            <div class='container  p-4' style={{ position: 'relative', marginTop: 40 }}>
                {Banner5(imageDat[shopCategory], 'Sell items like this? ', 'Open a shop on Wakanda Market now!', 'START SELLING', '#00A85A')}
            </div>
            <section class='container pt-0' style={{ minHeight: '100vh' }}  >

                <div id='filter' class='d-flex justify-content-center mb-4 container align-items-center '  >
                    <div class='container col-md-12 pb-2 pt-2'
                        style={{ backgroundColor: 'rgba(0,0,0,0.08)' , borderRadius:5}}
                    >
                        <div class=''>
                            <Accordion defaultActiveKey="0" >
                                <Accordion.Toggle as={Button} variant="link" eventKey="1" 
                                class='gradient-buttons pt-1 col-md-12 d-flex justify-content-between mr-2 my-0 ' style={{ color: 'black', textAlign: 'left', border: '0px solid #bababa' }} block>
                                    <div >
                                        <div type="button" class="btn btn-light btn-block">
                                            <small><b>Filter : Select Category</b></small>
                                            <small><b>&nbsp;<i class="fas fa-caret-down"></i></b></small>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <div class='gradient-buttons pt-1 col-md-12 d-flex justify-content-around mr-2 my-0 flex-wrap'>
                                        {categories.map((item, id) => {
                                            return (
                                                <button className='p-1 col-md-1 mx-1 my-1' key={id} style={{ backgroundColor: shopCategory === item.name ? '#00A85A' : '#D0D0D0', borderWidth: 0 , borderRadius:5}}
                                                    onClick={() => {
                                                        handleCategoryChange(item.name)
                                                    }}>
                                                    <Link to={`/category/${item.name}`} style={{ textDecoration: 'none', color: shopCategory === item.name ? 'white' : 'black' }}
                                                        onPress={() => {
                                                            handleCategoryChange(item.name)
                                                        }}>
                                                        <small>{item.name}</small>
                                                    </Link>
                                                </button>
                                            )
                                        })}
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        </div>
                        <Accordion defaultActiveKey="0" class='shadow-sm '>

                            <Accordion.Toggle as={Button} variant="link" eventKey="1" class='gradient-buttons pt-1 col-md-12 d-flex justify-content-between mr-2 my-0'
                            style={{ color: 'black', textAlign: 'left', border: '0px solid #bababa', }} block>
                                <div >
                                    <div type="button" class="btn btn-light btn-block">
                                        <small><b>Filter : Select Location</b></small>
                                        <small><b>&nbsp;<i class="fas fa-caret-down"></i></b></small>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <div class='gradient-buttons pt-1 col-md-12 d-flex justify-content-around mr-2 my-0 flex-wrap'>
                                    {locations.map((item, id) => {
                                        return (
                                            <button className='p-1 col-md-1 mx-1 my-1 ' key={id} style={{ textDecoration: 'none', backgroundColor: location === item.value ? '#00A85A' : '#D0D0D0', borderWidth: 0, borderRadius:5 }}
                                                onClick={() => {
                                                    handleLocationChange(item.value)
                                                }}>
                                                <small style={{ textDecoration: 'none', color: location === item.value ? 'white' : 'black' }}>{item.name === 'Port Harcourt' ? 'PH' : item.name}</small>
                                            </button>
                                        )
                                    })}
                                </div>
                            </Accordion.Collapse>
                        </Accordion>

                    </div>

                </div>
                <div class='d-flex justify-content-center mt-4 mb-4'>
                    <div class='container mt-4 row'  >
                        {shopProducts.map((item, id) => {
                            if (shopProducts.length == 0) {
                                return (
                                    <div class='col-md-12 ' style={{ height: 50, overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                        <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                            <small class='text-truncate' style={{ fontSize: 14, color: 'black' }} >No Results...</small>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div class='col-md-3 col-sm-6 py-2 p-1 p-sm-2' style={{ width: '50%'}} key={id}>
                                    <div class='shadow-sm p-0 pt-0  zoomin' style={{ border: '1px solid rgba(100,100,100,0.1)', borderRadius: 10 }}>
                                        {item.pictureURL ? <div class='col-md-12 p-0 pt-0 '
                                            style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', position: 'relative'  }}>
                                            {uniformImageHolder(item.pictureURL || url, '100%', '12rem', '', 10)}
                                        </div>
                                            :
                                            <Skeleton width={"100%"} height={"12rem"} />}

                                        <div class='col-md-12 ' style={{ minHeight: '5rem', paddingBottom: 20, paddingTop: 0, width: "100%", }}>
                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                    <small class='text-truncate' style={{ fontSize: 14, }} >{item.title || "..."}</small>
                                                </div>
                                                {/* <div style={{ width: "100%", marginTop: -5, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                    <small class='text-truncate' style={{ fontSize: 13, }} ><b>{displayprice(item.price, item.priceType, item.priceCurrency) || "..."}</b></small>
                                                </div> */}
                                                <div class='container row d-flex align-items-center ' style={{ width: "100%", textOverflow: 'ellipsis' }}>
                                                    {((item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount <= 0)) || (!item.hasOwnProperty("postPriceDiscount"))) && <small class='text-truncate py-2' style={{ fontSize: 13, }} ><b>
                                                        {displayprice(item.price, item.priceType, item.priceCurrency) || '...'}
                                                    </b></small>}
                                                    {(item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount > 0)) && <small style={{ color: 'black', fontWeight: 'bold' }} className="py-2 text-truncate">
                                                        <strike>{displayprice(item.price, item.priceType, item.priceCurrency) || '...'}</strike>
                                                    </small>}
                                                    {(item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount > 0)) && <small class='text-truncate ml-2' style={{ fontSize: 13, color: '#FF1E78' }} ><b>
                                                        {item.priceCurrency === 'NGN' ? '₦' : '₵'}{(item.price - item.postPriceDiscount) || '...'}
                                                    </b></small>}
                                                </div>
                                            </div>

                                            <hr />
                                            <div style={{ width: "100%", marginTop: -5, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <small class='text-truncate col-md-12  pl-0' style={{ fontSize: 11 }} >sold by <span class='text-success text-truncate'>{item.profileNameBusiness || "..."}</span></small>
                                                {(item.pictureURL || item.title || item.profileNameBusiness) && <Link to={`/${item.providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                        <button type="button" class="btn btn-sm btn-inline btn-success  py-1 py-sm-2  mx-0 mt-2 d-flex justify-content-center align-items-center" style={{ width: '100%', }} >
                                                            <span className='mx-2' ><small style={{ fontSize: 14 }} class='text-truncate font-weight-normal' >View Product<span></span></small></span>
                                                        </button>
                                                    </div>
                                                </Link>}
                                            </div>
                                        </div>
                                    </div>
                                    {(item.hasOwnProperty("postPriceDiscount") && (item.postPriceDiscount > 0)) && <div style={{ position: 'absolute', top: "10.5rem", left: 20, backgroundColor: '#FF1E78', padding: 5, paddingBottom: 0, paddingTop: 0, borderRadius: 10 }} >
                                        <small style={{ color: 'white', fontWeight: 'bold', fontSize: 10, marginTop: -10 }} >
                                            {Math.floor(item.postPriceDiscount / item.price * 100)}<i>{"% off"}</i>
                                        </small>
                                    </div>}
                                </div>)
                        })}
                    </div>
                </div>
            </section>
            <div class='container mt-n4  p-4' style={{ minHeight: 60, position: 'relative', marginTop: 0 }}>
                {BannerCat(imageDat[shopCategory], 'Enjoy More Benefits from the Wakanda Market App', marketing, 'GET STARTED', '#C1DE76')}
            </div>
            <Footer />
        </div >
    )
}

export default CategoryPage