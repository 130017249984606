import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from './utilityComponents'
import { Link } from 'react-router-dom'
import Skeleton from '@yisheng90/react-loading';
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap'
import Product from './product1'
import ProductHeader from './Header/ProductHeader'
import './styles/provider.css'
import Footer from './Footer/footer'
//import Product from  './product1'
import heart from '../assets/heart4.svg'
import './styles/category.css'
import './Header/ProductHeader.css';

let url = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"
//let products = 


const ProductShowcase = (props) => {
    const [products, setProducts] = useState([1, 2, 3, 4, , 5, 6, 7, 8,])

    const fetchProductsbyCategory = async () => {
       // console.log('launching fetchProductsbyCategory in ProductShowcase, location is', props.location, ' shopcategory is ', props.shopCategory)
        if (props.shopCategory == "" || props.shopCategory == undefined || !props.location) { return }
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'offers',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    category1: props.shopCategory,
                    amount: 8,
                    location: props.location || 'lagos'
                })
            });
            const r2 = await r.json();
            //console.log('r2 provider fetched in fetchProductsbyCategory is ', r2)
            if (r2.msg == 'SUCCESS') {
                //console.log('r2 is xxx', r2)
                let data = r2.finalResults
                //setShopData(r2.results)
                await setProducts(data)
            } else {
                console.log('no custom URL detected... ', r2.msg)
            }

        } catch (err) {
            console.log("Error from firebase using fetchProductsbyCategory is: ", err);
        }

    }

    useEffect(() => {
        fetchProductsbyCategory()
    }, [props.shopCategory, props.location])

    return (
        <div>
            <section  >

                <div class=''>

                    <div class=' mt-4 row'  >

                        {products.map((item, id) => {
                            return (

                                <div class='col-md-3 col-sm-6 py-2 ' style={{ width: 160, margin: '0 auto', }}>
                                    <div class='p-0 pt-0  zoomin' style={{ borderRadius: 10 }}>
                                        {item.pictureURL ? <div class='col-md-12 p-0 pt-0 '
                                            style={{ marginTop: -10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                            <Link to={`/${item.providerID}/${item.xID}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                {uniformImageHolder(item.pictureURL || url, '100%', '10rem', '2px solid rgba(100,100,100,0.1)', 20)}
                                            </Link>
                                        </div>
                                            :
                                            <Skeleton width={"100%"} height={"12rem"} />}

                                        {item.pictureURL || item.title || item.description ? <div class='col-md-12 ' style={{ padding: 20, paddingBottom: 20, paddingTop: 0, width: "100%" }}>
                                            <div style={{ minHeight: '5vh', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                                <div style={{ width: "100%", marginTop: 10, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                    <small class='text-truncate' style={{ fontSize: 14, }} >{item.title || item.description}</small>

                                                </div>
                                                <small style={{ marginTop: 10, textOverflow: 'ellipsis', textAlign: 'center', fontWeight:'bold' }}>
                                                    {displayprice(item.price, item.priceType, item.priceCurrency)}
                                                </small>{/* 
                                                <div style={{ width: "100%", marginTop: -5, overflowX: 'hidden', textOverflow: 'ellipsis', }}>
                                                    <small class='text-truncate' style={{ fontSize: 13, }} ><b>{displayprice(item.price, item.priceType, item.priceCurrency) || "..."}</b></small>
                                                </div>  */}
                                            </div>
                                        </div>
                                            :
                                            <Skeleton width={"100%"} height={20} />}


                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </div >
    )
}

export default ProductShowcase