import React from 'react';
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Skeleton from '@yisheng90/react-loading';
import { uniformImageHolder, displayprice } from '../utilityComponents'
import ElementSlider from '../slider'
import './professionals.css'
import imageData from '../imageData'
//import Product from './product1'
import '../styles/provider.css'
let OLDbg = 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680'
let NEWbg = 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2Fwkmarket-bground4.jpg?alt=media&token=644f492d-403f-43ae-b7d1-64ac77546fe2'
let IMAGES = imageData.images
const data = [
  /* { name: 'Public Speakers', color: '#4160E0' },  */
  { name: 'Marketing', color: 'Green' },
  { name: 'Digital Marketing', color: '#C43699' }, { name: 'Website Development', color: '#4DC5E2' },
  { name: 'Graphics Design', color: '#4160E0' },
  /* { name: 'Writing / Content', color: 'Green' }, */
  { name: 'Translation', color: '#C43699' }, { name: 'Video', color: '#4DC5E2' },
  { name: 'Animation', color: '#4160E0' }, { name: 'Photography', color: 'Green' },
  { name: 'Music', color: '#C43699' }, { name: 'Marketing Materials, Printing, Branding', color: '#4DC5E2' },
  { name: 'Software Development', color: '#4160E0' }, { name: 'Legal', color: 'Green' },
  { name: 'App Development', color: '#C43699' }, { name: 'Computer & IT Services', color: '#4DC5E2' },
  { name: 'General Business', color: '#C43699' }, { name: 'Facility Management', color: '#4DC5E2' },
  { name: 'Teaching - Primary', color: '#4160E0' }, { name: 'Teaching - Secondary', color: 'Green' },
  { name: 'Teaching - Tertiary', color: '#C43699' }, { name: 'Training & Education', color: '#4DC5E2' },
  { name: 'Training - Soft Skills', color: '#C43699' }, { name: 'Coaching', color: '#4DC5E2' },
  { name: 'Visa & Concierge', color: '#4160E0' }, { name: 'Events & Catering', color: 'Green' },
  { name: 'Wedding Planning', color: '#C43699' }, { name: 'Trade Services', color: '#4DC5E2' },
  { name: 'Financial & Investment', color: '#4160E0' }, { name: 'Insurances', color: 'Green' },
  { name: 'Loans', color: '#C43699' }, { name: 'Business Consulting', color: '#4DC5E2' },
  { name: 'IT Consulting', color: '#4160E0' }, { name: 'Other Services', color: 'Green' },
  /* { name: 'Fun/Entertainment', color: '#C43699' },  */
  { name: 'PR', color: '#C43699' }
]


const ProfessionalCard = (props) => {
  return (
    <div style={{  backgroundColor: "white", }}>
      <div
        style={{
          backgroundImage: `url(${props.image || OLDbg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minHeight: 150,
          width: "100%",
          marginTop: 10,
          borderRadius: 10,
          backgroundColor: props.color || 'blue',
          //backgroundBlendMode: 'overlay',
          //border: borderStyle || ''
        }}>
         <div style={{
          backgroundColor: props.color || 'blue',
          backgroundBlendMode: 'overlay',
          opacity:0.5,
          minHeight: 150,
          width: "100%",
          marginTop: 10,
          borderRadius: 10 
        }}>

        </div>  
      </div>
    </div>
  )
}


const Professionals = () => {

  return (
    // <div class='container' style={{ display: "flex", flexDirection: "column", paddingTop: 20 }}>
    /* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", }}> */
    <ElementSlider dataAvailable={data} top={100}>
      {data.map((item, id) => {
        return (
          <div key={id} class='shrink m-3' >
            <Link to={`/professionals/${item.name}`} target="_blank" style={{ textDecoration: 'none' }}>
              <div class='shadow-sm ' style={{
                width: 200, textAlign: 'center', paddingBottom: 10,
                backgroundColor: 'white', borderRadius: 10, textOverflow: 'ellipsis'
              }}>
                <ProfessionalCard image={IMAGES[item.name]} color={item.color} />
                <div class='text-truncate' style={{ textDecoration: 'none', color: item.color || 'grey', 
                    borderRadius: 10, textOverflow: 'ellipsis', overflow: 'hidden', padding:10
                  }}>
                  <small >
                    <b style={{ textDecoration: 'none', backgroundColor: "#FFFFFF", padding: 10, width: '100%', marginBottom: 10, fontSize: 14,}}>
                      {item.name}
                    </b>
                  </small>
                </div>

              </div>

            </Link>
          </div>
        )

      })}
    </ElementSlider>
    /* </div> */
    // </div >
  );
}

export default Professionals;
