import React, { useEffect, useState } from 'react'
import { uniformImageHolder, displayprice } from '../utilityComponents'
import { Link, Redirect } from 'react-router-dom'
import Skeleton from '@yisheng90/react-loading';
import { Dropdown, ButtonGroup, Button, InputGroup, FormControl } from 'react-bootstrap'
import Product from '../product1'
import ProductHeader from '../Header/ProductHeader'
import '../styles/provider.css'
import Footer from '../Footer/footer'
//import Product from  './product1'
import heart from '../../assets/heart4.svg'
import call from '../../assets/call-icon.png'
import '../styles/category.css'
import '../Header/ProductHeader.css';
import WakandaLogoWhite from '../../assets/Wakanda-Logo-white.svg'
import searchIcon from '../../assets/searchIcon.svg'
import map from '../../assets/map.svg'
import Accordion from 'react-bootstrap/Accordion'

import banner1image from '../images/Group-674.png'
import { BannerCat, Banner5 } from '../utilityComponents'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isAndroid,
    isIOS,
    isMobile
} from "react-device-detect";





const productData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const marketing = [
    '- Sell your products and services',
    '- Use the Wakanda Wallet and Escrow Service',
    '- Get free business/e-commerce Website and a lot more…'
]

let URL = "https://cdn.pixabay.com/photo/2017/01/14/10/03/fashion-1979136_1280.jpg"
let providerPhoto = "https://images.pexels.com/photos/4599047/pexels-photo-4599047.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
let bgCover = "https://images.pexels.com/photos/4355345/pexels-photo-4355345.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"





let POST = {}
let ARTICLEID = ''
let BLOGID = ''
const BlogPost = (props) => {

    const [shopCategory, setShopCategory] = useState("")
    //const [shopProducts, setProducts] = useState(productData)
    const [redirect, setRedirect] = useState(false)
    const [url, setURL] = useState('')
    const [blogData, setBlogData] = useState({})
    const [articleID, setArticleID] = useState('')
    const [post, setPost] = useState('')
    const [blogID, setBlogID] = useState('')


    const fetchBlogData = async () => {
        console.log('launching fetchProductsbyCategory, location is')
        if (!blogID && !articleID) return
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/worldGet", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'getBlog',
                    authPhrase: 'A¶§Ô˝D3ßºƒn',
                    // profileID:'00babaOjaGeneralBlog',
                    blogID: blogID || '00babaOjaGeneralBlog',
                    location: 'lagos'
                })
            });
            const r2 = await r.json();
            console.log('r2 offers fetched in fetchBlogData are: ', r2)
            if (r2.msg == 'SUCCESS') {
                console.log('r2 is xxx', r2)
                let data = r2.response
                setBlogData(data) 
                let articlepost = data.chatGroups.filter(item => {
                    if (item.xID == ARTICLEID) return true
                })
                POST = articlepost[0]
                setPost(articlepost) 
            } else {
                console.log('something went wrong... ', r2.msg)
                alert('something went wrong, please try again')  
            }

        } catch (err) {
            console.log("Error from firebase using fetchProductsbyCategory is: ", err);

        }

    }


    const parseURLandReturnRelevantID = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);

            console.log('fullURLsubStrings[fullURLsubStrings.length - 1] is ', fullURLsubStrings[fullURLsubStrings.length - 1],);
            ARTICLEID = fullURLsubStrings[fullURLsubStrings.length - 1]
            BLOGID = fullURLsubStrings[fullURLsubStrings.length - 2]
            setArticleID(ARTICLEID)
            setBlogID(BLOGID)

        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }


    useEffect(() => {
        parseURLandReturnRelevantID()
    }, [])

    useEffect(() => {
        fetchBlogData()
    }, [articleID])

    useEffect(() => {
        console.log('POST is ', post, ' articleID is ', articleID)
        setPost(POST)
        setBlogID(BLOGID)
    }, [blogData])



    return (
        <div style={{ minHeight: '100vh' }}>
            {redirect && <Redirect to={url} />}
            <Link to='/' style={{ textDecoration: 'none', width: '100%' }}>
                <div className="nw1-product-smaller-header" >
                    <div className="row"  >
                        <div style={{
                            backgroundImage: `url(${WakandaLogoWhite})`,
                            width: 190,
                            height: 45,
                            backgroundPosition: 'top left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            paddingBottom: 20
                        }} className='col-lg-3 col-sm-12 mt-2  mb-2'>
                        </div>


                        <div className="float-right d-none"><p className='sell d-none d-md-block' style={{ fontWeight: 'bold' }}>
                            SELL ON WAKANDA</p>
                        </div>


                    </div>

                </div>
            </Link>

            <div className="container col-md-10 col-sm-12" style={{ display: 'flex', justifyContent: 'left', width: "90%", marginTop: 100 }}>
                <div className='col-md-10 col-sm-12'>
                    <div className="jumbotron container mt-4 p-4" style={{ padding: 40 }}>
                        <div style={{ padding: 40 }}>
                            <div style={{ padding: 20, }}>
                                {post?.groupTitle && <h4 className="font-weight-bold"> {post.groupTitle} </h4>}
                            </div>
                            {post?.groupPictureURL ? <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={post.groupPictureURL || URL} width="100%"></img>
                            </div>
                                :
                                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Skeleton width={'100%'} height={100} />
                                </div>
                            }

                            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {post?.groupDetails && <small>
                                    {post.groupDetails || "..."}
                                </small>}
                            </div>

                        </div>
                    </div>

                    <div className="container comments p-4" style={{ marginTop: -30 }}>
                        <small className="text-success font-weight-bold mb-4">Comments</small>
                    </div>
                </div>

            </div>

            <div className='pt-4'>

            </div>

            <Footer />
        </div >
    )
}

export default BlogPost